import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import DeviceStatus from "@material-ui/icons/RssFeed";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function DeviceTable({ devices,setSelected }) {
  const classes = useStyles();
  const rows = devices.map((device, index) => {
    let dev = device.device;
    return [
      dev.number,
      `${dev.farm.owner.firstName} ${dev.farm.owner.lastName}`,
      dev.farm.name,
      dev.farm.crop.name,
      dev.deviceType.name,
      dev.msisdn,
      <DeviceStatus key={index} htmlColor={device.online ? "green" : "gray"} />,
      dev.id,
      dev.createdAt
    ];
  });
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S. No</StyledTableCell>
            <StyledTableCell>Owner</StyledTableCell>
            <StyledTableCell>Farm</StyledTableCell>
            <StyledTableCell>Crop</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Msisdn</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow 
              onClick={() => setSelected(row)}
              key={row[0]}>
              <StyledTableCell component="th" scope="row">
                {row[0]}
              </StyledTableCell>
              <StyledTableCell>{row[1]}</StyledTableCell>
              <StyledTableCell>{row[2]}</StyledTableCell>
              <StyledTableCell>{row[3]}</StyledTableCell>
              <StyledTableCell>{row[4]}</StyledTableCell>
              <StyledTableCell>{row[5]}</StyledTableCell>
              <StyledTableCell>{row[6]}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
DeviceTable.propTypes = { 
  devices: PropTypes.array.isRequired,
  setSelected: PropTypes.func,

};
