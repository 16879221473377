import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeDate } from "helpers/utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function SoilTestingResultTable({ soilTestingResults }) {
  const classes = useStyles();
  const constructResultsString = (resultsObject) => {
    return resultsObject.crop.name+" P: "+resultsObject.cropNeeds.potassium.soilAvailableAmount.toFixed(2)
    +","+resultsObject.cropNeeds.potassium.deficit.toFixed(2)
    +" C: "+resultsObject.cropNeeds.conductivity.soilAvailableAmount.toFixed(2)
    +","+resultsObject.cropNeeds.conductivity.deficit.toFixed(2)
    +" N: "+resultsObject.cropNeeds.nitrogen.soilAvailableAmount.toFixed(2)
    +","+resultsObject.cropNeeds.nitrogen.deficit.toFixed(2);
  };

  const rows = soilTestingResults.map((soilTestingResult) => {
    let resultsObject = JSON.parse(soilTestingResult.results);
    return [
        soilTestingResult.id,
        soilTestingResult.farm.name,
        constructResultsString(resultsObject),
      makeDate(soilTestingResult.createdAt),
      makeDate(soilTestingResult.updatedAt),
    ];
  });
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>No</StyledTableCell>
            <StyledTableCell>Farm Name</StyledTableCell>
            <StyledTableCell>Results</StyledTableCell>
            <StyledTableCell>Created</StyledTableCell>
            <StyledTableCell>Last Modified</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row[0]}>
              <StyledTableCell component="th" scope="row">
                {row[0]}
              </StyledTableCell>
              <StyledTableCell>{row[1]}</StyledTableCell>
              <StyledTableCell>{row[2]}</StyledTableCell>
              <StyledTableCell>{row[3]}</StyledTableCell>
              <StyledTableCell>{row[4]}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
SoilTestingResultTable.propTypes = { soilTestingResults: PropTypes.array.isRequired };
