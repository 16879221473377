import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
//import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
//import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { Row } from "antd";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  // textField: {
  //   width: "25ch",
  // },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
}));

//const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
//const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DeficitComputePane(props) {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Card>
        <GridContainer
          direction="column"
          alignItems="flex-start"
          alignContent="space-around"
          spacing={6}
        >
          {/* <CardBody> */}
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Pick start date"
            value={props.startDate}
            onChange={props.onStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Pick end date"
            value={props.endDate}
            onChange={props.onEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
          >
            <InputLabel htmlFor="standard-adornment-amount">
              Land Area
            </InputLabel>
            <Input
              id="standard-adornment-weight"
              value={props.landSize}
              onChange={props.onLandSizeChange}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "Land Area",
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Crop</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.selectedCrop}
              onChange={props.onCropSelection}
            >
              {props.crops.map((crop, index) => (
                <MenuItem key={index} value={crop.id}>
                  {crop.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Soil Depth</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.selectedDepth}
              onChange={props.onDepthSelection}
            >
              {props.supportedSoilDepths.map((depth, index) => (
                <MenuItem key={index} value={depth.value}>
                  {depth.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="soil-type-select-label">Soil Type</InputLabel>
              <Select
                labelId="soil-type-select-label"
                id="soil-type-select"
                value={props.selectedSoilType}
                onChange={props.onSoilTypeSelection}
              >
               <MenuItem key={"Loam Soil"} value={"Loam Soil"}>
                    {"Loam Soil"}
               </MenuItem>
               <MenuItem key={"Sandy Soil"} value={"Sandy Soil"}>
                   {"Sandy Soil"}
               </MenuItem>
               <MenuItem key={"Clay Soil"} value={"Clay Soil"}>
                 {"Clay Soil"}
               </MenuItem>
              </Select>
            </FormControl>
          <Autocomplete
            id="checkboxes-tags-demo"
            options={props.farms}
            //disableCloseOnSelect
            getOptionLabel={(farm) => {
                if (farm != undefined) {
                  return `${farm.name}-${farm.crop.name}`;
                } else {
                  return "";
                }
              }
            }
            onChange={props.onFarmSelection}
            renderOption={(farm) => {
              if (farm == undefined){
                return "";
              }
              return (
                <React.Fragment>
                  {`${farm.name}-${farm.crop.name}`}
                </React.Fragment>
              )}
            }
            style={{ width: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Farms"
                placeholder="Type to Search for the Farm"
              />
            )}
          />
          <Row>
            <Button color="success" onClick={props.onSubmit}>
              Calculate
            </Button>
            <Button color="warning" marginleft="20" onClick={props.onSaveResults}>
              Save
            </Button>
          </Row>
          {/* </CardBody> */}
        </GridContainer>
      </Card>
    </MuiPickersUtilsProvider>
  );
}
DeficitComputePane.propTypes = {
  onFarmSelection: PropTypes.func.isRequired,
  onLandSizeChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveResults: PropTypes.func.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  onCropSelection: PropTypes.func.isRequired,
  onSoilTypeSelection: PropTypes.func.isRequired,
  onDepthSelection: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  landSize: PropTypes.number,
  selectedCrop: PropTypes.number,
  selectedSoilType: PropTypes.string,
  selectedDepth: PropTypes.number,
  devices: PropTypes.array,
  farms: PropTypes.array,
  crops: PropTypes.array,
  supportedSoilDepths: PropTypes.array,
};
