import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import "date-fns";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import ComboBox from "components/ComboBox";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmptyObject } from "helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function InsuranceEditor({
  loading,
  onEdit,
  formData,
  status,
  farmsKv,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  console.log("xxxx data", JSON.stringify(data));
  useEffect(() => {
    setData(formData);
    setErrors({});
    return () => setData({});
  }, [formData]);

  const handleFarmSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["farmId"] = e.target.value;
    setData(oldData);
  };
  const handleClick = () => {
    let errorList = {};
    if (isEmptyObject(errorList)) {
  
      let edited = { ...data };
  //edited.value2insure=edited.price *  edited.farmSize * edited.expectedvalue;
  //edited.basicpremium=((edited.schemerate/100)*  edited.value2insure);
  //edited.subsidy=((edited.subsidyperc/100)* edited.basicpremium);
  //edited.traininglevy=(edited.basicpremium * 0.005);
  //edited.netpremium=parseInt(edited.basicpremium - edited.subsidy) + parseInt(edited.traininglevy) + parseInt(edited.stampduty);
      let changes = Object.fromEntries(
        Object.entries(edited).filter(
          ([k, v]) => k === "id" || v !== formData[k]
        )
      );
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  function handleChange(event, name) {
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
  <div>
        <ComboBox
          error={errors["farmId"]}
          value={data["farmId"]}
          label={"Farm Name"}
          onSelect={handleFarmSelection}
          kvOptions={farmsKv}
        />
       <TextField
          error={checkError("farmSize")}
          label="Farm Size(ACRES)"
          id="farmSize"
          value={data.farmSize}
          onChange={(e) => handleChange(e, "farmSize")}
          variant="outlined"
          helperText={errors.farmSize}
          size="small"
        /> 
       <TextField
          error={checkError("groupName")}
          label="Group Name"
          id="groupName"
          value={data.groupName}
          onChange={(e) => handleChange(e, "groupName")}
          variant="outlined"
          helperText={errors.groupName}
          size="small"
        /> 
      <TextField
          error={checkError("price")}
          label="Price Per KG(Ugx"
          id="price"
          value={data.price}
          onChange={(e) => handleChange(e, "price")}
          variant="outlined"
          helperText={errors.price}
          size="small"
        />           
      </div>
      <div>
       <TextField
          error={checkError("expectedvalue")}
          label="Expected Value"
          id="expectedvalue"
          value={data.expectedvalue}
          onChange={(e) => handleChange(e, "expectedvalue")}
          variant="outlined"
          helperText={errors.expectedvalue}
          size="small"
        />
      <TextField
          error={checkError("schemerate")}
          label="Scheme Rate"
          id="schemerate"
          value={data.schemerate}
          onChange={(e) => handleChange(e, "schemerate")}
          variant="outlined"
          helperText={errors.schemerate}
          size="small"
        /> 
      <TextField
          error={checkError("subsidyperc")}
          label="% Subsidy 4 basic premium"
          id="subsidyperc"
          value={data.subsidyperc}
          onChange={(e) => handleChange(e, "subsidyperc")}
          variant="outlined"
          helperText={errors.subsidyperc}
          size="small"
        />          
      <TextField
          error={checkError("stampduty")}
          label="Stamp Duty"
          id="stampduty"
          value={data.stampduty}
          onChange={(e) => handleChange(e, "stampduty")}
          variant="outlined"
          helperText={errors.stampduty}
          size="small"
        />       
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Update successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => handleClick(data)}
        >
          Update
        </Button>
      </div>
    </form>
  );
}
InsuranceEditor.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  farmsKv: PropTypes.array.isRequired,
};
