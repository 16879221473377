import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EnhancedTableHead from "components/EnhancedTableHead";
import ReportingTableToolbar from "components/ReportingTableToolbar";
import Spinner from "components/Spinner";
import Button from "@material-ui/core/Button";

export default function TransactionsTable({
  classes,
  idField,
  tableTitle,
  tableHeaders,
  onDownload,
  downloading,
  loading,
  data,
  emptyRows,
  onLoadOrder,
}) {
  return (
    <div>
      <ReportingTableToolbar
        tableTitle={tableTitle}
        onDownload={onDownload}
        downloading={downloading}
        classes={classes}
      />
      <div className={classes.tableWrapper}>
        {loading && <Spinner />}
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={0}
            order={"asc"}
            orderBy={"name"}
            rowCount={data.length}
            idField={idField}
            tableHeaders={tableHeaders}
          />
          <TableBody>
            {data.map((n, rowIndex) => {
              return (
                <TableRow hover key={rowIndex}>
                  {Object.keys(n).map((key, index) => {
                    let valueObj = n[key];
                    switch (valueObj.type) {
                      case "number":
                        return (
                          <TableCell key={index} numeric>
                            {valueObj.value}
                          </TableCell>
                        );
                      case "boolean":
                        return (
                          <TableCell
                            key={index}
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            {valueObj.value ? "YES" : "NO"}
                          </TableCell>
                        );
                      case "text":
                      default:
                        return (
                          <TableCell
                            key={index}
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            {valueObj.value}
                          </TableCell>
                        );
                    }
                  })}
                  <TableCell component="th" scope="row" padding="none">
                    {
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => onLoadOrder(n)}
                        className={classes.button}
                      >
                        View Order
                      </Button>
                    }
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

TransactionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  idField: PropTypes.string.isRequired,
  tableTitle: PropTypes.string.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onLoadOrder: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  downloading: PropTypes.bool,
  data: PropTypes.array,
  emptyRows: PropTypes.number.isRequired,
};
