import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { isEmptyObject } from "helpers/utils";
import ComboBox from "components/ComboBox";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newSmsTemplateButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function EditUserForm({
  loading,
  onEdit,
  formData,
  status,
  languageKv, 
  cropKv, 
  smsTypeKv
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({}); 
  useEffect(() => {
    setData(formData);
    setErrors({});
    return () => setData({});
  }, [formData]);
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  
  const handleClick = () => {
    let errorList = {};
    if (!data.smsType) errorList.smsType = "field cannot be empty";
    if (!data.content) errorList.content = "field cannot be empty";
    if (!data.sentAfterDays) errorList.sentAfterDays = "field cannot be empty";
    
    if (emptyObject(errorList)) {
      let changes = Object.fromEntries(
        Object.entries(data).filter(([k, v]) => k === "id" || v !== formData[k])
      );
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  function handleChange(event, name) {
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  }

  const handleCropSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["cropId"] = e.target.value;
    setData(oldData);
  };

  const handleLanguageSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["languageId"] = e.target.value;
    setData(oldData);
  };

  const handleSmsTypeSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["smsType"] = e.target.value;
    setData(oldData);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
      <ComboBox
          error={errors["smsType"]}
          value={data["smsType"] === undefined ? 0 : data["smsType"]}
          label={"SMS Type"}
          onSelect={handleSmsTypeSelection}
          kvOptions={smsTypeKv === undefined ? [] : smsTypeKv}
        />
        <TextField
          error={checkError("content")}
          label="Content"
          id="content"
          width={200}
          value={data.content}
          onChange={(e) => handleChange(e, "content")}
          variant="outlined"
          helperText={errors.content}
          size="small"
        />
      </div>
      <div>
      <TextField
          error={checkError("sentAfterDays")}
          label="Sent After Days"
          id="sentAfterDays"
          value={data.sentAfterDays}
          onChange={(e) => handleChange(e, "sentAfterDays")}
          variant="outlined"
          helperText={errors.sentAfterDays}
          size="small"
        />
        <ComboBox
          error={errors["cropId"]}
          value={data["cropId"] === undefined ? 1 : data["cropId"]}
          label={"Crop"}
          onSelect={handleCropSelection}
          kvOptions={cropKv}
        />
        <ComboBox
          error={errors["languageId"]}
          value={data["languageId"] === undefined ? 1 : data["languageId"]}
          label={"Langauge"}
          onSelect={handleLanguageSelection}
          kvOptions={languageKv === undefined ? [] : languageKv}
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          User update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          User update successful!
        </Alert>
      )}
      <div className={classes.newSmsTemplateButton}>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => handleClick(data)}
        >
          Update SMS Template
        </Button>
      </div>
    </form>
  );
}
EditUserForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  languageKv: PropTypes.array,
  cropKv: PropTypes.array,
  smsTypeKv: PropTypes.array
};
