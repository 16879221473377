import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Form from "./Form";
import { withRouter, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../../helpers/use-auth";
import "../../assets/css/login.css";
import "../../assets/css/bootstrap.min.css";
import ProgressBar from "../ProgressBar";

const Login2 = () => {
  const history = useHistory();
  //   const classes = useStyles();
  const auth = useAuth();
  const [loginFailed, setLoginFailed] = useState(false);
  const [loginProgress, setLoginProgress] = useState(false);
  const handleLogin = (e, username, password) => {
    setLoginProgress(true);
    setLoginFailed(false);
    e.preventDefault();
    auth
      .signin(username, password)
      .then(() => {
        setLoginFailed(false);
        setLoginProgress(false);
        history.push("/admin");
      })
      .catch(() => {
        setLoginProgress(false);
        setLoginFailed(true);
      });
  };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div>
      <section className="wf100 p100 inner-header">
        <div className="container">
          <h1>Welcome to Agri Yields!</h1>
          <ul>
            {/* <li>
              <a href="#">Register for an account</a>
            </li>
            <li>
              <a href="#"> We will contact you! </a>
            </li> */}
          </ul>
        </div>
      </section>
      <section className="wf100 p80">
        <div className="container">
          {loginProgress && <ProgressBar></ProgressBar>}
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="login-box">
                <div className="row">
                  <h3>Login &nbsp;</h3>
                  {loginFailed && <h3 style={{ color: "red" }}>Failed</h3>}
                </div>

                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username/Email"
                      required
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="input-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck2"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck2">
                      Remember Me
                    </label>
                    {/* <a href="#" className="fp">
                      Forgot Password
                    </a> */}
                  </div>
                  <div className="input-group">
                    <button
                      className="login-btn"
                      onClick={(e) => handleLogin(e, username, password)}
                    >
                      Login Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </section>
      <footer className="footer">
        <div className="footer-top wf100">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6"></div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer-widget"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyr wf100">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-8"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
Login2.propTypes = {
  history: PropTypes.object.isRequired,
};
export default withRouter(Login2);
