import moment from "moment";

const days = {
  1: "Mo",
  2: "Tu",
  3: "We",
  4: "Th",
  5: "Fr",
  6: "Sa",
  0: "Su",
};

function last7Days() {
  var result = [];
  for (var i = 0; i < 7; i++) {
    var d = new Date();
    d.setDate(d.getDate() - i);
    result.push(days[moment(d).day()]);
  }
  return result;
}

function last8Hours() {
  var result = {};
  var currDateTime = moment();
  for (var i = 0; i < 8; i++) {
    var a = currDateTime.clone().subtract(i, "hours").format("YYYY-MM-DD hha");
    result[i] = a;
  }
  return result;
}

export function addDays(date, days) {
  const copy = new Date(Number(date))
  copy.setDate(date.getDate() + days)
  return copy
}

// function nutrientDataInit() {
//   return {
//     nitrogen: "0.00",
//     potassium: "0.00",
//     phosphorus: "0.00",
//     ph: "0.00",
//     temp: "0.00",
//     moisture: "0.00",
//     conductivity: "0.00",
//     signalLevel: "0.00",
//   };
// }
// function last8Hours() {
//   var result = [];
//   for (var i = 0; i < 8; i++) {
//     var d = new Date();
//     d.setDate(d.getDate() - i);
//     result.push(days[moment(d).day()]);
//   }
//   return result;
// }
/**
 * Extract only nutrient data with createdAt date, sort by date in descending order
 * @param {*} soilData example input:
 *        [
            {
                "id": 1,
                "deviceId": 3,
                "nitrogen": 6,
                "phosphorus": 8,
                "potassium": 19,
                "ph": 727,
                "temp": 319,
                "moisture": 800,
                "conductivity": 115,
                "signalLevel": 131,
                "version": "01",
                "session": "00000018",
                "order": "03",
                "length": "0028",
                "end": "00",
                "body": "00000320 0000013F 000002D7 00000073 00000006 00000008 00000013 00000083 00000085 00000014 ",
                "createdAt": "2021-07-12T10:28:05Z",
                "updatedAt": "2021-07-12T10:28:05Z",
                "device": {
                    "id": 3,
                    "deviceTypeId": 2,
                    "number": "171CB740BC10-2",
                    "status": false,
                    "createdAt": "2021-07-11T17:27:19Z",
                    "updatedAt": "2021-07-13T12:57:06Z"
                }
            },
            ...
        ]
 * Example output:
           [
            {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": 0,
                "temp": 0,
                "moisture": 0,
                "conductivity": 0,
                "signalLevel": 0,
                "createdAt": "2021-07-16",
                "exactTime": "2021-07-13T12:57:06Z",
            },
            {
                "nitrogen": 4,
                "phosphorus": 6,
                "potassium": 14,
                "ph": 0,
                "temp": 0,
                "moisture": 0,
                "conductivity": 0,
                "signalLevel": 0,
                "createdAt": "2021-07-13",
                "exactTime": "2021-07-13T12:57:06Z",
            },
            {
                "nitrogen": 4,
                "phosphorus": 6,
                "potassium": 14,
                "ph": 0,
                "temp": 0,
                "moisture": 0,
                "conductivity": 0,
                "signalLevel": 0,
                "createdAt": "2021-07-13",
                "exactTime": "2021-07-13T12:57:06Z",
            },
            {
                "nitrogen": 4,
                "phosphorus": 6,
                "potassium": 14,
                "ph": 0,
                "temp": 0,
                "moisture": 0,
                "conductivity": 0,
                "signalLevel": 0,
                "createdAt": "2021-07-13",
                "exactTime": "2021-07-13T12:57:06Z",
            }
          ]
 */
export function extractNutrientsAndDateOnly(soilData) {
  return soilData
    .map((data) => {
      let extractedData = (({
        nitrogen,
        phosphorus,
        potassium,
        ph,
        temp,
        moisture,
        conductivity,
        signalLevel,
        createdAt,
      }) => ({
        nitrogen,
        phosphorus,
        potassium,
        ph,
        temp,
        moisture,
        conductivity,
        signalLevel,
        createdAt,
      }))(data);
      extractedData.exactTime = extractedData.createdAt;
      extractedData.createdAt = moment(
        Date.parse(extractedData.createdAt)
      ).format("YYYY-MM-DD");
      return extractedData;
    })
    .sort(function (a, b) {
      return new Date(b.exactTime) - new Date(a.exactTime);
    });
}

/**
 * 
 * @param {*} datedNutrientData example input:
           [
            {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": 0,
                "temp": 0,
                "moisture": 0,
                "conductivity": 0,
                "signalLevel": 0,
                "createdAt": "2021-07-16",
                "exactTime": "2021-07-13T12:57:06Z",
            },
            {
                "nitrogen": 4,
                "phosphorus": 6,
                "potassium": 14,
                "ph": 0,
                "temp": 0,
                "moisture": 0,
                "conductivity": 0,
                "signalLevel": 0,
                "createdAt": "2021-07-13",
                "exactTime": "2021-07-13T12:57:06Z",
            },
            {
                "nitrogen": 4,
                "phosphorus": 6,
                "potassium": 14,
                "ph": 0,
                "temp": 0,
                "moisture": 0,
                "conductivity": 0,
                "signalLevel": 0,
                "createdAt": "2021-07-13",
                "exactTime": "2021-07-13T12:57:06Z",
            },
            {
                "nitrogen": 4,
                "phosphorus": 6,
                "potassium": 14,
                "ph": 0,
                "temp": 0,
                "moisture": 0,
                "conductivity": 0,
                "signalLevel": 0,
                "createdAt": "2021-07-13",
                "exactTime": "2021-07-13T12:57:06Z",
            }
          ]
 *
 * Example output:
          {
            "2021-07-16": {
                "readings": [
                {
                    "nitrogen": 0,
                    "phosphorus": 0,
                    "potassium": 0,
                    "ph": 0,
                    "temp": 0,
                    "moisture": 0,
                    "conductivity": 0,
                    "signalLevel": 0,
                "exactTime": "2021-07-13T12:57:06Z",
                },
                {
                    "nitrogen": 0,
                    "phosphorus": 0,
                    "potassium": 0,
                    "ph": 0,
                    "temp": 0,
                    "moisture": 0,
                    "conductivity": 0,
                    "signalLevel": 0,
                "exactTime": "2021-07-13T12:57:06Z",
                }
                ]
            },
            "2021-07-13": {
                "readings": [
                {
                    "nitrogen": 4,
                    "phosphorus": 6,
                    "potassium": 14,
                    "ph": 0,
                    "temp": 0,
                    "moisture": 0,
                    "conductivity": 0,
                    "signalLevel": 0,
                "exactTime": "2021-07-13T12:57:06Z",
                },
                {
                    "nitrogen": 4,
                    "phosphorus": 6,
                    "potassium": 14,
                    "ph": 0,
                    "temp": 0,
                    "moisture": 0,
                    "conductivity": 0,
                    "signalLevel": 0,
                "exactTime": "2021-07-13T12:57:06Z",
                }
                ]
            }
          }
 */
export function groupReadingsByDate(datedNutrientData) {
  return datedNutrientData.reduce((acc, curr) => {
    let date = curr.createdAt;
    let newCurr = (({
      nitrogen,
      phosphorus,
      potassium,
      ph,
      temp,
      moisture,
      conductivity,
      signalLevel,
      exactTime,
    }) => ({
      nitrogen,
      phosphorus,
      potassium,
      ph,
      temp,
      moisture,
      conductivity,
      signalLevel,
      exactTime,
    }))(curr);
    // if there is no property in accumulator with this letter create it
    if (!acc[date]) acc[date] = { readings: [newCurr] };
    // if there is push current element to children array for that letter
    else acc[date].readings.push(newCurr);
    // return accumulator
    return acc;
  }, {});
}

export function last8HoursData(data) {
  let actualLast8Hours = last8Hours();
  let last8HoursData = {};
  for (let i = 0; i < 8; i++) {
    let strRep = actualLast8Hours[i];
    if (!data[strRep]) {
      last8HoursData[strRep.split(" ")[1]] = {
        nitrogen: 0.0,
        phosphorus: 0.0,
        potassium: 0.0,
        ph: 0.0,
        temp: 0.0,
        moisture: 0.0,
        conductivity: 0.0,
        signalLevel: 0.0,
      };
    } else last8HoursData[strRep.split(" ")[1]] = data[strRep];
  }
  return last8HoursData;
}

export function createHourlyChartData(last8HoursData) {
  let hourlyChartData = {};
  hourlyChartData.labels = Object.keys(last8HoursData).reverse();
  let last8HoursN = [];
  let last8HoursP = [];
  let last8HoursK = [];
  let last8HoursPh = [];
  let last8HoursTemp = [];
  let last8HoursMoisture = [];
  let last8HoursEc = [];
  let last8HoursSignal = [];
  for (let i = 0; i < 8; i++) {
    let dailyReadings = last8HoursData[hourlyChartData.labels[i]];
    last8HoursN.push(parseFloat(dailyReadings["nitrogen"]).toFixed(2));
    last8HoursP.push(parseFloat(dailyReadings["phosphorus"]).toFixed(2));
    last8HoursK.push(parseFloat(dailyReadings["potassium"]).toFixed(2));
    last8HoursPh.push(parseFloat(dailyReadings["ph"]).toFixed(2));
    last8HoursTemp.push(parseFloat(dailyReadings["temp"]).toFixed(2));
    last8HoursMoisture.push(parseFloat(dailyReadings["moisture"]).toFixed(2));
    last8HoursEc.push(parseFloat(dailyReadings["conductivity"]).toFixed(2));
    last8HoursSignal.push(parseFloat(dailyReadings["signalLevel"]).toFixed(2));
  }
  hourlyChartData.series = [
    { name: "Nitrogen", data: last8HoursN },
    { name: "Phosphorus", data: last8HoursP },
    { name: "Potassium", data: last8HoursK },
    { name: "Ph", data: last8HoursPh },
    { name: "Temp", data: last8HoursTemp },
    { name: "Moisture", data: last8HoursMoisture },
    { name: "Conductivity", data: last8HoursEc },
    { name: "SignalLevel", data: last8HoursSignal },
  ];
  return hourlyChartData;
}
/**
 * 
 * @param {*} readingsGroupedByDate example input:
 *        {
            "2021-07-16": {
                "readings": [
                {
                    "nitrogen": 0,
                    "phosphorus": 0,
                    "potassium": 0,
                    "ph": 0,
                    "temp": 0,
                    "moisture": 0,
                    "conductivity": 0,
                    "signalLevel": 0,
                "exactTime": "2021-07-13T12:57:06Z",
                },
                {
                    "nitrogen": 0,
                    "phosphorus": 0,
                    "potassium": 0,
                    "ph": 0,
                    "temp": 0,
                    "moisture": 0,
                    "conductivity": 0,
                    "signalLevel": 0,
                "exactTime": "2021-07-13T12:57:06Z",
                }
                ]
            },
            "2021-07-13": {
                "readings": [
                {
                    "nitrogen": 4,
                    "phosphorus": 6,
                    "potassium": 14,
                    "ph": 0,
                    "temp": 0,
                    "moisture": 0,
                    "conductivity": 0,
                    "signalLevel": 0,
                "exactTime": "2021-07-13T12:57:06Z",
                },
                {
                    "nitrogen": 4,
                    "phosphorus": 6,
                    "potassium": 14,
                    "ph": 0,
                    "temp": 0,
                    "moisture": 0,
                    "conductivity": 0,
                    "signalLevel": 0,
                "exactTime": "2021-07-13T12:57:06Z",
                }
                ]
            }
          }
 * Example output:
          {
            "2021-07-16": {
                "nitrogen": "0.00",
                "potassium": "0.00",
                "phosphorus": "0.00",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "2021-07-13": {
                "nitrogen": "4.00",
                "potassium": "13.66",
                "phosphorus": "5.63",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "2021-07-12": {
                "nitrogen": "4.45",
                "potassium": "15.44",
                "phosphorus": "6.36",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            }
          }
 */
export function computeDailyAverages(readingsGroupedByDate) {
  let dailyAvg = {};
  for (const [key, value] of Object.entries(readingsGroupedByDate)) {
    var nitrogenSum = 0;
    var potassiumSum = 0;
    var phosphorusSum = 0;
    var phSum = 0;
    var tempSum = 0;
    var moistureSum = 0;
    var conductivitySum = 0;
    var signalLevelSum = 0;
    let total = value.readings.length;
    value.readings.forEach((reading) => {
      nitrogenSum += reading["nitrogen"];
      potassiumSum += reading["potassium"];
      phosphorusSum += reading["phosphorus"];
      phSum += reading["ph"];
      tempSum += reading["temp"];
      moistureSum += reading["moisture"];
      conductivitySum += reading["conductivity"];
      signalLevelSum += reading["signalLevel"];
    });
    dailyAvg[key] = {
      nitrogen: (nitrogenSum / total).toFixed(2),
      potassium: (potassiumSum / total).toFixed(2),
      phosphorus: (phosphorusSum / total).toFixed(2),
      ph: ((phSum * 0.01) / total).toFixed(2),
      temp: ((tempSum * 0.01) / total).toFixed(2),
      moisture: ((moistureSum * 0.1) / total).toFixed(2),
      conductivity: (conductivitySum / total).toFixed(2),
      signalLevel: ((0.474 * signalLevelSum - 112) / total).toFixed(2),
    };
  }
  return dailyAvg;
}

/**
 * 
 * @param {*} dataMappedByDate example input:
 *        {
            "2021-07-16": {
                "nitrogen": "0.00",
                "potassium": "0.00",
                "phosphorus": "0.00",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
              
            },
            "2021-07-13": {
                "nitrogen": "4.00",
                "potassium": "13.66",
                "phosphorus": "5.63",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "2021-07-12": {
                "nitrogen": "4.45",
                "potassium": "15.44",
                "phosphorus": "6.36",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            }
          }
 * Example output:
          {
            "1": {
                "nitrogen": "4.45",
                "potassium": "15.44",
                "phosphorus": "6.36",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "2": {
                "nitrogen": "4.00",
                "potassium": "13.66",
                "phosphorus": "5.63",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "5": {
                "nitrogen": "0.00",
                "potassium": "0.00",
                "phosphorus": "0.00",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            }
          }
 */
export function mapByDayNumber(dataMappedByDate) {
  let mappedByDayNum = {};
  for (const [key, value] of Object.entries(dataMappedByDate)) {
    mappedByDayNum[moment(key).day()] = value;
  }
  return mappedByDayNum;
}

/**
 * 
 * @param {*} dataMappedByDayNumber example input:
           {
            "1": {
                "nitrogen": "4.45",
                "potassium": "15.44",
                "phosphorus": "6.36",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "2": {
                "nitrogen": "4.00",
                "potassium": "13.66",
                "phosphorus": "5.63",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "5": {
                "nitrogen": "0.00",
                "potassium": "0.00",
                "phosphorus": "0.00",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            }
          }
 * example output:
           {
            "Su": {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Mo": {
                "nitrogen": "4.45",
                "potassium": "15.44",
                "phosphorus": "6.36",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Tu": {
                "nitrogen": "4.00",
                "potassium": "13.66",
                "phosphorus": "5.63",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "We": {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Th": {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Fr": {
                "nitrogen": "0.00",
                "potassium": "0.00",
                "phosphorus": "0.00",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Sa": {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            }
          }
 */
export function createLast7DaysData(dataMappedByDayNumber) {
  let last7DaysData = {};
  for (let i = 0; i < 7; i++) {
    if (!dataMappedByDayNumber[i]) {
      last7DaysData[days[i]] = {
        nitrogen: 0.0,
        phosphorus: 0.0,
        potassium: 0.0,
        ph: 0.0,
        temp: 0.0,
        moisture: 0.0,
        conductivity: 0.0,
        signalLevel: 0.0,
      };
    } else last7DaysData[days[i]] = dataMappedByDayNumber[i];
  }
  return last7DaysData;
}

/**
 * 
 * @param {*} last7DaysData example input:
            {
            "Su": {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Mo": {
                "nitrogen": "4.45",
                "potassium": "15.44",
                "phosphorus": "6.36",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Tu": {
                "nitrogen": "4.00",
                "potassium": "13.66",
                "phosphorus": "5.63",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "We": {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Th": {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Fr": {
                "nitrogen": "0.00",
                "potassium": "0.00",
                "phosphorus": "0.00",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "Sa": {
                "nitrogen": 0,
                "phosphorus": 0,
                "potassium": 0,
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            }
          }
 * example output:
        {
            "labels": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            "series": [
              {
                "name": "Nitrogen",
                "data": ["0.00", "4.45", "4.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "Phosphorus",
                "data": ["0.00", "6.36", "5.63", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "Potassium",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00"]
              }
              {
                "name": "ph",
                "data": ["0.00", "4.45", "4.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "temp",
                "data": ["0.00", "6.36", "5.63", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "moisture",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "conductivity",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "signalLevel",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00"]
              },
            ]
        }
       
 */
export function createDailyChartData(last7DaysData) {
  let dailyChartData = {};
  dailyChartData.labels = last7Days().reverse();
  let last7DaysN = [];
  let last7DaysP = [];
  let last7DaysK = [];
  let last7DaysPh = [];
  let last7DaysTemp = [];
  let last7DaysMoisture = [];
  let last7DaysConductivity = [];
  let last7DaysSignalLevel = [];
  for (let i = 0; i < 7; i++) {
    let dailyReadings = last7DaysData[dailyChartData.labels[i]];
    last7DaysN.push(parseFloat(dailyReadings["nitrogen"]).toFixed(2));
    last7DaysP.push(parseFloat(dailyReadings["phosphorus"]).toFixed(2));
    last7DaysK.push(parseFloat(dailyReadings["potassium"]).toFixed(2));
    last7DaysPh.push(parseFloat(dailyReadings["ph"]).toFixed(2));
    last7DaysTemp.push(parseFloat(dailyReadings["temp"]).toFixed(2));
    last7DaysMoisture.push(parseFloat(dailyReadings["moisture"]).toFixed(2));
    last7DaysConductivity.push(
      parseFloat(dailyReadings["conductivity"]).toFixed(2)
    );
    last7DaysSignalLevel.push(
      parseFloat(dailyReadings["signalLevel"]).toFixed(2)
    );
  }
  dailyChartData.series = [
    { name: "Nitrogen", data: last7DaysN },
    { name: "Phosphorus", data: last7DaysP },
    { name: "Potassium", data: last7DaysK },
    { name: "Ph", data: last7DaysPh },
    { name: "Temp", data: last7DaysTemp },
    { name: "Moisture", data: last7DaysMoisture },
    { name: "Conductivity", data: last7DaysConductivity },
    { name: "SignalLevel", data: last7DaysSignalLevel },
  ];
  return dailyChartData;
}

/**
 * 
 * @param {*} dailyAvgsByDate example input:
 *        {
            "2021-07-16": {
                "nitrogen": "0.00",
                "potassium": "0.00",
                "phosphorus": "0.00",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "2021-07-13": {
                "nitrogen": "4.00",
                "potassium": "13.66",
                "phosphorus": "5.63",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            },
            "2021-07-12": {
                "nitrogen": "4.45",
                "potassium": "15.44",
                "phosphorus": "6.36",
                "ph": "0.00",
                "temp": "0.00",
                "moisture": "0.00",
                "conductivity": "0.00",
                "signalLevel": "0.00",
            }
          }
 * Example output:
         {
            "labels": ["W-7", "W-6", "W-5", "W-4", "W-3", "W-2", "W-1", "W-0"],
            "series": [
              {
                "name": "Nitrogen",
                "data": ["0.00", "4.45", "4.00", "0.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "Phosphorus",
                "data": ["0.00", "6.36", "5.63", "0.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "Potassium",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "ph",
                "data": ["0.00", "4.45", "4.00", "0.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "temp",
                "data": ["0.00", "6.36", "5.63", "0.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "moisture",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "conductivity",
                "data": ["0.00", "4.45", "4.00", "0.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "signalLevel",
                "data": ["0.00", "6.36", "5.63", "0.00", "0.00", "0.00", "0.00", "0.00"]
              }
            ]
        }
 */
export function createLast8WeeksData(weeklyData) {
  const weekLabels = ["W-0", "W-1", "W-2", "W-3", "W-4", "W-5", "W-6", "W-7"];
  let thisWeekNum = moment(new Date()).week();
  let weekNumToWeekLabel = {};
  for (let i = 0; i < 8; i++) {
    weekNumToWeekLabel[thisWeekNum - i] = weekLabels[i];
  }
  let weeklyAvg = {};
  for (const [date, readings] of Object.entries(weeklyData)) {
    let weekNum = moment(date).week();
    let weekLabel = weekNumToWeekLabel[weekNum];
    weeklyAvg[weekLabel] = readings;
  }
  let weeklyChartData = {};
  let last8WeeksN = [];
  let last8WeeksP = [];
  let last8WeeksK = [];
  let last8WeeksPh = [];
  let last8WeeksTemp = [];
  let last8WeeksMoisture = [];
  let last8WeeksConductivity = [];
  let last8WeeksSignalLevel = [];
  weeklyChartData.labels = weekLabels.reverse();
  for (const week of weeklyChartData.labels) {
    if (weeklyAvg[week]) {
      let weekAvg = weeklyAvg[week];
      last8WeeksN.push(weekAvg.nitrogen);
      last8WeeksP.push(weekAvg.phosphorus);
      last8WeeksK.push(weekAvg.potassium);
      last8WeeksPh.push(weekAvg.ph);
      last8WeeksTemp.push(weekAvg.temp);
      last8WeeksMoisture.push(weekAvg.moisture);
      last8WeeksConductivity.push(weekAvg.conductivity);
      last8WeeksSignalLevel.push(weekAvg.signalLevel);
    } else {
      last8WeeksN.push(0.0);
      last8WeeksP.push(0.0);
      last8WeeksK.push(0.0);
      last8WeeksPh.push(0.0);
      last8WeeksTemp.push(0.0);
      last8WeeksMoisture.push(0.0);
      last8WeeksConductivity.push(0.0);
      last8WeeksSignalLevel.push(0.0);
    }
  }
  weeklyChartData.series = [
    { name: "Nitrogen", data: last8WeeksN },
    { name: "Phosphorus", data: last8WeeksP },
    { name: "Potassium", data: last8WeeksK },
    { name: "Ph", data: last8WeeksPh },
    { name: "Temp", data: last8WeeksTemp },
    { name: "Moisture", data: last8WeeksMoisture },
    { name: "Conductivity", data: last8WeeksConductivity },
    { name: "SignalLevel", data: last8WeeksSignalLevel },
  ];
  return weeklyChartData;
}
/**
 * 
 * @param {*} nutrient 
 * @param {*} data 
 *  example input:
        {
            "labels": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            "series": [
              {
                "name": "Nitrogen",
                "data": ["0.00", "4.45", "4.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "Phosphorus",
                "data": ["0.00", "6.36", "5.63", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "Potassium",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00"]
              }
              {
                "name": "ph",
                "data": ["0.00", "4.45", "4.00", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "temp",
                "data": ["0.00", "6.36", "5.63", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "moisture",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "conductivity",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00"]
              },
              {
                "name": "signalLevel",
                "data": ["0.00", "15.44", "13.66", "0.00", "0.00", "0.00", "0.00"]
              },
            ]
        }

    example output:
        {
            "labels": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            "series": [
              {
                "name": "Nitrogen",
                "data": ["0.00", "4.45", "4.00", "0.00", "0.00", "0.00", "0.00"]
              },
            ]
        }
 */
export function extractChartData(nutrient, data) {
  return {
    ...data,
    series: data.series.filter((serie) => serie.name === nutrient),
  };
}
/**
 * 
 * @param {*} meta 
 * @param {*} data 
 * let chartB = {
  labels: ["y", "z"],
  series: [
    [{ meta: "value is:", value: 2 }, { meta: "value is:", value: 4 }],
    [{ meta: "value is:", value: 6 }, { meta: "value is:", value: 8 }]
  ]
};
 */
export function dataWithToolTip(meta, data) {
  return {
    ...data,
    series: data.series.map((serie) => {
      return serie.data.map((point) => ({ meta: meta, value: point }));
    }),
  };
}

export function getMaxValues(data) {
  let maxValues = {};
  data.series.forEach((serie) => {
    maxValues[serie.name] = Math.max(...serie.data.map((a) => parseFloat(a)));
  });
  return maxValues;
}

export function makeMoney(amt) {
  // Create our number formatter.
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "UGX",
  });

  return formatter.format(amt);
}

export function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}
export function phoneNoValidates(phone) {
  const re = /^([2567]{4})([\d]{8})$/g;
  return re.test(phone);
}
export function emailValidates(email) {
  const re = /^\S+@\S+\.\S+$/;
  return re.test(email);
}
export function isAirtel(phoneNo) {
  const re = /^([2567]{4})([0|4|5]{1})([\d]{7})$/g;
  return re.test(phoneNo);
}
export function isMtn(phoneNo) {
  const re = /^([2567]{4})([6|7|8]{1})([\d]{7})$/g;
  return re.test(phoneNo);
}
export function makeDate(date) {
  return moment(Date.parse(date)).format("YYYY-MM-DD hh:mm");
}
