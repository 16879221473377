import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { SuccessAlert, ErrorAlert } from "components/Alerts";
import { phoneNoValidates, isEmptyObject } from "helpers/utils";
import ComboBox from "components/ComboBox";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function NewDeviceForm({
  loading,
  onCreate,
  status,
  userError,
  deviceTypes,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const handleChange = (event, name) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const handleTypeSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["deviceTypeId"] = e.target.value;
    setData(oldData);
  };
  const handleClick = () => {
    let errorList = {};
    if (!data.number) errorList.number = "field cannot be empty";
    if (!data.deviceTypeId) errorList.deviceTypeId = "field cannot be empty";
    if (!data.msisdn) errorList.msisdn = "field cannot be empty";
    if (data.msisdn && !phoneNoValidates(data.msisdn))
      errorList.msisdn = "Invalid msisdn format";
    if (isEmptyObject(errorList)) {
      onCreate(data);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          error={checkError("number")}
          label="Device Num"
          id="number"
          value={data.number}
          onChange={(e) => handleChange(e, "number")}
          variant="outlined"
          helperText={errors.number}
          size="small"
        />
        <ComboBox
          value={data["deviceTypeId"]}
          label={"Type"}
          onSelect={handleTypeSelection}
          kvOptions={deviceTypes}
          error={checkError("deviceTypeId")}
        />
        <TextField
          error={checkError("msisdn")}
          label="Msisdn"
          id="msisdn"
          value={data.msisdn}
          onChange={(e) => handleChange(e, "msisdn")}
          variant="outlined"
          helperText={errors.msisdn}
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <ErrorAlert
          msg={
            userError ||
            "Device creation failed, device number or msisdn maybe taken!"
          }
        />
      )}
      {!loading && status === "SUCCESS" && (
        <SuccessAlert msg="Device creation successful!" />
      )}
      <div className={classes.newButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Create
        </Button>
      </div>
    </form>
  );
}
NewDeviceForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
  deviceTypes: PropTypes.array.isRequired,
};
