import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core";
import { phoneNoValidates, isMtn, isAirtel } from "helpers/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default function MomoWidget({ handlePayment }) {
  const classes = useStyles();
  const [provider, setProvider] = React.useState("27");
  const [msisdn, setMsisdn] = React.useState("");
  const [msisdnError, setMsisdnError] = React.useState(false);
  const [msisdnErrorMsg, setMsisdnErrorMsg] = React.useState("");

  const handleProviderChange = (event) => {
    event.preventDefault();
    setProvider(event.target.value);
  };
  const handleMsisdnChange = (event) => {
    let userMsisdn = event.target.value;
    setMsisdnError(false);
    setMsisdnErrorMsg("");
    setMsisdn(userMsisdn);
    if (userMsisdn.length === 0) {
      return;
    }
    if (!phoneNoValidates(userMsisdn)) {
      setMsisdnError(true);
      setMsisdnErrorMsg("Invalid phone number, must be like 256772354645");
      return;
    }
    if (isAirtel(userMsisdn)) {
      setProvider("28");
    } else if (isMtn(userMsisdn)) {
      setProvider("27");
    } else {
      setMsisdnError(true);
      setMsisdnErrorMsg("Only MTN or Airtel supported");
    }
  };
  function initPayment() {
    if (!msisdnError) {
      handlePayment(parseInt(provider), msisdn);
    }
  }

  return (
    <Paper className={classes.root} variant="outlined">
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select a payment provider</FormLabel>
        <RadioGroup
          aria-label="momo-providers"
          name="momo-providers"
          value={provider}
          onChange={handleProviderChange}
        >
          <FormControlLabel value={"27"} control={<Radio />} label="MTN MOMO" />
          <FormControlLabel
            value={"28"}
            control={<Radio />}
            label="Airtel Money"
            onChange={handleProviderChange}
          />
        </RadioGroup>
        <TextField
          error={msisdnError}
          label="Phone No"
          id="msisdn"
          value={msisdn}
          onChange={handleMsisdnChange}
          variant="outlined"
          helperText={msisdnErrorMsg}
          size="small"
        />
        <p>Leave empty to use profile Phone No</p>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => initPayment()}
          className={classes.button}
        >
          Pay
        </Button>
      </FormControl>
    </Paper>
  );
}
MomoWidget.propTypes = {
  handlePayment: PropTypes.func.isRequired,
};
