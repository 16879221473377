import { baseUrl } from "../Constants";
import { makeDate } from "../utils";
export const soilDataTableHeaders = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  { id: "device", numeric: false, disablePadding: true, label: "Device" },
  {
    id: "nitrogen",
    numeric: true,
    disablePadding: false,
    label: "N (mg/Kg)",
  },
  {
    id: "phosphorus",
    numeric: true,
    disablePadding: false,
    label: "P (mg/Kg)",
  },
  {
    id: "potassium",
    numeric: true,
    disablePadding: false,
    label: "K (mg/Kg)",
  },
  { id: "ph", numeric: true, disablePadding: false, label: "PH" },
  { id: "temp", numeric: true, disablePadding: false, label: "Temp(°C)" },
  {
    id: "moisture",
    numeric: true,
    disablePadding: false,
    label: "Relative Humidity (%RH)",
  },
  {
    id: "conductivity",
    numeric: true,
    disablePadding: false,
    label: "EC (us/cm)",
  },
  {
    id: "signalLevel",
    numeric: true,
    disablePadding: false,
    label: "Signal Level (dbm)",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Time",
  },
];

export const soilDataTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  device: { value: "", type: "text", label: "Device" },
  nitrogen: { value: "", type: "number", label: "N" },
  phosphorus: { value: "", type: "number", label: "P" },
  potassium: { value: "", type: "number", label: "K" },
  ph: { value: "", type: "number", label: "PH" },
  temp: { value: "", type: "number", label: "Temperature" },
  moisture: { value: "", type: "number", label: "Relative Humidity" },
  conductivity: { value: "", type: "number", label: "EC" },
  signalLevel: { value: "", type: "number", label: "Signal Level" },
  createdAt: {
    value: "",
    type: "text",
    label: "Time",
    readOnly: true,
  },
};
export const createSoilDataRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    device: {
      value: data.device.number,
      type: "text",
      label: "Device",
      readOnly: true,
    },
    nitrogen: {
      value: data.nitrogen,
      type: "number",
      label: "N",
      readOnly: true,
    },
    phosphorus: {
      value: data.phosphorus,
      type: "number",
      label: "P",
      readOnly: true,
    },
    potassium: {
      value: data.potassium,
      type: "number",
      label: "K",
      readOnly: true,
    },
    ph: {
      value: (data.ph * 0.01).toFixed(2),
      type: "number",
      label: "PH",
      readOnly: true,
    },
    temp: {
      value: (data.temp * 0.1).toFixed(2),
      type: "number",
      label: "Temperature",
      readOnly: true,
    },
    moisture: {
      value: (data.moisture * 0.1).toFixed(2),
      type: "number",
      label: "Relative Humidity",
      readOnly: true,
    },
    conductivity: {
      value: data.conductivity,
      type: "number",
      label: "EC",
      readOnly: true,
    },
    signalLevel: {
      value: (0.474 * data.signalLevel - 112).toFixed(2),
      type: "number",
      label: "Signal Level",
      readOnly: true,
    },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Time",
      readOnly: true,
    },
  };
  return record;
};

export const soilDataTableIdField = "id";
export const soilDataTableTitle = "Sensor";
export const soilDataBaseUrl = baseUrl + "soil-data";
