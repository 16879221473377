import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import fertilizer from "assets/img/fertilizer.jpeg";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    // marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
function makeMoney(amt) {
  // Create our number formatter.
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "UGX",
  });

  return formatter.format(amt);
}
export default function Product({
  details,
  inCart,
  addToCart,
  removeFromCart,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title={details.name} />
      <CardMedia
        className={classes.media}
        image={fertilizer}
        title="Paella dish"
      />
      <CardContent>
        <p className="card-text">
          <b>Category:</b> {details.type.name}
        </p>
        <p className="card-text">
          <b>Supplier:</b>{" "}
          {`${details.supplier.firstName} ${details.supplier.lastName}`}
        </p>
        <p className="card-text">
          <b>Packaging:</b> {`Per ${details.unit.label}`}
        </p>
        <p className="card-text">
          <b>Unit Price:</b> {makeMoney(details.unitPrice)}
        </p>
      </CardContent>
      <div className={classes.controls}>
        <CardActions disableSpacing>
          <IconButton
            aria-label="add to favorites"
            onClick={() => removeFromCart(details)}
          >
            <IndeterminateCheckBoxIcon />
          </IconButton>
          <p>
            <b>{inCart}</b>
          </p>
          <IconButton aria-label="share" onClick={() => addToCart(details)}>
            <AddBoxIcon />
          </IconButton>
        </CardActions>
      </div>
    </Card>
  );
}
Product.propTypes = {
  details: PropTypes.object.isRequired,
  inCart: PropTypes.number.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
};
