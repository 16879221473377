import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default function ProductTypeWidget({ onSelect, type }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root} variant="outlined">
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select product type</FormLabel>
        <RadioGroup
          aria-label="momo-providers"
          name="momo-providers"
          value={`${type}`}
          onChange={(e) => onSelect(parseInt(e.target.value))}
        >
          <FormControlLabel
            value={"1"}
            control={<Radio />}
            label="FERTILIZER"
          />
          <FormControlLabel 
            value={"2"} 
            control={<Radio />} 
            label="SEED" />

        </RadioGroup>
      </FormControl>
    </Paper>
  );
}
ProductTypeWidget.propTypes = {
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
};
