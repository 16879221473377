import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const smsLogTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "to", numeric: false, disablePadding: true, label: "To" },
  { id: "message", numeric: false, disablePadding: true, label: "Message" },
  { id: "status", numeric: false, disablePadding: true, label: "Status" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "sendTime",
    numeric: false,
    disablePadding: true,
    label: "Send Time",
  },
];

export const smsLogTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  to: { value: "", type: "text", label: "to" },
  message: { value: "", type: "text", label: "Message" },
  status: { value: "", type: "text", label: "Status" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  sendTime: {
    value: "",
    type: "text",
    label: "Send Time",
    readOnly: true,
  },
};

export const createSmsLogRecord = (data) => {
  let record = {
    id: { 
        value: data.id, 
        type: "number", 
        label: "ID", 
        readOnly: true 
    },
    to: { 
        value: data.to, 
        type: "text", 
        label: "To" 
    },
    message: { 
        value: data.message, 
        type: "text", 
        label: "Message" 
    },
    status: { 
        value: data.status, 
        type: "text", 
        label: "Status" 
    },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    sendTime: {
      value: makeDate(data.sendTime),
      type: "text",
      label: "Send Time",
      readOnly: true,
    },
  };
  return record;
};

export const smsLogTableIdField = "id";
export const smsLogTableTitle = "SMS Log";
export const smsLogBaseUrl = baseUrl + "smslog";
