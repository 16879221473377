import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const cropNeedTableHeaders = [
  { id: "id", numeric: false, disablePadding: false, label: "ID" },
  { id: "cropId", numeric: false, disablePadding: true, label: "Crop" },
  { id: "yieldKgSqm", numeric: false, disablePadding: false, label: "Yield" },
  { id: "nitrogen", numeric: false, disablePadding: false, label: "Nitrogen" },
  {
    id: "phosphorus",
    numeric: false,
    disablePadding: false,
    label: "Phosphorus",
  },
  {
    id: "potassium",
    numeric: false,
    disablePadding: false,
    label: "Potassium",
  },
  { id: "calcium", numeric: false, disablePadding: false, label: "Calcium" },
  {
    id: "magnesium",
    numeric: false,
    disablePadding: false,
    label: "Magnesium",
  },
  {
    id: "growPeriodMax",
    numeric: false,
    disablePadding: false,
    label: "Total Growing Period Max",
  },
  {
    id: "growPeriodMin",
    numeric: false,
    disablePadding: false,
    label: "Total Growing Period Min",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const cropNeedTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  cropId: { value: "", type: "text", label: "Crop" },
  yieldKgSqm: { value: "", type: "number", label: "Yield" },
  nitrogen: { value: "", type: "number", label: "Nitrogen" },
  phosphorus: { value: "", type: "number", label: "Phosphorus" },
  potassium: { value: "", type: "number", label: "Potassium" },
  calcium: { value: "", type: "number", label: "Calcium" },
  magnesium: { value: "", type: "number", label: "Magnesium" },
  growPeriodMax: { value: "", type: "number", label: "Total Growing Period Max" },
  growPeriodMin: { value: "", type: "number", label: "Total Growing Period Min" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createCropNeedRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    cropId: { value: data.crop.name, type: "text", label: "Crop" },
    yieldKgSqm: { value: data.yieldKgSqm, type: "number", label: "Yield" },
    nitrogen: { value: data.nitrogen, type: "number", label: "Nitrogen" },
    phosphorus: { value: data.phosphorus, type: "number", label: "Phosphorus" },
    potassium: { value: data.potassium, type: "number", label: "Potassium" },
    calcium: { value: data.calcium, type: "number", label: "Calcium" },
    magnesium: { value: data.magnesium, type: "number", label: "Magnesium" },
    growPeriodMax: { value: data.growPeriodMax, type: "number", label: "Total Growing Period Max" },
    growPeriodMin: { value: data.growPeriodMin, type: "number", label: "Total Growing Period Min" },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const cropNeedTableIdField = "id";
export const cropNeedTableTitle = "Crop Requirements";
export const cropNeedBaseUrl = baseUrl + "crop-needs";
