import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EnhancedTableHead from "components/EnhancedTableHead";
import Spinner from "components/Spinner";

export default function MonitorEventsTable({
  classes,
  idField,
  tableHeaders,
  loading,
  data,
  emptyRows,
}) {
  function handleRequestSort(event, property) {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  }
  return (
    <div>
      <div className={classes.tableWrapper}>
        {loading && <Spinner />}
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={0}
            order={"asc"}
            orderBy={"name"}
            rowCount={data.length}
            idField={idField}
            tableHeaders={tableHeaders}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {data.map((n, rowIndex) => {
              return (
                <TableRow hover key={rowIndex}>
                  {Object.keys(n).map((key, index) => {
                    let valueObj = n[key];
                    switch (valueObj.type) {
                      case "number":
                        return (
                          <TableCell key={index} numeric>
                            {valueObj.value}
                          </TableCell>
                        );
                      case "boolean":
                        return (
                          <TableCell
                            key={index}
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            {valueObj.value ? "YES" : "NO"}
                          </TableCell>
                        );
                      case "text":
                      default:
                        return (
                          <TableCell
                            key={index}
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            {valueObj.value}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

MonitorEventsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  idField: PropTypes.string.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  emptyRows: PropTypes.number.isRequired,
};
