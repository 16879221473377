import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TableChartIcon from "@material-ui/icons/TableChart";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import TodayIcon from "@material-ui/icons/Today";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import ChartistGraph from "react-chartist";
import SoilDataTable from "glite-components/nutrient-reporter/SoilDataTable";
import HourlyAnalytics from "glite-components/nutrient-reporter/HourlyAnalytics";
import DailyAnalytics from "glite-components/nutrient-reporter/DailyAnalytics";
import WeeklyAnalytics from "glite-components/nutrient-reporter/WeeklyAnalytics";
import MonitorAlerts from "glite-components/nutrient-reporter/MonitorAlerts";
//import SoilDataMapView from "glite-components/nutrient-reporter/SoilDataMapView";
import WarningIcon from "@material-ui/icons/WarningRounded";
import {
  soilDataTableSchema,
  soilDataTableHeaders,
  soilDataBaseUrl,
  soilDataTableTitle,
  soilDataTableIdField,
  createSoilDataRecord,
} from "helpers/schemas/SoilData";

export default function SoilData() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title="Reports:"
          headerColor="success"
          tabs={[
            /*
            {
              tabName: "Soil Data Map",
              tabIcon: WarningIcon,
              tabContent: <SoilDataMapView />
            },
            */
            {
              tabName: "Alerts",
              tabIcon: WarningIcon,
              tabContent: <MonitorAlerts />,
            },
            {
              tabName: "Daily",
              tabIcon: TodayIcon,
              tabContent: <DailyAnalytics />,
            },
            {
              tabName: "Hourly",
              tabIcon: AccessAlarmIcon,
              tabContent: <HourlyAnalytics />,
            },
            {
              tabName: "Weekly",
              tabIcon: ViewWeekIcon,
              tabContent: <WeeklyAnalytics />,
            },
            {
              tabName: "Raw Tabular",
              tabIcon: TableChartIcon,
              tabContent: (
                <SoilDataTable
                  schema={soilDataTableSchema}
                  headers={soilDataTableHeaders}
                  soilDataUrl={soilDataBaseUrl}
                  title={soilDataTableTitle}
                  idField={soilDataTableIdField}
                  creator={createSoilDataRecord}
                />
              ),
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
