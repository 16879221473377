import React, { useState, useEffect } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import FailedIcon from "@material-ui/icons/SmsFailed";
import SuccessIcon from "@material-ui/icons/DoneAll";
import People from "@material-ui/icons/People";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import OrderSummaryTable from "glite-components/shop-manager/OrderSummaryTable";
import SupplierSummaryTable from "glite-components/shop-manager/SupplierSummaryTable";
import ProductTypeTable from "glite-components/shop-manager/ProductTypeTable";
import ProductUnitCrud from "glite-components/shop-manager/ProductUnitCrud";
import axios from "axios";
import { baseUrl } from "helpers/Constants";

const useStyles = makeStyles(styles);
function createData(entry, seeds, fertilizers, total) {
  return { entry, seeds, fertilizers, total };
}
export default function ShopManager() {
  const [productTypes, setProductTypes] = useState([]);
  //these variables should be converted to state
  const numSuppliers = 50;
  const numProducts = 157;
  const orderSummary = {
    seeds: {
      totalCount: 30,
      totalValue: 1500000,
      successfulCount: 29,
      successfulValue: 1350000,
      failedCount: 1,
      failedValue: 150000,
    },
    fertilizers: {
      totalCount: 255,
      totalValue: 7000000,
      successfulCount: 241,
      successfulValue: 6500000,
      failedCount: 14,
      failedValue: 450000,
    },
    all: {
      totalCount: 300,
      totalValue: 9000000,
      successfulCount: 285,
      successfulValue: 8500000,
      failedCount: 15,
      failedValue: 500000,
    },
  };
  const supplierSummary = [
    {
      id: 30,
      name: 1500000,
      productTypes: 29,
      totalSales: 1350000,
    },
    {
      id: 255,
      name: 7000000,
      productTypes: 241,
      totalSales: 6500000,
    },
    {
      id: 300,
      name: 9000000,
      productTypes: 285,
      totalSales: 8500000,
    },
  ];
  const rows = [
    createData(
      "#Orders",
      orderSummary.seeds.totalCount,
      orderSummary.fertilizers.totalCount,
      orderSummary.all.totalCount
    ),
    createData(
      "#Success",
      orderSummary.seeds.successfulCount,
      orderSummary.fertilizers.successfulCount,
      orderSummary.all.successfulCount
    ),
    createData(
      "#Failed",
      orderSummary.seeds.failedCount,
      orderSummary.fertilizers.failedCount,
      orderSummary.all.failedCount
    ),
    createData(
      "Total Value",
      orderSummary.seeds.totalValue,
      orderSummary.fertilizers.totalValue,
      orderSummary.all.totalValue
    ),
    createData(
      "Success Value",
      orderSummary.seeds.successfulValue,
      orderSummary.fertilizers.successfulValue,
      orderSummary.all.successfulValue
    ),
    createData(
      "Failed Value",
      orderSummary.seeds.failedValue,
      orderSummary.fertilizers.failedValue,
      orderSummary.all.failedValue
    ),
  ];
  useEffect(() => {
    axios
      .get(`${baseUrl}product-types`)
      .then((response) => {
        let types = [];
        response.data.map((type) =>
          types.push({ id: type.id, name: type.name })
        );
        setProductTypes(types);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
  }, []);
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <People />
              </CardIcon>
              <p className={classes.cardCategory}>#Suppliers</p>
              <h3 className={classes.cardTitle}>{numSuppliers}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {/* Just Updated */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>#Products</p>
              <h3 className={classes.cardTitle}>{numProducts}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                {/* Last 24 Hours */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <SuccessIcon />
              </CardIcon>
              <p className={classes.cardCategory}>#Successful Orders</p>
              <h3 className={classes.cardTitle}>
                {orderSummary.all.successfulCount}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                {/* Tracked from Github */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="danger">
                <FailedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>#Failed Orders</p>
              <h3 className={classes.cardTitle}>
                {orderSummary.all.failedCount}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                {/* <div>Get more space</div> */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader title="Order summaries" color="success">
              <h4 className={classes.cardTitleWhite}>Order Summaries</h4>
            </CardHeader>
            <CardBody>
              <OrderSummaryTable rows={rows} />
            </CardBody>
          </Card>
          <Card>
            <CardHeader title="Supplier summaries" color="warning">
              <h4 className={classes.cardTitleWhite}>Supplier Summaries</h4>
            </CardHeader>
            <CardBody>
              <SupplierSummaryTable rows={supplierSummary} />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Product Types</h4>
            </CardHeader>
            <CardBody>
              <ProductTypeTable rows={productTypes} />
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Product Units</h4>
            </CardHeader>
            <CardBody>
              <ProductUnitCrud />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
