import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import EnhancedTable from "components/EnhancedTable";
import NewProductForm from "./NewProductForm";
import EditProductForm from "./EditProductForm";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "./CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  productTableHeaders,
  createProductRecord,
  productTableTitle,
} from "../../helpers/schemas/Product";
import UserProfile from "glite-views/UserProfile";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function SupplierProducts({ supplierId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [profileView, setProfileView] = useState(false);
  const [productUnits, setProductUnits] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [fertilizerGrades, setFertilizerGrades] = useState([]);
  const [cropTypes, setCropTypes] = useState([]);
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}products?supplierId=${supplierId}`)
      .then((response) => {
        let result = response.data.map((rec) => createProductRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
    axios
      .get(`${baseUrl}product-units`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.label };
        });
        setProductUnits(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });

    axios
      .get(`${baseUrl}product-types`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setProductTypes(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
    axios
      .get(`${baseUrl}fertilizers`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setFertilizerGrades(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
    axios
      .get(`${baseUrl}crops`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setCropTypes(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
  }, []);
  function handleBackToCreate() {
    setFormData({});
  }
  function handleBackToEdit() {
    setProfileView(false);
  }
  function handleViewProfile() {
    setProfileView(true);
  }
  function loadEditData(tableData) {
    let userSelected = {};
    Object.keys(tableData).forEach(
      (k) => (userSelected[k] = tableData[k].value)
    );
    return userSelected;
  }
  function saveEdit(editedUser) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "products/" + editedUser.id, editedUser)
      .then((newRecord) => {
        let transformed = createProductRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === editedUser.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function create(newProduct) {
    console.log("xxx creating product", newProduct);
    newProduct.supplierId = supplierId;
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(`${baseUrl}products`, newProduct)
      .then((response) => {
        console.log("nrew response", response.data);
        // console.log("nrew record", createProductRecord(response.data));
        // let newData = [...data, createProductRecord(response.data)];
        // console.log("nrew data", newData);
        // setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        // if (error.response.status === 400) {
        //   setUserError(error.response.data.message);
        // }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  const classes = useStyles();
  return profileView ? (
    <div>
      <Tooltip title="Back to edit">
        <IconButton onClick={handleBackToEdit}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <UserProfile userId={formData.id.value} />
    </div>
  ) : (
    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <EditProductForm
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              onViewProfile={handleViewProfile}
            />
          </div>
        ) : (
          <div>
            <NewProductForm
              onCreate={create}
              loading={loading}
              status={status}
              userError={userError}
              productTypes={productTypes}
              productUnits={productUnits}
              fertilizerGrades={fertilizerGrades}
              cropTypes={cropTypes}
            />
          </div>
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title={productTableTitle}
        headers={productTableHeaders}
        data={data}
        setUserSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
SupplierProducts.propTypes = { supplierId: PropTypes.number.isRequired };
