import * as React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeDate } from "../../helpers/utils";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{makeDate(row.createdAt)}</TableCell>
        <TableCell align="right">{makeDate(row.updatedAt)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Watched Parameters
              </Typography>
              <Table size="small" aria-label="parameters">
                <TableHead>
                  <TableRow>
                    <TableCell>Nutrient</TableCell>
                    <TableCell>Bound</TableCell>
                    <TableCell align="right">Bound Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {JSON.parse(row.observedParams).map((nutParam, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {nutParam.param}
                      </TableCell>
                      <TableCell>{nutParam.bound}</TableCell>
                      <TableCell align="right">{nutParam.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.object,
};

export default function MonitorsTable({ monitors }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Monitor Name</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right">Last Modified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {monitors.map((monitor, idx) => (
            <Row key={idx} row={monitor} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
MonitorsTable.propTypes = {
  monitors: PropTypes.array.isRequired,
};
