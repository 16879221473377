import * as React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeDate } from "../../helpers/utils";

export default function MonitorTargetsTable({ monitorTargets }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {/* <TableCell>ID</TableCell> */}
            <TableCell>Monitor</TableCell>
            <TableCell>Scope</TableCell>
            <TableCell>Target</TableCell>
            <TableCell>Channels</TableCell>
            <TableCell>Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {monitorTargets.map((target, idx) => (
            <TableRow key={idx}>
              <TableCell component="th" scope="row">
                {target.id}
              </TableCell>
              <TableCell>{target.monitor.name}</TableCell>
              <TableCell>{target.scope}</TableCell>
              <TableCell>{target.targetId}</TableCell>
              <TableCell>{target.notificationChannels}</TableCell>
              <TableCell>{makeDate(target.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
MonitorTargetsTable.propTypes = {
  monitorTargets: PropTypes.array.isRequired,
};
