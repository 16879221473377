import React from "react";
import Iframe from 'react-iframe';
import { baseUrl } from "../../helpers/Constants";



export default function SoilDataMapView() {
    const height = window.innerHeight;

    return (
        <div>
            <Iframe url={baseUrl+'soilDataMap'}
                width="98%"
                height={height - 100}
                id=""
                className=""
                display="block"
                position="relative"/>
        </div>
    );
}
