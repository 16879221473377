import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Download from "@material-ui/icons/CloudDownloadOutlined";
import PropTypes from "prop-types";

export const DeleteButton = (props) => {
  return (
    <IconButton
      onClick={() => {
        let data = props.retrieveData();
        props.doDelete(data[props.idField].value);
      }}
    >
      <DeleteIcon color="secondary" />
    </IconButton>
  );
};
export const EditButton = (props) => {
  return (
    <IconButton
      onClick={() => {
        let data = props.retrieveData();
        props.launchEdit(data);
      }}
    >
      <EditIcon color="primary" />
    </IconButton>
  );
};
export const CreateButton = (props) => {
  return (
    <Tooltip title="Create new record">
      <IconButton
        aria-label="Add"
        onClick={() => {
          props.launchCreate(props.data);
        }}
      >
        <AddIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
};
export const DownloadButton = ({ launchDownload }) => {
  return (
    <Tooltip title="Download">
      <IconButton aria-label="Add" onClick={launchDownload}>
        <Download color="primary" />
      </IconButton>
    </Tooltip>
  );
};
DeleteButton.propTypes = {
  idField: PropTypes.string.isRequired,
  doDelete: PropTypes.func.isRequired,
  retrieveData: PropTypes.func.isRequired,
};
EditButton.propTypes = {
  launchEdit: PropTypes.func.isRequired,
  retrieveData: PropTypes.func.isRequired,
};
CreateButton.propTypes = {
  launchCreate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
DownloadButton.propTypes = {
  launchDownload: PropTypes.func.isRequired,
};
