import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const cropTableHeaders = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  { id: "name", numeric: false, disablePadding: true, label: "Crop  Name" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const cropTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  name: { value: "", type: "text", label: "Crop Name" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createCropRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    name: { value: data.name, type: "text", label: "Crop Name" },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const cropTableIdField = "id";
export const cropTableTitle = "Crops";
export const cropBaseUrl = baseUrl + "crops";
