import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  // textField: {
  //   width: "25ch",
  // },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
}));

export default function ComboBox({ label, onSelect, value, kvOptions, error }) {
  const classes = useStyles();
  const actualLabel = error === true ? label + " is required" : label;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">{actualLabel}</InputLabel>
      <Select
        error={error}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={onSelect}
      >
        {kvOptions.map((option, index) => (
          <MenuItem key={index} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
ComboBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  kvOptions: PropTypes.array.isRequired,
  error: PropTypes.bool,
};
