import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { SuccessAlert, ErrorAlert } from "components/Alerts";
import { phoneNoValidates, isEmptyObject } from "helpers/utils";
import ComboBox from "components/ComboBox";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  updateButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function EditDeviceForm({
  loading,
  onEdit,
  formData,
  status,
  deviceTypes,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  useEffect(() => {
    console.log("xxx form data", JSON.stringify(formData));
    setData(formData);
    setErrors({});
    return () => setData({});
  }, [formData]);
  const handleTypeSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["deviceTypeId"] = e.target.value;
    setData(oldData);
  };
  const handleClick = () => {
    let errorList = {};
    if (!data.number) errorList.number = "field cannot be empty";
    if (!data.deviceTypeId) errorList.deviceTypeId = "field cannot be empty";
    if (!data.msisdn) errorList.msisdn = "field cannot be empty";
    if (data.msisdn && !phoneNoValidates(data.msisdn))
      errorList.msisdn = "Invalid msisdn format";
    if (isEmptyObject(errorList)) {
      let changes = Object.fromEntries(
        Object.entries(data).filter(([k, v]) => k === "id" || v !== formData[k])
      );
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  function handleChange(event, name) {
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          error={checkError("number")}
          label="Device Num"
          id="number"
          value={data.number}
          onChange={(e) => handleChange(e, "number")}
          variant="outlined"
          helperText={errors.number}
          size="small"
        />
        <TextField
          error={checkError("msisdn")}
          label="Msisdn"
          id="msisdn"
          value={data.msisdn}
          onChange={(e) => handleChange(e, "msisdn")}
          variant="outlined"
          helperText={errors.msisdn}
          size="small"
        />
        <ComboBox
          value={data["deviceTypeId"]}
          label={"Type"}
          onSelect={handleTypeSelection}
          kvOptions={deviceTypes}
          error={checkError("deviceTypeId")}
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && <ErrorAlert msg="Update failed!" />}
      {!loading && status === "SUCCESS" && (
        <SuccessAlert msg="Update successful!" />
      )}
      <div className={classes.updateButton}>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => handleClick(data)}
        >
          Update
        </Button>
      </div>
    </form>
  );
}
EditDeviceForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  deviceTypes: PropTypes.array.isRequired,
};
