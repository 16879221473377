import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);
export default function NutruentRecommendationPane(props) {
  const classes = useStyles();
  const { recommendation } = props;
  const fertilizers = recommendation.supplementalFertilizers;

  function recommendationData(fertilizers) {
    let totalN = 0;
    let totalP = 0;
    let totalK = 0;
    let total = 0;
    let rows = fertilizers.map((fert) => {
      const type = fert.type;
      const qty = fert.quantityKg;
      total += qty;
      const n = type.unitN * qty;
      totalN += n;
      const p = type.unitP * qty;
      totalP += p;
      const k = type.unitK * qty;
      totalK += k;
      return [
        <p key={1}>
          <b>{type.name}</b>
        </p>,
        <h4 key={2}>
          <b>{qty.toFixed(2)}</b>
        </h4>,
        n.toFixed(2),
        p.toFixed(2),
        k.toFixed(2),
      ];
    });

    let totals = [
      <h4 key={1}>
        <b>{"Totals "}</b>
      </h4>,
      <h4 key={2}>
        <b>{total.toFixed(2)}</b>
      </h4>,
      <h4 key={3}>
        <b>{totalN.toFixed(2)}</b>
      </h4>,
      <h4 key={4}>
        <b>{totalP.toFixed(2)}</b>
      </h4>,
      <h4 key={5}>
        <b>{totalK.toFixed(2)}</b>
      </h4>,
    ];
    rows.push(totals);
    return rows;
  }
  return (
    <Card>
      <CardHeader color="success">
        <h4 className={classes.cardTitleWhite}>Recommended Supplementation</h4>
        {/* <p className={classes.cardCategoryWhite}>
              New employees on 15th September, 2016
            </p> */}
      </CardHeader>
      <CardBody>
        <Table
          tableHeaderColor="success"
          tableHead={[
            "Grade",
            "Qty(Kg)",
            "Nitrogen(Kg)",
            "Phosphorus(Kg)",
            "Potassium(Kg)",
          ]}
          tableData={recommendationData(fertilizers)}
        />
      </CardBody>
    </Card>
  );
}

NutruentRecommendationPane.propTypes = {
  recommendation: PropTypes.object.isRequired,
};
