import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import ComboBox from "components/ComboBox";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function UptakeEditor({
  loading,
  onEdit,
  status,
  formData,
  crops,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [cropsKv, setCropsKv] = useState([]);
  useEffect(() => {
    setData(formData);
    setCropsKv(crops);
    setErrors({});
    return () => setData({});
  }, [formData, crops]);
  const handleChange = (event, name) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handleClick = () => {
    let errorList = {};
    // if (!data.yieldKgSqm) errorList.yieldKgSqm = "Must contain a value";
    // if (!data.nitrogen) errorList.nitrogen = "Must contain a value";
    // if (!data.phosphorus) errorList.phosphorus = "Invalid input";
    // if (!data.potassium) errorList.potassium = "Invalid input";
    // if (!data.magnesium) errorList.magnesium = "Invalid input";
    // if (!data.calcium) errorList.calcium = "Invalid input";
    if (emptyObject(errorList)) {
      let changes = Object.fromEntries(
        Object.entries(data).filter(([k, v]) => k === "id" || v !== formData[k])
      );
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <ComboBox
          label="Crop"
          onSelect={(e) => handleChange(e, "cropId")}
          value={data.cropId}
          kvOptions={cropsKv}
        />
        <TextField
          error={checkError("yieldKgSqm")}
          label="Yield(tonnes)"
          id="yieldKgSqm"
          value={data.yieldKgSqm}
          onChange={(e) => handleChange(e, "yieldKgSqm")}
          variant="outlined"
          helperText={errors.yieldKgSqm}
          size="small"
        />
        <TextField
          error={checkError("nitrogen")}
          label="Nitrogen"
          id="nitrogen"
          value={data.nitrogen}
          onChange={(e) => handleChange(e, "nitrogen")}
          variant="outlined"
          helperText={errors.nitrogen}
          size="small"
        />
        <TextField
          error={checkError("phosphorus")}
          label="Phosphorus"
          id="phosphorus"
          value={data.phosphorus}
          onChange={(e) => handleChange(e, "phosphorus")}
          variant="outlined"
          helperText={errors.phosphorus}
          size="small"
        />
      </div>
      <div>
        <TextField
          error={checkError("potassium")}
          label="Potassium"
          id="potassium"
          value={data.potassium}
          onChange={(e) => handleChange(e, "potassium")}
          variant="outlined"
          helperText={errors.potassium}
          size="small"
        />
        <TextField
          error={checkError("calcium")}
          label="Calcium"
          id="calcium"
          value={data.calcium}
          onChange={(e) => handleChange(e, "calcium")}
          variant="outlined"
          helperText={errors.calcium}
          size="small"
        />
        <TextField
          error={checkError("magnesium")}
          label="Magnesium"
          id="magnesium"
          value={data.magnesium}
          onChange={(e) => handleChange(e, "magnesium")}
          variant="outlined"
          helperText={errors.magnesium}
          size="small"
        />
         <TextField
          error={checkError("growPeriodMin")}
          label="Total Growing Period MIN"
          id="growPeriodMin"
          value={data.growPeriodMin}
          onChange={(e) => handleChange(e, "growPeriodMin")}
          variant="outlined"
          helperText={errors.growPeriodMin}
          size="small"
        />
        <TextField
          error={checkError("growPeriodMax")}
          label="Total Growing Period MAX"
          id="growPeriodMax"
          value={data.growPeriodMax}
          onChange={(e) => handleChange(e, "growPeriodMax")}
          variant="outlined"
          helperText={errors.growPeriodMax}
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Update successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Update
        </Button>
      </div>
    </form>
  );
}
UptakeEditor.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  formData: PropTypes.object,
  crops: PropTypes.array,
};
