import { baseUrl } from "../Constants";
import { makeDate } from "../utils";

export const subcountyTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "districtId", numeric: false, disablePadding: true, label: "District" },
  { id: "name", numeric: false, disablePadding: true, label: "Subcounty" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const subcountyTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  districtId: { value: "", type: "text", label: "District" },
  name: { value: "", type: "text", label: "Subcounty" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createSubcountyRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    districtId: { value: data.district.name, type: "text", label: "district" },
    name: { value: data.name, type: "text", label: "Subcounty" },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const subcountyTableIdField = "id";
export const subcountyTableTitle = "Subcounties";
export const subcountyBaseUrl = baseUrl + "subcounties";
