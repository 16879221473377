import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import ComboBox from "components/ComboBox";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function CreateMonitorTargetForm({
  loading,
  status,
  onSave,
  monitors,
  farmers,
  farms,
  devices,
}) {
  const classes = useStyles();
  const [monitor, setMonitor] = useState();
  const [monitorKv, setMonitorKv] = useState([]);
  const [targetsKv, setTargetsKv] = useState([]);
  const [scope, setScope] = useState("DEVICE");
  const [target, setTarget] = useState();
  useEffect(() => {
    if (scope === "DEVICE") {
      setTargetsKv(
        devices.map((device) => ({
          key: device.id,
          value: `${device.farm.name}-${device.farm.crop.name}(${device.number})`,
        }))
      );
    } else if (scope === "FARM") {
      setTargetsKv(farms.map((farm) => ({ key: farm.id, value: farm.name })));
    } else if (scope === "FARMER") {
      setTargetsKv(
        farmers.map((farmer) => ({
          key: farmer.id,
          value: `${farmer.firstName} ${farmer.lastName}`,
        }))
      );
    }
  }, [scope, devices, farms, farmers]);

  useEffect(() => {
    setMonitorKv(
      monitors.map((monitor) => ({ key: monitor.id, value: monitor.name }))
    );
  }, [monitors]);
  const scopesKv = [
    { key: "DEVICE", value: "DEVICE" },
    { key: "FARM", value: "FARM" },
    { key: "FARMER", value: "FARMER" },
  ];

  const handleSave = () => {
    let data = {
      monitorId: monitor,
      targetId: target,
      scope,
      notificationChannels: "WEB",
    };
    onSave(data);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <ComboBox
          label="Select monitor"
          onSelect={(e) => setMonitor(e.target.value)}
          value={monitor}
          kvOptions={monitorKv}
        />
      </div>
      <div>
        <ComboBox
          label="Scope"
          onSelect={(e) => setScope(e.target.value)}
          value={scope}
          kvOptions={scopesKv}
        />
      </div>
      <div>
        <ComboBox
          label="Target"
          onSelect={(e) => setTarget(e.target.value)}
          value={target}
          kvOptions={targetsKv}
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Update successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button
          color="success"
          round
          disabled={loading}
          onClick={() => handleSave()}
        >
          Create
        </Button>
      </div>
    </form>
  );
}
CreateMonitorTargetForm.propTypes = {
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  status: PropTypes.string,
  monitors: PropTypes.array,
  farmers: PropTypes.array,
  farms: PropTypes.array,
  devices: PropTypes.array,
};
