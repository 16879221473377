import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import "date-fns";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmptyObject } from "helpers/utils";
import ComboBox from "components/ComboBox";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function InsuranceCreator({
  loading,
  onCreate,
  status,
  userError,
  farmsKv,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const handleChange = (event, name) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const handleFarmSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["farmId"] = e.target.value;
    setData(oldData);
  };
  const handleClick = () => {
    let errorList = {};
    //const abValue2Insure = (params) => {
    //return params.data.price *  params.data.farmSize * params.data.expectedvalue;
    //};
    //if (!data.ownerId) errorList.ownerId = "field cannot be empty";
    //if (!data.phoneNo) errorList.phoneNo = "field cannot be empty";
    if (!data.farmId) errorList.farmId = "field cannot be empty";
    //if (!data.villageId) errorList.villageId = "field cannot be empty";
    //if (!data.cropId) errorList.cropId = "field cannot be empty";
    if (!data.farmSize) errorList.farmSize = "field cannot be empty";
    //if (!data.groupName) errorList.groupName = "field cannot be empty";
    if (!data.price) errorList.price = "field cannot be empty";
    if (!data.expectedvalue) errorList.expectedvalue = "field cannot be empty";
    if (!data.subsidyperc) errorList.subsidyperc= "field cannot be empty";
    if (!data.schemerate) errorList.schemerate = "field cannot be empty";
    //if (null==abValue2Insure) errorList.value2insure = "field cannot be empty";
    //if (!data.subsidy) errorList.subsidy = "field cannot be empty";
    //if (!data.traininglevy) errorList.traininglevy = "field cannot be empty";
    if (!data.stampduty) errorList.stampduty = "field cannot be empty";
    //if (!data.netpremium) errorList.netpremium = "field cannot be empty";
    if (isEmptyObject(errorList)) {
	data.value2insure=data.price *  data.farmSize * data.expectedvalue;
	data.basicpremium=((data.schemerate/100)*  data.value2insure);
	data.subsidy=((data.subsidyperc/100)*  data.basicpremium);
	data.traininglevy=(data.basicpremium * 0.005);
	data.netpremium=parseInt(data.basicpremium - data.subsidy) + parseInt(data.traininglevy) + parseInt(data.stampduty);
      onCreate(data);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <ComboBox
          error={errors["farmId"]}
          value={data["farmId"]}
          label={"Farm Name"}
          onSelect={handleFarmSelection}
          kvOptions={farmsKv}
        />
       <TextField
          error={checkError("farmSize")}
          label="Farm Size(ACRES)"
          id="farmSize"
          value={data.farmSize}
          onChange={(e) => handleChange(e, "farmSize")}
          variant="outlined"
          helperText={errors.farmSize}
          size="small"
        /> 
       <TextField
          error={checkError("groupName")}
          label="Group Name"
          id="groupName"
          value={data.groupName}
          onChange={(e) => handleChange(e, "groupName")}
          variant="outlined"
          helperText={errors.groupName}
          size="small"
        /> 
      <TextField
          error={checkError("price")}
          label="Price Per KG(Ugx"
          id="price"
          value={data.price}
          onChange={(e) => handleChange(e, "price")}
          variant="outlined"
          helperText={errors.price}
          size="small"
        />           
      </div>
      <div>
       <TextField
          error={checkError("expectedvalue")}
          label="Expected Value"
          id="expectedvalue"
          value={data.expectedvalue}
          onChange={(e) => handleChange(e, "expectedvalue")}
          variant="outlined"
          helperText={errors.expectedvalue}
          size="small"
        />
      <TextField
          error={checkError("schemerate")}
          label="Scheme Rate"
          id="schemerate"
          value={data.schemerate}
          onChange={(e) => handleChange(e, "schemerate")}
          variant="outlined"
          helperText={errors.schemerate}
          size="small"
        /> 
      <TextField
          error={checkError("%subsidy")}
          label="%Subsidy"
          id="subsidyperc"
          value={data.subsidyperc}
          onChange={(e) => handleChange(e, "subsidyperc")}
          variant="outlined"
          helperText={errors.subsidyperc}
          size="small"
        />          
      <TextField
          error={checkError("stampduty")}
          label="Stamp Duty"
          id="stampduty"
          value={data.stampduty}
          onChange={(e) => handleChange(e, "stampduty")}
          variant="outlined"
          helperText={errors.stampduty}
          size="small"
        />      
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {userError || "Insurance creation failed!"}
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Registration for Insurance was successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Register for Insurance 
        </Button>
      </div>
    </form>  
  );
}
InsuranceCreator.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
  farmsKv: PropTypes.array.isRequired,
};
