import React from "react";
import PropTypes from "prop-types";
import CartItems from "../shop/CartItems";

export default function ShoppingCart({ cartItems, keyedProducts }) {
  const items = Object.entries(cartItems)
    .filter((entry) => entry[1] > 0)
    .map(([key, value]) => {
      let prod = keyedProducts[key];
      let desc = prod.name;
      let supplier = prod.supplier.firstName + " " + prod.supplier.lastName;
      let unitPrice = prod.unitPrice;
      let qty = value;
      let unit = prod.unit.label;
      return { desc, supplier, qty, unitPrice, unit };
    });
  return (
    <div>
      <CartItems items={items} />
    </div>
  );
}
ShoppingCart.propTypes = {
  cartItems: PropTypes.object.isRequired,
  keyedProducts: PropTypes.object.isRequired,
};
