import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { baseUrl } from "helpers/Constants";
import { useAuth } from "helpers/use-auth";
import {
  monitorEventTableSchema,
  monitorEventTableHeaders,
  monitorEventTableTitle,
  monitorEventIdField,
  createMonitorEventRecord,
} from "helpers/schemas/MonitorEvent";
import MonitorEventsTable from "./MonitorEventsTable";
import { tableStyles } from "../../components/styles/MainBodyStyle";

const useStyles = makeStyles(tableStyles);

export default function MonitorAlerts() {
  const auth = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const pageSize = 15;
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(pageSize);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${baseUrl}monitor-events/${auth.user.id}?page=${page}&size=${
          rowsToFetch < 1 ? pageSize : rowsToFetch
        }`
      )
      .then((response) => {
        let result = response.data.elements.map((row) =>
          createMonitorEventRecord(row)
        );
        setAlerts(result);
        setEmptyRows(pageSize - rowsToFetch);
        setLoading(false);
        // setRowsToFetch(Math.min(pageSize, response.data.total - page * pageSize));
        setRowsToFetch(Math.min(pageSize, response.data.total - pageSize));
        // setRowsToFetch(pageSize);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);
  function paginate(pageNum) {
    if (pageNum < page) {
      setRowsToFetch(pageSize);
    }
    setPage(pageNum);
  }
  const classes = useStyles();
  return (
    <div>
      <Paper className={classes.root}>
        <MonitorEventsTable
          classes={classes}
          tableSchema={monitorEventTableSchema}
          tableHeaders={monitorEventTableHeaders}
          tableTitle={monitorEventTableTitle}
          idField={monitorEventIdField}
          loading={loading}
          data={alerts}
          emptyRows={emptyRows}
        />
        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={pageSize}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          rowsPerPageOptions={[pageSize]}
          onPageChange={(event, newPage) => paginate(newPage)}
        />
      </Paper>
    </div>
  );
}
