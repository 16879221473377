import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "components/Table/CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RoomIcon from "@material-ui/icons/RoomRounded";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { farmTableHeaders, createFarmRecord } from "../../helpers/schemas/Farm";
import FarmEditor from "./FarmEditor";
import FarmCreator from "./FarmCreator";
import GoogleMapsContainer from "./GoogleMapsContainer";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function FarmCrud() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [villagesKv, setVillagesKv] = useState([]);
  const [cropsKv, setCropsKv] = useState([]);
  const [usersKv, setUsersKv] = useState([]);
  const [mapView, setMapView] = useState(false);
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}farms`)
      .then((response) => {
        let result = response.data.map((rec) => createFarmRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
    axios
      .get(`${baseUrl}villages`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setVillagesKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
    axios
      .get(`${baseUrl}crops`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setCropsKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
    axios
      .get(`${baseUrl}users`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.firstName + " " + rec.lastName };
        });
        setUsersKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
  }, []);

  function getMarkers() {
    return data.map((farm) => {
      let [lat, lng] = farm.coordinates.value.split(",");
      let position = { lat, lng };
      let title = `${farm.name.value}|${farm.ownerId.value}|${farm.crop.value}`;
      return { title, position };
    });
  }
  function loadEditData(tableData) {
    let selected = {};
    Object.keys(tableData).forEach((k) => (selected[k] = tableData[k].value));
    return selected;
  }
  function saveEdit(edited) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "farms/" + edited.id, edited)
      .then((newRecord) => {
        let transformed = createFarmRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === edited.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function create(record) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(baseUrl + "farms", record)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function handleBackToCreate() {
    setFormData({});
  }
  function handleBackListView() {
    setMapView(false);
  }
  function handleLaunchMapView() {
    setMapView(true);
  }
  const classes = useStyles();
  return mapView ? (
    <div style={{ height: "900px" }}>
      <Tooltip title="Back to list view">
        <IconButton onClick={handleBackListView}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <GoogleMapsContainer markers={getMarkers()} />
    </div>
  ) : (
    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <FarmEditor
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              villagesKv={villagesKv}
              cropsKv={cropsKv}
              usersKv={usersKv}
            />
          </div>
        ) : (
          <div>
            <Tooltip title="Launch Map View">
              <IconButton onClick={handleLaunchMapView}>
                <RoomIcon />
              </IconButton>
            </Tooltip>
            <FarmCreator
              onCreate={create}
              loading={loading}
              status={status}
              userError={userError}
              villagesKv={villagesKv}
              cropsKv={cropsKv}
              usersKv={usersKv}
            />
          </div>
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="Farms"
        headers={farmTableHeaders}
        data={data}
        setSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
