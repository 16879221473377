import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import EnhancedTable from "components/EnhancedTable";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TransactionsTableWrapper from "glite-components/payment-manager/TransactionsTableWrapper";

import {
  paymentProviderTableSchema,
  paymentProviderTableHeaders,
  paymentProviderBaseUrl,
  paymentProviderTableTitle,
  paymentProviderTableIdField,
  createPaymentProviderRecord,
} from "helpers/schemas/PaymentProvider";

export default function PaymentManager() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title="Payment:"
          headerColor="success"
          tabs={[
            {
              tabName: "Transactions",
              tabIcon: FilterVintageIcon,
              tabContent: <TransactionsTableWrapper />,
            },
            {
              tabName: "Providers",
              tabIcon: FilterVintageIcon,
              tabContent: (
                <EnhancedTable
                  tableSchema={paymentProviderTableSchema}
                  tableHeaders={paymentProviderTableHeaders}
                  baseUrl={paymentProviderBaseUrl}
                  tableTitle={paymentProviderTableTitle}
                  idField={paymentProviderTableIdField}
                  createRecord={createPaymentProviderRecord}
                />
              ),
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
