import React, { useState, useEffect } from "react";
// import EnhancedTable from "components/EnhancedTable";
import NewLanguageForm from "./NewLanguageForm";
import EditLanguageForm from "./EditLanguageForm";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "./CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { languageTableHeaders, createLanguageRecord } from "../../helpers/schemas/Language";
import LanguageProfile from "glite-views/LanguageProfile";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function LanguageData() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [profileView, setProfileView] = useState(false);
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}languages`)
      .then((response) => {
        let result = response.data.map((rec) => createLanguageRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
  }, []);
  function handleBackToCreate() {
    setFormData({});
  }
  function handleBackToEdit() {
    setProfileView(false);
  }
  function handleViewProfile() {
    setProfileView(true);
  }
  
  function loadEditData(tableData) {
    let languageSelected = {};
    Object.keys(tableData).forEach(
      (k) => (languageSelected[k] = tableData[k].value)
    );
    return languageSelected;
  }
  function saveEdit(editedLanguage) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "languages/" + editedLanguage.id, editedLanguage)
      .then((newRecord) => {
        let transformed = createLanguageRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === editedLanguage.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  
  function create(newLanguage) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(baseUrl + "languages", newLanguage)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        // if (error.response.status === 400) {
        //   setUserError(error.response.data.message);
        // }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  const classes = useStyles();
  return profileView ? (
    <div>
      <Tooltip title="Back to edit">
        <IconButton onClick={handleBackToEdit}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      {profileView && <LanguageProfile languageId={formData.id.value} />}
    </div>
  ) : (
    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <EditLanguageForm
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              onViewProfile={handleViewProfile}
            />
          </div>
        ) : (
          <NewLanguageForm
            onCreate={create}
            loading={loading}
            status={status}
            userError={userError}
          />
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="Langauges"
        headers={languageTableHeaders}
        data={data}
        setLanguageSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
