import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { ProvideAuth } from "./helpers/use-auth";

// core components
import Admin from "layouts/Admin.js";
import Login from "components/Login/Login2";

import "assets/css/material-dashboard-react.css?v=1.10.0";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};
ReactDOM.render(
  <ProvideAuth>
    <Provider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/login" component={Login} />
          <Redirect from="/" to="/admin" />
        </Switch>
      </BrowserRouter>
    </Provider>
  </ProvideAuth>,
  document.getElementById("root")
);
