import React, { useEffect, useState } from "react";
import { useAuth } from "helpers/use-auth";
import { baseUrl } from "helpers/Constants";
import axios from "axios";
import GenericTable from "components/GenericTable";
import {
  orderItemTableHeaders,
  createOrderItemRecord,
  orderItemTableTitle,
} from "helpers/schemas/OrderItem";
import { makeStyles } from "@material-ui/core/styles";
import { tableStyles } from "../../components/styles/MainBodyStyle";
const useStyles = makeStyles((theme) => ({
  ...tableStyles,
  selectorRoot: {
    width: "100%",
    marginBottom: "10px",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));
export default function SupplierOrders() {
  const classes = useStyles();
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // setError(undefined);
    setLoading(true);
    axios
      .get(`${baseUrl}order-items/bySupplier/${auth.user.id}`)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setData(response.data.map((row) => createOrderItemRecord(row)));
        setLoading(false);
        // setError(undefined);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        // setError(error.message);
        setLoading(false);
      });
  }, []);
  return (
    <GenericTable
      tableSchema={{}}
      tableHeaders={orderItemTableHeaders}
      baseUrl={baseUrl}
      tableTitle={orderItemTableTitle}
      idField={"id"}
      createRecord={createOrderItemRecord}
      allowEdit={false}
      allowDelete={false}
      allowCreate={false}
      loading={loading}
      data={data}
      classes={classes}
      emptyRows={0}
    />
  );
}
