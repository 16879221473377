import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { DownloadButton } from "./ActionWidgets";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { toolbarStyles } from "./styles/MainBodyStyle";
import DateRangePicker from "./DateRangePicker";
import { ErrorAlert } from "./Alerts";
import moment from "moment";
import Spinner from "./Spinner";

let ReportingTableToolbar = ({
  classes,
  onDownload,
  tableTitle,
  downloading,
}) => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [error, setError] = useState(undefined);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  function handleChangeFromDate(date) {
    setError(undefined);
    setFromDate(date);
  }
  function handleChangeToDate(date) {
    setError(undefined);
    setToDate(date);
  }
  function handleDownload() {
    if (!openDatePicker) {
      setError(undefined);
      setOpenDatePicker(true);
    } else {
      if (moment(fromDate).isSameOrAfter(toDate)) {
        setError("'From' date must be before 'to' date");
      } else if (moment(toDate).isAfter(new Date())) {
        setError("'to' date must not exceed current date");
      } else {
        onDownload(
          moment(fromDate).format("YYYY-MM-DD"),
          moment(toDate).format("YYYY-MM-DD")
        );
      }
    }
  }
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle">
          {tableTitle + " data"}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {openDatePicker && (
          <DateRangePicker
            onFromDate={handleChangeFromDate}
            onToDate={handleChangeToDate}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        {downloading && <Spinner />}
        <DownloadButton launchDownload={handleDownload} />
        {error && <ErrorAlert msg={error} />}
      </div>
    </Toolbar>
  );
};

ReportingTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  onDownload: PropTypes.func.isRequired,
  downloading: PropTypes.bool,
  tableTitle: PropTypes.string.isRequired,
};

export default withStyles(toolbarStyles)(ReportingTableToolbar);
