import React, { useState, useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import LineGraph from "./LineGraph";
import ProgressBar from "components/ProgressBar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "helpers/use-auth";
import axios from "axios";
import { baseUrl } from "helpers/Constants";
import moment from "moment";
import ReactRadialGauge from "./ReactRadialGauge";
import {
  extractChartData,
  // dataWithToolTip,
  // getMaxValues,
  last8HoursData,
  createHourlyChartData,
} from "helpers/utils";
// import { dailyFeedsChart } from "variables/charts.js";
// function maxValuesInit() {
//   return {
//     Nitrogen: 100,
//     Phosphorus: 100,
//     Potassium: 100,
//     Ph: 100,
//     Temp: 100,
//     Moisture: 100,
//     Conductivity: 100,
//     SignalLevel: 100,
//   };
// }
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function HourlyAnalytics() {
  const classes = useStyles();
  const auth = useAuth();
  const [hourlyN, setHourlyN] = useState({});
  const [hourlyP, setHourlyP] = useState({});
  const [hourlyK, setHourlyK] = useState({});
  const [hourlyPh, setHourlyPh] = useState({});
  const [hourlyTemp, setHourlyTemp] = useState({});
  const [hourlyMoisture, setHourlyMoisture] = useState({});
  const [hourlyEc, setHourlyEc] = useState({});
  const [hourlySignal, setHourlySignal] = useState({});
  // const [maxHourly, setMaxHourly] = useState(maxValuesInit());
  const [inProgress, setInProgress] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const handleDeviceSelection = (e, values) => {
    e.preventDefault();
    setSelectedDevices(values.map((d) => d.id));
  };
  useEffect(() => {
    setInProgress(true);

    if (devices.length === 0) {
      axios.get(`${baseUrl}user-devices/${auth.user.id}`).then((response) => {
        setDevices(response.data.map((e) => e.device));
      });
    }
    let devicesToUse =
      selectedDevices.length === 0 ? devices.map((d) => d.id) : selectedDevices;

    axios
      .get(`${baseUrl}analytics/hourly?devices=${devicesToUse.join()}`)
      .then((response) => {
        let dataArr = response.data.map((value) => [
          moment(value.date).format("YYYY-MM-DD hha"),
          value,
        ]);

        let dataObj = Object.fromEntries(dataArr);
        let last8HoursAvgs = last8HoursData(dataObj);
        let hourlyChartData = createHourlyChartData(last8HoursAvgs);
        // setMaxHourly(getMaxValues(hourlyChartData));
        setHourlyN(extractChartData("Nitrogen", hourlyChartData));
        setHourlyP(extractChartData("Phosphorus", hourlyChartData));
        setHourlyK(extractChartData("Potassium", hourlyChartData));
        setHourlyPh(extractChartData("Ph", hourlyChartData));
        setHourlyTemp(extractChartData("Temp", hourlyChartData));
        setHourlyMoisture(extractChartData("Moisture", hourlyChartData));
        setHourlyEc(extractChartData("Conductivity", hourlyChartData));
        setHourlySignal(extractChartData("SignalLevel", hourlyChartData));
        setInProgress(false);
      })
      .catch((error) => {
        setInProgress(false);
        console.log("xxxx error", error);
      });
  }, [selectedDevices]);
  return (
    <div>
      {/* <ReactRadialGauge /> */}
      <div className={classes.root}>
        <Autocomplete
          multiple
          // id="checkboxes-tags-demo"
          id="tags-standard"
          options={devices}
          disableCloseOnSelect
          getOptionLabel={(device) => device.number}
          onChange={handleDeviceSelection}
          renderOption={(device, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {`${device.farm.name}-${device.farm.crop.name}(${device.number})`}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Select Devices"
              placeholder="Leave empty to use all"
            />
          )}
        />
      </div>
      {inProgress && <ProgressBar></ProgressBar>}
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          {/** hourlyN e.g. {"labels":["10am","11am","12pm","01pm","02pm","03pm","04pm","05pm"],"series":[{"name":"Nitrogen","data":["2.00","2.00","2.00","2.13","3.00","3.00","3.00","2.33"]}]} */}
          <ReactRadialGauge
            value={
              hourlyN.series &&
              hourlyN.series[0].data[hourlyN.series[0].data.length - 1]
            }
            title={"Nitrogen"}
            units={"mg/Kg"}
            majorTicks={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <ReactRadialGauge
            value={
              hourlyP.series &&
              hourlyP.series[0].data[hourlyP.series[0].data.length - 1]
            }
            title={"Phosphorus"}
            units={"mg/Kg"}
            majorTicks={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <ReactRadialGauge
            value={
              hourlyK.series &&
              hourlyK.series[0].data[hourlyK.series[0].data.length - 1]
            }
            title={"Potassium"}
            units={"mg/Kg"}
            majorTicks={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <ReactRadialGauge
            value={
              hourlyPh.series &&
              hourlyPh.series[0].data[hourlyPh.series[0].data.length - 1]
            }
            title={"Ph"}
            units={"Ph"}
            majorTicks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <ReactRadialGauge
            value={
              hourlyTemp.series &&
              hourlyTemp.series[0].data[hourlyTemp.series[0].data.length - 1]
            }
            title={"Temperature"}
            units={"°C"}
            majorTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <ReactRadialGauge
            value={
              hourlyMoisture.series &&
              hourlyMoisture.series[0].data[
                hourlyMoisture.series[0].data.length - 1
              ]
            }
            title={"Relative Humidity"}
            units={"%RH"}
            majorTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <ReactRadialGauge
            value={
              hourlySignal.series &&
              hourlySignal.series[0].data[
                hourlySignal.series[0].data.length - 1
              ]
            }
            title={"Signal Level"}
            units={"dbm"}
            majorTicks={[
              -500,
              -400,
              -300,
              -200,
              -100,
              0,
              100,
              200,
              300,
              400,
              500,
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <ReactRadialGauge
            value={
              hourlyEc.series &&
              hourlyEc.series[0].data[hourlyEc.series[0].data.length - 1]
            }
            title={"Conductivity/EC"}
            units={"us/cm"}
            majorTicks={[
              0,
              300,
              600,
              900,
              1200,
              1500,
              1800,
              2100,
              2400,
              2700,
              3000,
              3300,
            ]} //{[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4} />
      </GridContainer>
    </div>
  );
}
