import React, { useState, useEffect } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import ListIcon from "@material-ui/icons/List";
import OutdoorGrillIcon from "@material-ui/icons/OutdoorGrill";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DeficitReport from "glite-components/nutrient-recommender/DeficitReport";
import NutrientRecommendationPane from "glite-components/nutrient-recommender/NutrientRecommendationPane";
import { makeStyles } from "@material-ui/core/styles";
import { baseUrl } from "helpers/Constants";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import { borders } from "@material-ui/system";
import moment from "moment";
import { useAuth } from "helpers/use-auth";

import axios from "axios";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import DeficitComputePane from "glite-components/nutrient-recommender/DeficitComputePane";
import CropsTableWrapper from "glite-components/nutrient-recommender/CropsTableWrapper";
import SoilTestingResultTableWrapper from "glite-components/nutrient-recommender/SoilTestingResultTableWrapper";
import UptakeManager from "glite-components/nutrient-recommender/UptakeManager";
import Spinner from "components/Spinner";
import AlertDialog from "../views/Dialogs/AlertDialog";
import ConfirmDialog from "../views/Dialogs/ConfirmDialog";
const useStyles = makeStyles(styles);

export default function Crops() {
  const auth = useAuth();
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedCrop, setSelectedCrop] = useState(0);
  const [selectedSoilType, setSelectedSoilType] = useState("Loam Soil");
  const [crops, setCrops] = useState([]);
  const [devices, setDevices] = useState([]);
  const [farms, setFarms] = useState([]);
  //const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedFarms, setSelectedFarms] = useState("");
  const [landSize, setLandSize] = useState(0);
  const [deficiencyReport, setDeficiencyReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDepth, setSelectedDepth] = useState(20);
  const [soilTestingResult, setSoilTestingResult] = useState({farmId:0, results: ""});
  //const [status, setStatus] = useState("");
  const [dialogContent, setDialogContent] = React.useState({
    state: false,
    title: "Alert",
    message: ""
  });
  const [confirmDialogContent, setConfirmDialogContent] = React.useState({
    state: false,
    title: "Alert",
    message: ""
  });
  const supportedSoilDepths = [
    { key: "10cm", value: 10 },
    { key: "15cm", value: 15 },
    { key: "20cm", value: 20 },
    { key: "40cm", value: 40 },
    { key: "60cm", value: 60 },
    { key: "80cm", value: 80 },
    { key: "100cm", value: 100 },
  ];

  useEffect(() => {
    if (crops.length === 0) {
      axios.get(`${baseUrl}crops`).then((response) => {
        setCrops(response.data);
      });
    }
    
    if (devices.length === 0) {
      axios.get(`${baseUrl}user-devices/${auth.user.id}`).then((response) => {
        setDevices(response.data.map((e) => e.device));
      });
    }
    if (farms.length === 0) {
      axios.get(`${baseUrl}farms`).then((response) => {
        setFarms(response.data);
      });
    }
  });

  const formatDate = (date) => {
    return moment(Date.parse(date)).format("YYYY-MM-DD");
  };
  const handleStartDateChange = (e, value) => {
    setStartDate(formatDate(value));
  };
  const handleEndDateChange = (e, value) => {
    setEndDate(formatDate(value));
  };
  const handleCropSelection = (e) => {
    e.preventDefault();
    setSelectedCrop(e.target.value);
  };
  const handleSoilTypeSelection = (e) => {
      e.preventDefault();
      setSelectedSoilType(e.target.value);
    };
  const handleDepthSelection = (e) => {
    e.preventDefault();
    setSelectedDepth(e.target.value);
  };
  /*
  const handleDeviceSelection = (e, values) => {
    e.preventDefault();
    setSelectedDevices(values.map((d) => d.id));
  };
  */
  const handleFarmSelection = (e, value) => {
    if (value == null){
      return;
    }
    
    e.preventDefault();
    setSelectedFarms(value.id);
  };

  const handleLandSizeChange = (e) => {
    e.preventDefault();
    setLandSize(e.target.value);
  };
  
  const computeDeficits = () => {
    // e.preventDefault();
    setDeficiencyReport({});
    setLoading(true);
    console.log(selectedFarms);
    if (selectedFarms.length < 0) {
      console.log(selectedFarms);
      return;
    }
    let farmIdToUse = selectedFarms;
      /*
    axios
      .get(
        `${baseUrl}crop-needs/calc?start=${formatDate(
          startDate
        )}&end=${formatDate(
          endDate
        )}&cropId=${selectedCrop}&devices=${devicesToUse.join()}&landArea=${landSize}&soilDepthCm=${selectedDepth}`
      )
      .then((response) => {
        setLoading(false);
        setDeficiencyReport(response.data);
      });*/
      axios
      .get(
        `${baseUrl}crop-needs/calc-with-farm-id?start=${formatDate(
          startDate
        )}&end=${formatDate(
          endDate
        )}&cropId=${selectedCrop}&farmId=${farmIdToUse}&landArea=${landSize}&soilDepthCm=${selectedDepth}&soilType=${selectedSoilType}`
      )
      .then((response) => {
        setLoading(false);
        response.data.soilDepth = selectedDepth;
        response.data.landSize = landSize;
        setSoilTestingResult({
          "farmId":farmIdToUse, 
          "results":JSON.stringify(response.data)
        });
        setDeficiencyReport(response.data);
      });
  };

  const handleConfirmDialogOnCloseYes = ()=> {
    setConfirmDialogContent({
      state: false,
      title: "",
      message: ""
    });
    submitSaveComputedResultsReq(true);
  }

  const handleConfirmDialogOnCloseNo = ()=> {
    setConfirmDialogContent({
      state: false,
      title: "",
      message: "",
    });

    submitSaveComputedResultsReq(false);
  }

  const saveComputedResults = () => {
    // e.preventDefault();
    if (soilTestingResult.farmId == 0) {
      setDialogContent({
        state: true,
        title: "Alert",
        message: "You have to load results first."
      });
      return;
    }

    setConfirmDialogContent({
      state: true,
      title: "Confirm",
      message: "Would you like to schedule an SMS now?"
    });
  };

  const submitSaveComputedResultsReq = (scheduleSms) => {
    setLoading(true);
    let url = scheduleSms ? baseUrl + "soil-testing-results-schedule-sms" : 
      baseUrl + "soil-testing-results";

    axios
      .post(url, soilTestingResult)
      .then(() => {
        setLoading(false);
        setDialogContent({
          state: true,
          title: "Alert",
          message: "Saved successfully."
        });
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        // if (error.response.status === 400) {
        //   setUserError(error.response.data.message);
        // }
        setLoading(false);
        setDialogContent({
          state: true,
          title: "Error",
          message: "Failed to save soil testing results."
        });
    });
  }

  const handleCloseDialog = () => {
    setDialogContent({
      state: false,
      title: "",
      message: ""
    });
  }

  const cropListComponent = {
    tabName: "List",
    tabIcon: FilterVintageIcon,
    tabContent: <CropsTableWrapper />,
  };

  const soilTestingResultComponent = {
    tabName: "Soil Testing Results",
    tabIcon: ListIcon,
    tabContent: <SoilTestingResultTableWrapper />
  };

  const uptakeComponent = {
    tabName: "Uptake",
    tabIcon: OutdoorGrillIcon,
    tabContent: <UptakeManager />,
  };

  const calculatorComponent = {
    tabName: "Deficit Calculator",
    tabIcon: AttachMoneyIcon,
    tabContent: (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <AlertDialog 
            open={dialogContent.state}
            dialogTitle={dialogContent.title}
            dialogBodyMessage={dialogContent.message}
            handleClose={handleCloseDialog}
          />
          <ConfirmDialog 
            open={confirmDialogContent.state}
            dialogTitle={confirmDialogContent.title}
            dialogBodyMessage={confirmDialogContent.message}
            handleCloseOnYes={handleConfirmDialogOnCloseYes}
            handleCloseOnNo={handleConfirmDialogOnCloseNo}
            />
          <DeficitComputePane
            onSaveResults={saveComputedResults}
            onSubmit={computeDeficits}
            onLandSizeChange={handleLandSizeChange}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            onCropSelection={handleCropSelection}
            onSoilTypeSelection={handleSoilTypeSelection}
            onDepthSelection={handleDepthSelection}
            onFarmSelection={handleFarmSelection}
            startDate={startDate}
            endDate={endDate}
            landSize={landSize}
            selectedCrop={selectedCrop}
            selectedSoilType={selectedSoilType}
            selectedDepth={selectedDepth}
            devices={devices}
            crops={crops}
            farms={farms}
            supportedSoilDepths={supportedSoilDepths}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6} borderleft={1}>
          {deficiencyReport.crop ? (
            <div>
              <DeficitReport cropNeeds={deficiencyReport.cropNeeds} />
              {deficiencyReport.supplementalBudget ? (
                <NutrientRecommendationPane
                  recommendation={deficiencyReport.supplementalBudget}
                />
              ) : (
                <h3>No recommendation data available!</h3>
              )}
            </div>
          ) : (
            <Card>
              <CardBody>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className={classes.typo}>
                    <h3>Results will appear here!</h3>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    ),
  };

  const adminTabs = [cropListComponent, uptakeComponent, calculatorComponent, soilTestingResultComponent];
  const clientTabs = [calculatorComponent];
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title="Crop:"
          headerColor="success"
          tabs={auth.user.roles.includes("ADMIN") ? adminTabs : clientTabs}
        />
      </GridItem>
    </GridContainer>
  );
}
