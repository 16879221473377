import React, { useState } from "react";
import { isEmptyObject } from "helpers/utils";
import PropTypes from "prop-types";
import { useAuth } from "helpers/use-auth";
import { baseUrl } from "helpers/Constants";
import Spinner from "components/Spinner";
import MomoWidget from "../payment-manager/MomoWidget";
import { ErrorAlert, SuccessAlert } from "components/Alerts";

import axios from "axios";

export default function Checkout({ cartItems, keyedProducts }) {
  const auth = useAuth();
  const [status, setStatus] = useState("NONE");
  const [order, setOrder] = useState({});
  const items = Object.entries(cartItems)
    .filter((entry) => entry[1] > 0)
    .map(([key, value]) => {
      let prod = keyedProducts[key];
      let productId = prod.id;
      let unitPrice = prod.unitPrice;
      let qty = value;
      return { productId, qty, unitPrice };
    });
  const clientId = auth.user.id;
  const total = items
    .map((item) => item.qty * item.unitPrice)
    .reduce((a, b) => a + b, 0);
  const handlePaymentInit = (providerId, msisdn) => {
    if (items.length === 0) {
      setStatus("EMPTY");
    } else if (isEmptyObject(order)) {
      setStatus("PENDING");
      let data = {
        clientId,
        providerId,
        amount: total,
        status: "PENDING",
        msisdn: msisdn,
        items,
      };
      axios
        .post(`${baseUrl}orders`, data)
        .then((response) => {
          setOrder(response.data);
          setStatus("SUCCESS");
        })
        .catch((error) => {
          setStatus("FAILED");
          setOrder({});
          console.log("xxxx error", error);
        });
    } else {
      setStatus("DUP");
    }
  };
  return (
    <div>
      {status === "PENDING" && <Spinner />}
      {status === "FAILED" && (
        <ErrorAlert msg="We failed to place your order!" />
      )}
      {status === "SUCCESS" && (
        <SuccessAlert msg="Order successfully initiated, please approve payment from your phone to complete!" />
      )}
      {status === "DUP" && <ErrorAlert msg="Your order is already placed!" />}
      {status === "EMPTY" && <ErrorAlert msg="No items in cart!" />}
      <MomoWidget handlePayment={handlePaymentInit} />
    </div>
  );
}
Checkout.propTypes = {
  cartItems: PropTypes.object.isRequired,
  keyedProducts: PropTypes.object.isRequired,
};
