import React, { useState, useEffect } from "react";
// import EnhancedTable from "components/EnhancedTable";
import NewSmsTemplateForm from "./NewSmsTemplateForm";
import EditSmsTemplateForm from "./EditSmsTemplateForm";
import axios from "axios";
import { baseUrl } from "../../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "./CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { smsTemplateTableHeaders, createSmsTemplateRecord, smsTypeList } from "../../../helpers/schemas/SmsTemplate";
import getSmsTypeByValue from "./GetSmsType"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function SmsTemplateData() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [languageKv, setLanguageKv] = useState([]);
  const [cropKv, setCropKv] = useState([]);
  const [smsTypeKv, setSmsTypeKv] = useState([]);

  useEffect(() => {
    setTableLoading(true);
    loadData();
      axios
      .get(`${baseUrl}languages`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setLanguageKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
    });

    axios
      .get(`${baseUrl}crops`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setCropKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
    });
    //Set SMS types
    setSmsTypeKv(smsTypeList);
  }, []);

  function loadData() {
    axios
      .get(`${baseUrl}smstemplate`)
      .then((response) => {
        let result = response.data.map((rec) => createSmsTemplateRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
    });
  }

  function handleBackToCreate() {
    setFormData({});
  }

  function loadEditData(tableData) {
    let smsTemplateSelected = {};
    Object.keys(tableData).forEach(
      (k) => {
        if (k == "smsType") {
          smsTemplateSelected[k] = getSmsTypeByValue(tableData[k].value).key;
        } else {
          smsTemplateSelected[k] = tableData[k].value
        }
      }
    );
    //console.log(smsTemplateSelected);
    return smsTemplateSelected;
  }

  function saveEdit(editedSmsTemplate) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "smstemplate/" + editedSmsTemplate.id, editedSmsTemplate)
      .then((newRecord) => {
        let transformed = createSmsTemplateRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === editedSmsTemplate.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }

  function create(newSmsTemplate) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(baseUrl + "smstemplate", newSmsTemplate)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");

        loadData();
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        // if (error.response.status === 400) {
        //   setUserError(error.response.data.message);
        // }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  const classes = useStyles();
  
  return (
    <div>
      <div className={classes.root}>
        {
          formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <EditSmsTemplateForm
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              languageKv={languageKv}
              cropKv={cropKv}
              smsTypeKv={smsTypeKv}
            />
          </div>
        ) : (
          <NewSmsTemplateForm
            onCreate={create}
            loading={loading}
            status={status}
            userError={userError}
            languageKv={languageKv}
            cropKv={cropKv}
            smsTypeKv={smsTypeKv}
          />
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="SMS Templates"
        headers={smsTemplateTableHeaders}
        data={data}
        setSmsTemplateSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
