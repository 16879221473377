import React from "react";
import { baseUrl } from "../Constants";
import { makeDate } from "../utils";
import Button from "components/CustomButtons/Button.js";

export const monitorEventTableHeaders = [
  { id: "id", numeric: true, disablePadding: true, label: "ID" },
  { id: "monitor", numeric: false, disablePadding: true, label: "Monitor" },
  { id: "scope", numeric: false, disablePadding: true, label: "Scope" },
  { id: "targetId", numeric: true, disablePadding: true, label: "Target" },
  { id: "nutrient", numeric: false, disablePadding: true, label: "Nutrient" },
  {
    id: "value",
    numeric: true,
    disablePadding: true,
    label: "Value",
  },
  { id: "comment", numeric: false, disablePadding: true, label: "Comment" },
  {
    id: "triggerTime",
    numeric: false,
    disablePadding: true,
    label: "Trigger Time",
  },
];

export const monitorEventTableSchema = {
  id: { value: "", type: "number", label: "ID" },
  monitor: { value: "", type: "text", label: "Monitor" },
  scope: { value: "", type: "text", label: "Scope" },
  targetId: { value: "", type: "number", label: "Target" },
  nutrient: { value: "", type: "text", label: "Nutrient" },
  value: {
    value: "",
    type: "number",
    label: "Value",
  },
  comment: {
    value: "",
    type: "text",
    label: "Comment",
  },
  triggerTime: {
    value: "",
    type: "text",
    label: "Trigger Time",
  },
};
export const createMonitorEventRecord = (data) => {
  let params = JSON.parse(data.recordedParams);
  let comment = params.bound === "UPPER" ? "TOO HIGH" : "TOO LOW";
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    monitor: {
      value: data.monitorTarget.monitor.name,
      type: "text",
      label: "Monitor",
    },
    scope: { value: data.monitorTarget.scope, type: "text", label: "Scope" },
    targetId: {
      value: data.targetName,
      type: "text",
      label: "Target ID",
    },
    nutrient: { value: params.param, type: "text", label: "Nutrient" },
    value: { value: params.value, type: "number", label: "Value" },
    comment: { value: statusButton(comment), type: "text", label: "Comment" },
    triggerTime: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Trigger Time",
      readOnly: true,
    },
  };
  return record;
};
function statusButton(status) {
  let color;
  if (status === "TOO HIGH") color = "danger";
  else if (status === "TOO LOW") color = "warning";
  else color = "primary";
  return <Button color={color}>{status}</Button>;
}
export const monitorEventIdField = "id";
export const monitorEventTableTitle = "Monitor Events";
export const monitorEventBaseUrl = baseUrl + "monitor-events";
