import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { tableStyles } from "./styles/MainBodyStyle";
import axios from "axios";
import FormDialog from "./FormDialog";
import AppSnackBar from "./ActionStatusSnackBar";
import ConfirmDialog from "./ConfirmDialog";
import { DeleteButton, EditButton } from "./ActionWidgets";
import { stableSort, getSorting } from "./tableUtils";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import Spinner from "./Spinner";

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "name",
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 7,
    editing: false,
    creating: false,
    dataToEdit: {},
    displaySnackBar: false,
    snackBarVariant: "",
    snackBarMessage: "",
    confirmedDelete: false,
    showConfirmDeleteDialog: false,
    deleteId: "",
    loading: false,
  };
  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
  }
  componentDidMount() {
    axios
      .get(this.props.baseUrl)
      .then((response) => {
        if (this.props.shareData) {
          this.props.shareData(response.data);
        }
        this.setState(() => {
          let newData = response.data.map((row) => {
            let rec = this.props.createRecord(row);
            return rec;
          });
          return {
            data: [...newData],
            loading: false,
          };
        });
      })
      .catch((error) => {
        this.setState({
          displaySnackBar: true,
          snackBarMessage: error.message,
          snackBarVariant: "error",
          loading: false,
        });
      });
  }
  doDelete = (id) => {
    if (!this.state.confirmedDelete) {
      this.setState({
        showConfirmDeleteDialog: true,
        deleteId: id,
        confirmedDelete: true,
      });
    } else {
      axios
        .delete(this.props.baseUrl + "/" + id)
        .then(() => {
          this.setState({
            editing: false,
            displaySnackBar: true,
            snackBarMessage: "Successfully deleted",
            snackBarVariant: "success",
            confirmedDelete: false,
            showConfirmDeleteDialog: false,
          });
        })
        .catch((error) => {
          this.setState({
            editing: false,
            displaySnackBar: true,
            snackBarMessage: error.message,
            snackBarVariant: "error",
            confirmedDelete: false,
            showConfirmDeleteDialog: false,
          });
        });
    }
  };
  saveNew = (data) => {
    axios
      .post(this.props.baseUrl, data)
      .then((response) => {
        this.setState({
          creating: false,
          displaySnackBar: true,
          snackBarMessage: "Successfully created new record",
          snackBarVariant: "success",
          data: [...this.state.data, this.props.createRecord(response.data)],
        });
      })
      .catch((error) => {
        this.setState({
          creating: false,
          displaySnackBar: true,
          snackBarMessage: error.message,
          snackBarVariant: "error",
        });
      });
  };
  saveEdits = (data) => {
    axios
      .put(this.props.baseUrl + "/" + data[this.props.idField], data)
      .then((newRecord) => {
        this.setState({
          editing: false,
          displaySnackBar: true,
          snackBarMessage: "Success",
          snackBarVariant: "success",
          data: this.state.data.map((record) => {
            let m =
              record[this.props.idField].value === data[this.props.idField]
                ? this.props.createRecord(newRecord.data)
                : record;
            return m;
          }),
        });
      })
      .catch((error) => {
        this.setState({
          editing: false,
          displaySnackBar: true,
          snackBarMessage: error.message,
          snackBarVariant: "error",
        });
      });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };
  onCancelDelete = () => {
    this.setState({
      showConfirmDeleteDialog: false,
      confirmedDelete: false,
    });
  };
  onConfirmDelete = () => {
    this.setState({
      showConfirmDeleteDialog: false,
    });
    this.doDelete(this.state.deleteId);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  closeFormDialog = () => {
    this.setState({ editing: false, creating: false });
  };
  launchEdit = (data) => {
    this.setState({
      dataToEdit: data,
      editing: true,
    });
  };
  launchCreate = (data) => {
    this.setState({
      dataToEdit: data,
      creating: true,
    });
  };

  closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ displaySnackBar: false });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      idField,
      tableTitle,
      tableHeaders,
      tableSchema,
    } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          tableSchema={tableSchema}
          tableTitle={tableTitle}
          numSelected={selected.length}
          launchCreate={this.launchCreate}
        />
        <div className={classes.tableWrapper}>
          <ConfirmDialog
            open={this.state.showConfirmDeleteDialog}
            message="Are you sure you want to delete this record"
            onConfirmDelete={this.onConfirmDelete}
            onCancelDelete={this.onCancelDelete}
          />
          <AppSnackBar
            closeSnackBar={this.closeSnackBar}
            open={this.state.displaySnackBar}
            messageVariant={this.state.snackBarVariant}
            displayMessage={this.state.snackBarMessage}
          />
          <FormDialog
            save={this.saveEdits}
            open={this.state.editing}
            handleClose={this.closeFormDialog}
            data={this.state.dataToEdit}
            title={"Edit " + tableTitle}
          />
          <FormDialog
            save={this.saveNew}
            open={this.state.creating}
            handleClose={this.closeFormDialog}
            data={this.state.dataToEdit}
            title={"Create new " + tableTitle}
          />
          {this.state.loading && <Spinner />}
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              idField={idField}
              tableHeaders={tableHeaders}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, rowIndex) => {
                  return (
                    <TableRow hover key={rowIndex}>
                      {Object.keys(n).map((key, index) => {
                        let valueObj = n[key];
                        switch (valueObj.type) {
                          case "number":
                            return (
                              <TableCell key={index} numeric>
                                {valueObj.value}
                              </TableCell>
                            );
                          case "boolean":
                            return (
                              <TableCell
                                key={index}
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                {valueObj.value ? "YES" : "NO"}
                              </TableCell>
                            );
                          case "text":
                          default:
                            return (
                              <TableCell
                                key={index}
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                {valueObj.value}
                              </TableCell>
                            );
                        }
                      })}
                      <TableCell component="th" scope="row" padding="none">
                        <DeleteButton
                          idField={idField}
                          retrieveData={() => n}
                          doDelete={this.doDelete}
                        />
                        <EditButton
                          retrieveData={() => n}
                          launchEdit={this.launchEdit}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  createRecord: PropTypes.func.isRequired,
  shareData: PropTypes.func,
  idField: PropTypes.string.isRequired,
  tableTitle: PropTypes.string.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  tableSchema: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default withStyles(tableStyles)(EnhancedTable);
