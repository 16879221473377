import React from "react";
import PropTypes from "prop-types";
import Table from "components/Table/Table.js";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

export default function DeficitReport(props) {
  const classes = useStyles();
  const { cropNeeds } = props;
  return (
    <Card>
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Nutrient Deficiency Report</h4>
        {/* <p className={classes.cardCategoryWhite}>
            New employees on 15th September, 2016
          </p> */}
      </CardHeader>
      <CardBody>
        <Table
          tableHeaderColor="warning"
          tableHead={["", "Nitrogen(Kg)", "Phosphorus(Kg)", "Potassium(Kg)", "Moisture", "Conductivity", "Temperature", "PH"]}
          tableData={[
            [
              <p key={1}>
                <b>{"Soil Available"}</b>
              </p>,
              cropNeeds.nitrogen.soilAvailableAmount.toFixed(2),
              cropNeeds.phosphorus.soilAvailableAmount.toFixed(2),
              cropNeeds.potassium.soilAvailableAmount.toFixed(2),
              cropNeeds.moisture.soilAvailableAmount.toFixed(2),
              cropNeeds.conductivity.soilAvailableAmount.toFixed(2),
              cropNeeds.temperature.soilAvailableAmount.toFixed(2),
              cropNeeds.ph.soilAvailableAmount.toFixed(2),
            ],
            [
              <p key={1}>
                <b>{"Crop Requirement"}</b>
              </p>,
              cropNeeds.nitrogen.cropNeed.toFixed(2),
              cropNeeds.phosphorus.cropNeed.toFixed(2),
              cropNeeds.potassium.cropNeed.toFixed(2),
              cropNeeds.moisture.cropNeed.toFixed(2),
              cropNeeds.conductivity.cropNeed.toFixed(2),
              cropNeeds.temperature.cropNeed.toFixed(2),
              cropNeeds.ph.cropNeed.toFixed(2),
            ],
            [
              <h4 key={1}>
                <b>
                  {"Deficit "}
                  <span className={classes.dangerText}>
                    <ArrowDownward className={classes.upArrowCardCategory} />
                  </span>
                  {"| surplus"}
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} />
                  </span>
                </b>
              </h4>,
              <h4 key={2}>
                <b>
                  {cropNeeds.nitrogen.deficit > 0 ? (
                    <span className={classes.dangerText}>
                      <ArrowDownward className={classes.upArrowCardCategory} />
                      {cropNeeds.nitrogen.deficit.toFixed(2)}
                    </span>
                  ) : (
                    <span className={classes.successText}>
                      <ArrowUpward className={classes.upArrowCardCategory} />
                      {cropNeeds.nitrogen.deficit.toFixed(2)}
                    </span>
                  )}
                </b>
              </h4>,
              <h4 key={3}>
                <b>
                  {cropNeeds.phosphorus.deficit > 0 ? (
                    <span className={classes.dangerText}>
                      <ArrowDownward className={classes.upArrowCardCategory} />
                      {cropNeeds.phosphorus.deficit.toFixed(2)}
                    </span>
                  ) : (
                    <span className={classes.successText}>
                      <ArrowUpward className={classes.upArrowCardCategory} />
                      {cropNeeds.phosphorus.deficit.toFixed(2)}
                    </span>
                  )}
                </b>
              </h4>,
              <h4 key={4}>
                <b>
                  {cropNeeds.potassium.deficit > 0 ? (
                    <span className={classes.dangerText}>
                      <ArrowDownward className={classes.upArrowCardCategory} />
                      {cropNeeds.potassium.deficit.toFixed(2)}
                    </span>
                  ) : (
                    <span className={classes.successText}>
                      <ArrowUpward className={classes.upArrowCardCategory} />
                      {cropNeeds.potassium.deficit.toFixed(2)}
                    </span>
                  )}
                </b>
              </h4>,
            ],
          ]}
        />
      </CardBody>
    </Card>
  );
}
DeficitReport.propTypes = {
  cropNeeds: PropTypes.object.isRequired,
};
