import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { CardContent } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "helpers/Constants";
import { useAuth } from "helpers/use-auth";
import PasswordChanger from "./PasswordChanger";

export default function UserPasswordChanger({ userId, classes }) {
  const [changingPassword, setChangingPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(undefined);
  const [passwordStatus, setPasswordStatus] = useState("");
  const history = useHistory();
  const auth = useAuth();

  function handlePasswordChange(currPass, newPass) {
    setChangingPassword(true);
    setPasswordError(undefined);
    setPasswordStatus("");
    axios
      .post(`${baseUrl}auth/change-password`, {
        userId,
        currentPassword: currPass,
        newPassword: newPass,
      })
      .then(() => {
        setChangingPassword(false);
        setPasswordStatus("SUCCESS");
        auth.signout();
        history.push("/");
        //redirect to login
      })
      .catch((error) => {
        setChangingPassword(false);
        setPasswordStatus("FAILED");
        //set errors
        console.log("xxx error", JSON.stringify(error.response));
        if (error.response.status >= 400) {
          setPasswordError(error.response.data.message);
        }
      });
  }

  return (
    <Card>
      <CardHeader color="warning">
        <h3 className={classes.cardTitle}>Password Change</h3>
      </CardHeader>
      <CardContent>
        <PasswordChanger
          loading={changingPassword}
          onPasswordChange={handlePasswordChange}
          status={passwordStatus}
          userError={passwordError}
        />
      </CardContent>
    </Card>
  );
}
UserPasswordChanger.propTypes = {
  userId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};
