import React, { useState, useContext, createContext } from "react";
import { login, register } from "./authHelper";
import PropTypes from "prop-types";
import axios from "axios";

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
function toLocalStorage(key, obj) {
  localStorage.setItem(key, JSON.stringify(obj));
}
function fromLocalStorage(key) {
  let v = localStorage.getItem(key);
  if (v) {
    return JSON.parse(v);
  } else return {};
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  if (fromLocalStorage("token")) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + fromLocalStorage("token").token;
  }
  const context = useContext(authContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within ProvideAuth component");
  }
  return context;
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(fromLocalStorage("token"));
  function signin(username, password) {
    return login(username, password)
      .catch((error) => {
        console.log("error", JSON.stringify(error));
        localStorage.removeItem("token");
        return Promise.reject(error);
      })
      .then((response) => {
        toLocalStorage("token", response);
        setUser(response);
        return response;
      });
  }
  const signup = (email, password) => {
    return register(email, password);
  };
  const signout = () => {
    localStorage.removeItem("token");
    setUser(null);
    return user;
  };
  const sendPasswordResetEmail = (email) => {
    console.log(email);
    return null;
  };
  const confirmPasswordReset = (code, password) => {
    console.log(code, password);
    return null;
  };

  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}
ProvideAuth.propTypes = {
  children: PropTypes.object,
};
