import { baseUrl } from "../Constants";
import { makeMoney, makeDate } from "helpers/utils";
export const orderItemTableHeaders = [
  { id: "id", numeric: false, disablePadding: false, label: "ID" },
  {
    id: "productId",
    numeric: false,
    disablePadding: true,
    label: "Product ID",
  },
  { id: "orderId", numeric: false, disablePadding: true, label: "Order ID" },
  {
    id: "productType",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "unitPriceAtSale",
    numeric: false,
    disablePadding: false,
    label: "Unit Px @ Sale",
  },
  {
    id: "currentUnitPrice",
    numeric: false,
    disablePadding: false,
    label: "Curr Unit Px",
  },
  { id: "qty", numeric: false, disablePadding: true, label: "Quantity" },
  { id: "unit", numeric: false, disablePadding: true, label: "Units" },
  { id: "total", numeric: false, disablePadding: true, label: "Total" },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
];

export const createOrderItemRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    productId: {
      value: data.productId,
      type: "number",
      label: "",
    },
    orderId: {
      value: data.orderId,
      type: "number",
      label: "",
    },
    productType: { value: data.product.type.name, type: "text", label: "" },
    description: { value: data.product.name, type: "text", label: "" },
    unitPriceAtSale: {
      value: makeMoney(data.unitPrice),
      type: "number",
      label: "",
    },
    currentUnitPrice: {
      value: makeMoney(data.product.unitPrice),
      type: "number",
      label: "",
    },
    qty: { value: data.qty, type: "number", label: "" },
    unit: {
      value: data.product.unit.label,
      type: "text",
      label: "",
    },
    total: {
      value: makeMoney(data.unitPrice * data.qty),
      type: "text",
      label: "",
    },
    date: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date",
      readOnly: true,
    },
  };
  return record;
};

export const orderItemTableIdField = "id";
export const orderItemTableTitle = "Order Items";
export const orderItemBaseUrl = baseUrl + "order-items";
