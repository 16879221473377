import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AddButton from "@material-ui/icons/AddCircleOutlineRounded";
import ClearButton from "@material-ui/icons/ClearOutlined";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import NutrientPropDialog from "./NutrientPropDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  textArea: {
    margin: theme.spacing(2),
    width: 200,
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function CreateMonitorForm({ loading, status, onSave }) {
  const classes = useStyles();
  const [name, setName] = useState("name");
  const [error, setError] = useState();
  const [observedParams, setObservedParams] = useState([]);
  const [addingNutrientParam, setAddingNutrientParam] = useState(false);

  const handleParamAddition = (param) => {
    if (
      observedParams.findIndex(
        (p) => p.param === param.param && p.bound === param.bound
      ) === -1
    ) {
      let newArr = [...observedParams, param];
      setObservedParams(newArr);
    }
    setAddingNutrientParam(false);
  };
  const handleSave = () => {
    if (name === "name") {
      setError("Invalid name");
      return;
    } else if (name === undefined) {
      setError("Name is required");
      return;
    } else if (observedParams.length === 0) {
      return;
    }
    onSave({ name, observedParams: JSON.stringify(observedParams) });
  };
  const checkError = () => {
    return error !== undefined;
  };
  function handleNameChange(event) {
    setName(event.target.value);
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          error={checkError()}
          label="Monitor Name"
          id="name"
          value={name}
          onChange={handleNameChange}
          variant="outlined"
          helperText={error}
          size="medium"
        />
      </div>
      <div>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder={JSON.stringify(observedParams)}
          style={{ width: 200 }}
          className={classes.textArea}
        />
      </div>
      <div>
        <Tooltip title="Add param">
          <IconButton aria-label="Add" size="large">
            <AddButton
              fontSize="inherit"
              onClick={() => setAddingNutrientParam(true)}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear">
          <IconButton aria-label="Add" size="large">
            <ClearButton
              fontSize="inherit"
              onClick={() => setObservedParams([])}
            />
          </IconButton>
        </Tooltip>
      </div>
      <NutrientPropDialog
        open={addingNutrientParam}
        selectedValue={"benikod"}
        onClose={handleParamAddition}
      />
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Update successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button
          color="success"
          round
          disabled={loading}
          onClick={() => handleSave()}
        >
          Create
        </Button>
      </div>
    </form>
  );
}
CreateMonitorForm.propTypes = {
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  status: PropTypes.string,
};
