import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import CheckedTableHead from "./CheckedTableHead";
import CheckedTableToolbar from "./CheckedTableToolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function CheckedTable({
  title,
  headers,
  data,
  selected,
  setSelected,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (row) => {
    if (selected.id) return selected.id.value === row.id.value;
    else return false;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CheckedTableToolbar title={title} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CheckedTableHead
              title={title}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers}
            />
            <TableBody>
              {data.map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={() => setSelected(row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    {Object.keys(row).map((key, index) => {
                      let valueObj = row[key];
                      switch (valueObj.type) {
                        case "number":
                          return (
                            <TableCell key={index} numeric>
                              {valueObj.value}
                            </TableCell>
                          );
                        case "boolean":
                          return (
                            <TableCell
                              key={index}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {valueObj.value ? "YES" : "NO"}
                            </TableCell>
                          );
                        case "text":
                        default:
                          return (
                            <TableCell
                              key={index}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {valueObj.value}
                            </TableCell>
                          );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
CheckedTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};
