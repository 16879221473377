import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { CreateButton } from "./ActionWidgets";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { toolbarStyles } from "./styles/MainBodyStyle";

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    launchCreate,
    tableTitle,
    tableSchema,
    allowCreate,
  } = props;
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="title" id="tableTitle">
            {tableTitle + " data"}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {allowCreate && (
          <CreateButton data={tableSchema} launchCreate={launchCreate} />
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  launchCreate: PropTypes.func.isRequired,
  tableTitle: PropTypes.string.isRequired,
  tableSchema: PropTypes.object.isRequired,
  allowCreate: PropTypes.bool,
};

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
