import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

export default class FormDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      data: {},
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let keys = Object.keys(nextProps.data);
    let initialData = {};
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      initialData[key] = nextProps.data[key].value;
    }
    this.setState({
      data: initialData,
    });
  }

  handleChange = (name) => (event) => {
    let newValue = event.target.value;
    this.setState((prevState) => {
      let prevData = prevState.data;
      prevData[name] = newValue;
      return {
        data: prevData,
      };
    });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>Edit the fields below</DialogContentText>
            {Object.keys(this.props.data).map((key, index) => {
              let valueObject = this.props.data[key];
              switch (valueObject.type) {
                case "boolean":
                  return (
                    <TextField
                      key={index}
                      autoFocus
                      margin="dense"
                      id={key}
                      label={valueObject.label}
                      onChange={this.handleChange(key)}
                      type={valueObject.type}
                      defaultValue={valueObject.value === true ? "YES" : "NO"}
                      fullWidth
                      InputProps={{
                        readOnly: valueObject.readOnly ? true : false,
                      }}
                    />
                  );
                case "text":
                default:
                  return (
                    <TextField
                      key={index}
                      autoFocus
                      margin="dense"
                      id={key}
                      label={valueObject.label}
                      onChange={this.handleChange(key)}
                      type={valueObject.type}
                      defaultValue={valueObject.value}
                      fullWidth
                      InputProps={{
                        readOnly: valueObject.readOnly ? true : false,
                      }}
                    />
                  );
              }
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => this.props.save(this.state.data)}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
FormDialog.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
