import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import ComboBox from "components/ComboBox";
import ProductTypeWidget from "./ProductTypeWidget";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function NewProductForm({
  loading,
  onCreate,
  status,
  userError,
  productTypes,
  productUnits,
  fertilizerGrades,
  cropTypes,
}) {
  const fertilizerType = 1;
  const seedType = 2;
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [productType, setProductType] = useState(fertilizerType);
  const handleChange = (event, name) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  console.log("xx product types", productTypes);

  //   const handleProductTypeSelection = (e) => {
  //     e.preventDefault();
  //     if (!emptyObject(errors)) {
  //       setErrors({});
  //     }
  //     let oldData = { ...data };
  //     oldData["productType"] = event.target.value;
  //     setData(oldData);
  //   };
  function getFertilizerName(id) {
    return fertilizerGrades.filter((obj) => obj.key === id)[0].value;
  }
  function getSeedName(id) {
    return cropTypes.filter((obj) => obj.key === id)[0].value;
  }
  const handleFertilizerSelection = (e) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["productTypeDetailsId"] = e.target.value;
    oldData["productTypeId"] = 1;
    oldData["listed"] = true;
    oldData["name"] = getFertilizerName(e.target.value);
    setData(oldData);
  };
  const handleCropSelection = (e) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["productTypeDetailsId"] = e.target.value;
    oldData["productTypeId"] = 2;
    oldData["listed"] = true;
    oldData['isUssdProduct'] = false;
    oldData["name"] = getSeedName(e.target.value);
    setData(oldData);
  };
  const handleProductUnitSelection = (e) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["productUnitId"] = e.target.value;
    setData(oldData);
  };
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handleClick = () => {
    let errorList = {};
    if (!data.productTypeId) errorList.productTypeId = "field cannot be empty";
    if (!data.productTypeDetailsId)
      errorList.productTypeDetailsId = "field cannot be empty";
    if (!data.unitPrice) errorList.unitPrice = "field cannot be empty";
    if (!data.productUnitId) errorList.productUnitId = "field cannot be empty";
    if (!data.description) errorList.description = "field cannot be empty";
    // if (!data.name) errorList.name = "field cannot be empty";
    // if (!data.listed) errorList.listed = "field cannot be empty";
    if (data.unitPrice && (isNaN(data.unitPrice) || data.unitPrice < 0.0))
      errorList.unitPrice = "Invalid value for price";
    if (emptyObject(errorList)) {
      onCreate(data);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        {/* <TextField
          error={checkError("name")}
          label="Product Name"
          id="name"
          value={data.firstName}
          onChange={(e) => handleChange(e, "name")}
          variant="outlined"
          helperText={errors.name}
          size="small"
        /> */}
        <ProductTypeWidget onSelect={setProductType} type={productType} />
        {productType === fertilizerType && (
          <ComboBox
            value={data["productTypeDetailsId"]}
            label={"Fertilizer"}
            onSelect={handleFertilizerSelection}
            kvOptions={fertilizerGrades}
          />
        )}
        {productType === seedType && (
          <ComboBox
            value={data["productTypeDetailsId"]}
            label={"Seeds"}
            onSelect={handleCropSelection}
            kvOptions={cropTypes}
          />
        )}
        <ComboBox
          value={data["productUnitId"]}
          label={"Units"}
          onSelect={handleProductUnitSelection}
          kvOptions={productUnits}
        />
      </div>
      <div>
        <TextField
          error={checkError("description")}
          label="Description"
          id="description"
          value={data.description}
          onChange={(e) => handleChange(e, "description")}
          variant="outlined"
          helperText={errors.description}
          size="small"
        />
        <TextField
          error={checkError("unitPrice")}
          label="Unit Price"
          id="unitPrice"
          value={data.phoneNo}
          onChange={(e) => handleChange(e, "unitPrice")}
          variant="outlined"
          helperText={errors.unitPrice}
          size="small"
        />
      </div>
      <div></div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {userError || "Product creation failed!"}
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Product creation successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Create
        </Button>
      </div>
    </form>
  );
}
NewProductForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
  productTypes: PropTypes.array.isRequired,
  productUnits: PropTypes.array.isRequired,
  fertilizerGrades: PropTypes.array.isRequired,
  cropTypes: PropTypes.array.isRequired,
};
