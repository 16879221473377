import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { isEmptyObject } from "helpers/utils";
import ComboBox from "components/ComboBox";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newSmsTransactionButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function NewSmsTransactionForm({ loading, onCreate, status, smsTransactionError, userKv }) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  
  const handleChange = (event, name) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };

  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleUserSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["userId"] = e.target.value;
    setData(oldData);
  };

  const handleClick = () => {
    let errorList = {};
    if (!data.userId) errorList.userId = "field cannot be empty";
    if (!data.amount) errorList.amount = "field cannot be empty";
    if (!data.narrative) errorList.narrative = "field cannot be empty";
   
    if (emptyObject(errorList)) {
      onCreate(data);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          error={checkError("Narrative")}
          label="Narrative"
          id="narrative"
          value={data.narrative}
          onChange={(e) => handleChange(e, "narrative")}
          variant="outlined"
          helperText={errors.narrative}
          size="small"
        />
        <TextField
          error={checkError("Amount")}
          label="Amount"
          id="amount"
          value={data.amount}
          onChange={(e) => handleChange(e, "amount")}
          variant="outlined"
          helperText={errors.amount}
          size="small"
        />
        
        <ComboBox
          error={errors["userId"]}
          value={data["userId"] === undefined ? 1 : data["userId"]}
          label={"User"}
          onSelect={handleUserSelection}
          kvOptions={userKv === undefined ? [] : userKv}
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {smsTransactionError || "SMS Transaction creation failed!"}
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          SMS Transaction creation successful!
        </Alert>
      )}
      <div className={classes.newSmsTransactionButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Load SMS Credit
        </Button>
      </div>
    </form>
  );
}
NewSmsTransactionForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  smsTransactionError: PropTypes.string,
  userKv: PropTypes.array,
};
