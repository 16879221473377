import { lighten } from "@material-ui/core/styles/colorManipulator";

export const styles = () => ({
  tableGrid: {
    // backgroundColor:'grey',
    height: "75%",
    margin: "10px",
  },
  analyticsGrid: {
    // backgroundColor:'brown',
    height: "25%",
  },
  analyticsContainer: {
    height: "100%",
  },
  analytics: {
    width: "auto",
    height: "auto",
    padding: "10px",
    // backgroundColor:'red',
  },
  // display:{
  //   height:'inherit',
  //   // backgroundColor:'orange',
  // },
  mainBodyContainer: {
    // backgroundColor:'orange',
    height: "100vh",
    maxWidth: "100vw",
  },
  paper: {
    minHeight: 150,
    maxHeight: 180,
    height: "100%",
  },
});

export const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});

export const tableStyles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    height: "100%",
    // backgroundColor:'red'
  },
  table: {
    minWidth: 1020,
    // backgroundColor:'green'
  },
  tableWrapper: {
    overflowX: "auto",
    overflowY: "scroll",
    // backgroundColor:'yellow'
  },
});
