import React, { useState, useEffect } from "react";
// import EnhancedTable from "components/EnhancedTable";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import DeviceTable from "./DeviceTable";
export default function DeviceTableWrapper() {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}devices/statuses`)
      .then((response) => {
        setDevices(response.data);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
  }, []);

  return <DeviceTable devices={devices} />;
}
