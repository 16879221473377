import { baseUrl } from "../Constants";
import { makeDate } from "../utils";
export const districtTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "regionId", numeric: false, disablePadding: true, label: "Region" },
  { id: "name", numeric: false, disablePadding: true, label: "District" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const districtTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  regionId: { value: "", type: "text", label: "Region" },
  name: { value: "", type: "text", label: "District" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createDistrictRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    regionId: { value: data.region.name, type: "text", label: "Region" },
    name: { value: data.name, type: "text", label: "District" },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const districtTableIdField = "id";
export const districtTableTitle = "Districts";
export const districtBaseUrl = baseUrl + "districts";
