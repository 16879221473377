import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeviceEditor from "./DeviceEditor";
import DeviceCreator from "./DeviceCreator";
import DeviceTable from "./DeviceTable";
import { createDeviceRecord /*createDeviceRecordFromObject*/ } from "../../helpers/schemas/Device";
//import { da } from "date-fns/locale";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function DeviceCrud() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [deviceTypesKv, setDeviceTypesKv] = useState([]);
  const [farmsKv, setFarmsKv] = useState([]);

  function loadServerData() {
    axios
    .get(`${baseUrl}devices/statuses`)
    .then((response) => {
      //console.log(response);
      //let result = response.data.map((rec) => createDeviceRecord(rec));
      
      setData(response.data);
      setTableLoading(false);
    })
    .catch((error) => {
      console.log("xxxx err", JSON.stringify(error.response));
      setTableLoading(false);
    });
  }
  
  useEffect(() => {
    setTableLoading(true);
    
    loadServerData();

    axios
      .get(`${baseUrl}devicetypes`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setDeviceTypesKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
    axios
      .get(`${baseUrl}farms`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setFarmsKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
  }, []);

  function loadEditData(tableData) {
    console.log(tableData);
    let selected = {};
    Object.keys(tableData).forEach((k) => (selected[k] = tableData[k].value));
    return selected;
  }

  function saveEdit(edited) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "devices/" + edited.id, edited)
      .then((newRecord) => {
        console.log(newRecord);
        setTableLoading(true);
        loadServerData();
        handleBackToCreate();
        /*
        let transformed = createDeviceRecordFromObject(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === edited.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        */
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log(error);
        console.log("xxx error", JSON.stringify(error));
        /*
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        */
        setLoading(false);
        setStatus("FAILED");
      });
  }

  function onRowClicked(data) {
    //console.log(typeof data);
    //console.log(data[0]);
    //console.log(data);
    let transformed = createDeviceRecord(data);
    setFormData(transformed);
  }

  function create(record) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    console.log(record);
    axios
      .post(baseUrl + "devices", record)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function handleBackToCreate() {
    setFormData({});
  }
  
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <DeviceEditor
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              deviceTypesKv={deviceTypesKv}
              farmsKv={farmsKv}
            />
          </div>
        ) : (
          <div>
            <DeviceCreator
              onCreate={create}
              loading={loading}
              status={status}
              userError={userError}
              deviceTypesKv={deviceTypesKv}
              farmsKv={farmsKv}
            />
          </div>
        )}
      </div>
      {tableLoading && <Spinner />}
      <DeviceTable 
        devices={data}
        setSelected={onRowClicked}
        />
    </div>
  );
}
