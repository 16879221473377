import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const productTableHeaders = [
  { id: "id", numeric: false, disablePadding: false, label: "ID" },
  { id: "supplierId", numeric: false, disablePadding: true, label: "Supplier" },
  {
    id: "productTypeId",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "unitPrice",
    numeric: false,
    disablePadding: false,
    label: "Unit Px",
  },
  { id: "productUnitId", numeric: false, disablePadding: true, label: "Units" },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "listed", numeric: false, disablePadding: true, label: "Listed" },
  { id: "isUssdProduct", numeric: false, disablePadding: true, label: "USSD Product" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const productTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  supplierId: { value: "", type: "text", label: "Supplier" },
  productTypeId: { value: "", type: "text", label: "Type" },
  unitPrice: { value: "", type: "number", label: "Unit Px" },
  productUnitId: { value: "", type: "text", label: "Units" },
  description: { value: "", type: "text", label: "Description" },
  name: { value: "", type: "text", label: "Name" },
  listed: { value: "", type: "number", label: "Listed" },
  isUssdProduct: { value: "", type: "number", label: "Is USSD" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createProductRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    supplierId: {
      value: data.supplier.firstName + data.supplier.lastName,
      type: "text",
      label: "",
    },
    productTypeId: { value: data.type.name, type: "text", label: "" },
    unitPrice: {
      value: data.unitPrice,
      type: "number",
      label: "",
    },
    productUnitId: {
      value: data.unit.label,
      type: "text",
      label: "",
    },
    description: { value: data.description, type: "text", label: "" },
    name: {
      value: data.name,
      type: "text",
      label: "",
    },
    listed: { value: data.listed, type: "boolean", label: "" },
    isUssdProduct: { value: data.isUssdProduct, type: "boolean", label: "Is USSD Product" },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const productTableIdField = "id";
export const productTableTitle = "Products";
export const productBaseUrl = baseUrl + "products";
