import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "helpers/Constants";
import ShoppingProducts from "./ShoppingProducts";

export default function FertilizerProducts() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}products/type/1`)
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return <ShoppingProducts loading={loading} products={products} />;
}
