import React from "react";
// import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import SeedProducts from "glite-components/go-shopping/SeedProducts";
import FertilizerProducts from "glite-components/go-shopping/FertilizerProducts";
import UssdProducts from "glite-components/go-shopping/UssdProducts";
import ShoppingOrders from "glite-components/go-shopping/ShoppingOrders";
export default function GoShopping() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Go to:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Seeds",
                tabIcon: LockOpenIcon,
                tabContent: <SeedProducts />,
              },
              {
                tabName: "Fertilizers",
                tabIcon: LockOpenIcon,
                tabContent: <FertilizerProducts />,
              },
              {
                tabName: "USSD Products",
                tabIcon: LockOpenIcon,
                tabContent: <UssdProducts />,
              },
              {
                tabName: "Past Orders",
                tabIcon: LockOpenIcon,
                tabContent: <ShoppingOrders />,
              },
              
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
