import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GroupIcon from "@material-ui/icons/ListSharp";
import SmsTemplateIcon from "@material-ui/icons/MessageSharp";
import SmsTransactionIcon from "@material-ui/icons/MessageSharp";
import SmsLogData from "../glite-components/sms-manager/SmsLogData";
import SmsTemplateData from "../glite-components/sms-manager/sms-template/SmsTemplateData";
import SmsTransactionData from "../glite-components/sms-manager/sms-transaction/SmsTransactionData";
export default function SmsLog() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Sms Log:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Data",
                tabIcon: GroupIcon,
                tabContent: <SmsLogData />,
              },
              {
                tabName: "Sms Templates",
                tabIcon: SmsTemplateIcon,
                tabContent: <SmsTemplateData />,
              },
              {
                tabName: "Sms Transaction",
                tabIcon: SmsTransactionIcon,
                tabContent: <SmsTransactionData />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
