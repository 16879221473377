import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppSnackBar from "../../components/ActionStatusSnackBar";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import RealSoilDataTable from "./RealSoilDataTable";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { useAuth } from "../../helpers/use-auth";
import { makeStyles } from "@material-ui/core/styles";
import { tableStyles } from "../../components/styles/MainBodyStyle";
const useStyles = makeStyles((theme) => ({
  ...tableStyles,
  selectorRoot: {
    width: "100%",
    marginBottom: "10px",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function SoilDataTable(props) {
  const auth = useAuth();
  const classes = useStyles();
  const pageSize = 15;
  const { schema, headers, soilDataUrl, title, idField, creator } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Something went wrong!");
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(pageSize);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const handleDeviceSelection = (e, values) => {
    e.preventDefault();
    setSelectedDevices(values.map((d) => d.id));
  };
  useEffect(() => {
    setLoading(true);

    if (devices.length === 0) {
      axios.get(`${baseUrl}user-devices/${auth.user.id}`).then((response) => {
        setDevices(response.data.map((e) => e.device));
      });
    }
    let devicesToUse =
      selectedDevices.length === 0 ? devices.map((d) => d.id) : selectedDevices;

    axios
      .get(
        `${soilDataUrl}?page=${page}&size=${
          rowsToFetch < 1 ? pageSize : rowsToFetch
        }&devices=${devicesToUse.join()}`
      )
      .then((response) => {
        let result = response.data;
        let newData = result.elements.map((row) => {
          let rec = creator(row);
          return rec;
        });
        setEmptyRows(pageSize - rowsToFetch);
        setData(newData);
        setLoading(false);
        setRowsToFetch(Math.min(pageSize, result.total - page * pageSize));
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error));
        setLoading(false);
        setShowError(true);
        setErrorMessage("Something went wrong!");
      });
  }, [page, selectedDevices]);
  function handleDownload(fromDate, toDate) {
    setDownloading(true);
    axios({
      url: `${soilDataUrl}/download?from=${fromDate}&to=${toDate}&devices=`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "soil_report.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setDownloading(false);
      });
    console.log("xxxx download date range ", fromDate, toDate);
  }
  function paginate(pageNum) {
    setPage(pageNum);
  }
  function closeSnackBar(reason) {
    if (reason === "clickaway") {
      return;
    }
    setShowError(false);
  }
  return (
    <div>
      <div className={classes.selectorRoot}>
        <Autocomplete
          multiple
          // id="checkboxes-tags-demo"
          id="tags-standard"
          options={devices}
          disableCloseOnSelect
          getOptionLabel={(device) => device.number}
          onChange={handleDeviceSelection}
          renderOption={(device, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {`${device.farm.name}-${device.farm.crop.name}(${device.number})`}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Select Devices"
              placeholder="Leave empty to use all"
            />
          )}
        />
      </div>
      <AppSnackBar
        closeSnackBar={closeSnackBar}
        open={showError}
        messageVariant={"error"}
        displayMessage={errorMessage}
      />
      <Paper className={classes.root}>
        <RealSoilDataTable
          tableSchema={schema}
          tableHeaders={headers}
          baseUrl={baseUrl}
          tableTitle={title}
          idField={idField}
          createRecord={creator}
          allowEdit={false}
          allowDelete={false}
          allowCreate={false}
          loading={loading}
          data={data}
          classes={classes}
          emptyRows={emptyRows}
          onDownload={handleDownload}
          downloading={downloading}
        />
        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={pageSize}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          rowsPerPageOptions={[pageSize]}
          onPageChange={(event, newPage) => paginate(newPage)}
        />
      </Paper>
    </div>
  );
}
SoilDataTable.propTypes = {
  schema: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  creator: PropTypes.func.isRequired,
  idField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  soilDataUrl: PropTypes.string.isRequired,
};
