import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { isEmptyObject } from "helpers/utils";
import ComboBox from "components/ComboBox";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function EditUserForm({
  loading,
  onEdit,
  formData,
  status,
  onViewProfile,
  onViewSupplier,
  languageKv
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setData(formData);
    setErrors({});
    return () => setData({});
  }, [formData]);
  
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const phoneNoValidates = (phone) => {
    const re = /^([2567]{4})([\d]{8})$/g;
    return re.test(phone);
  };
  const emailValidates = (email) => {
    const re = /^\S+@\S+\.\S+$/;
    return re.test(email);
  };
  const handleClick = () => {
    let errorList = {};
    if (!data.firstName) errorList.firstName = "field cannot be empty";
    if (!data.lastName) errorList.lastName = "field cannot be empty";
    if (!data.nin) errorList.nin = "field cannot be empty";
    if (!data.email) errorList.email = "field cannot be empty";
    if (!data.phoneNo) errorList.phoneNo = "field cannot be empty";
    if (data.phoneNo && !phoneNoValidates(data.phoneNo))
      errorList.phoneNo = "Invalid phone number format";
    if (data.email && !emailValidates(data.email))
      errorList.email = "Invalid email format";
    if (emptyObject(errorList)) {
      let changes = Object.fromEntries(
        Object.entries(data).filter(([k, v]) => k === "id" || v !== formData[k])
      );
      if (changes.email) {
        changes.userAuth = { email: changes.email };
      }
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  function handleChange(event, name) {
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  }

  const handleLanguageSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["languageId"] = e.target.value;
    setData(oldData);
  };

  const handleGenderSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["gender"] = e.target.value;
    setData(oldData);
  };

  const genderOptions = [
    {label:'Male', value:'male', key:'male'}, {
    label:'Female', value:'female', key:'female'},
    {label:'Undefined', value:'undefined', key:'undefined'}
  ];

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          label="First Name"
          id="firstName"
          defaultValue={data.firstName}
          value={data.firstName}
          onChange={(e) => handleChange(e, "firstName")}
          variant="outlined"
          size="small"
        />
        <TextField
          label="Last Name"
          id="lastName"
          defaultValue={data.lastName}
          value={data.lastName}
          onChange={(e) => handleChange(e, "lastName")}
          variant="outlined"
          size="small"
        />
        <TextField
          label="Middle Name"
          id="middleName"
          defaultValue={data.middleName}
          value={data.middleName}
          onChange={(e) => handleChange(e, "middleName")}
          variant="outlined"
          size="small"
        />
        <ComboBox
          defaultValue={"undefined"}
          error={errors["gender"]}
          value={data.gender === undefined ? "undefined" : data.gender}
          label={"Gender"}
          onSelect={handleGenderSelection}
          kvOptions={genderOptions}
        />
      </div>
      <div>
        <TextField
          label="NIN"
          id="nin"
          defaultValue={data.nin}
          value={data.nin}
          onChange={(e) => handleChange(e, "nin")}
          variant="outlined"
          size="small"
        />
        <TextField
          error={checkError("email")}
          helperText={errors.email}
          label="Email"
          id="email"
          defaultValue={data.email}
          value={data.email}
          onChange={(e) => handleChange(e, "email")}
          variant="outlined"
          size="small"
        />
        <TextField
          error={checkError("phoneNo")}
          helperText={errors.phoneNo}
          label="Phone No"
          id="phoneNo"
          defaultValue={data.phoneNo}
          value={data.phoneNo}
          onChange={(e) => handleChange(e, "phoneNo")}
          variant="outlined"
          size="small"
        />

        <ComboBox
          error={errors["languageId"]}
          value={data.languageId == undefined ? 1 : data.languageId}
          label={"Language Name"}
          onSelect={handleLanguageSelection}
          kvOptions={languageKv}
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          User update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          User update successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => handleClick(data)}
        >
          Update User
        </Button>
        <Button
          color="success"
          round
          disabled={loading}
          onClick={() => onViewProfile()}
        >
          User Profile
        </Button>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => onViewSupplier()}
        >
          Supplier Profile
        </Button>
      </div>
    </form>
  );
}
EditUserForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onViewProfile: PropTypes.func.isRequired,
  onViewSupplier: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  languageKv: PropTypes.array.isRequired
};
