import React from "react";
import PropTypes from "prop-types";
import CartItems from "../shop/CartItems";

export default function ShoppingCart({ cartItems, keyedProducts }) {
  const items = Object.entries(cartItems)
  
    .filter((entry) => entry[1] > 0)
    .map(([key, value]) => {
      let prod = keyedProducts[key];
      let desc = 'insurance';
      let supplier = prod.farm.owner.firstName + " " + prod.farm.owner.lastName;
      let unitPrice = prod.netpremium;
      let qty = value;
      let unit = 'InsurancePackage';
      return { desc, supplier, qty, unitPrice, unit };
    });
  return (
    <div>
      <CartItems items={items} />
    </div>
  );
}
ShoppingCart.propTypes = {
  cartItems: PropTypes.object.isRequired,
  keyedProducts: PropTypes.object.isRequired,
};
