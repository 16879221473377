import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "100%",
    margin: "10px",
  },
}));

export default function MultipleSelector({
  options,
  selectedOptions,
  onSelect,
  onUnselect,
  busy,
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl>
        <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedOptions}
          onChange={(e) => {
            let selection = e.target.value;
            //check if addition
            if (!busy && selection.length > selectedOptions.length) {
              onSelect(e.target.value[e.target.value.length - 1]);
            }
            //or removal
            else if (!busy && selection.length < selectedOptions.length) {
              let removedItem = selectedOptions.find(
                (el) => selection.indexOf(el) === -1
              );
              onUnselect(removedItem);
            }
          }}
          input={<OutlinedInput label="Roles" />}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {options.map((k) => (
            <MenuItem key={k} value={k}>
              <Checkbox checked={selectedOptions.indexOf(k) > -1} />
              <ListItemText primary={k} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
MultipleSelector.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
};
