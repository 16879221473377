import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from "@material-ui/pickers";
 import "date-fns";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import DateFnsUtils from "@date-io/date-fns"; 
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmptyObject } from "helpers/utils";
import { addDays } from "helpers/utils";
import ComboBox from "components/ComboBox";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));
8910300093063

export default function YieldCreator({
  loading,
  onCreate,
  status,
  userError,
  farmsKv,
  usersKv,
  cropsKv,
  seasonsKv,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (event, name) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const handlePlantingDateChange = (value) => {
    if (data.cropId == undefined) {
      console.log("data.cropId is undefined");
      return;
    }

    if (!isEmptyObject(errors)) {
      setErrors({});
    }

    let name = "plantingDate";
    console.log(value);
    axios
      .get(`${baseUrl}crop-needs-by-crop-id/${data.cropId}`)
      .then((response) => {
        //console.log(response);
        let oldData = { ...data };
        oldData[name] = value;
        oldData['harvestDate']=addDays(value, response.data.growPeriodMax);
        setData(oldData);
        console.log(oldData.harvestDate);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error));
      });
    
  };
  
  const handleFarmSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["farmId"] = e.target.value;
    setData(oldData);
  };
  const handleOwnerSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["ownerId"] = e.target.value;
    setData(oldData);
  };
  const handleCropSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["cropId"] = e.target.value;
    setData(oldData);
  };
  const handleSeasonSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["seasonId"] = e.target.value;
    setData(oldData);
  };

  const handleClick = () => {
    
    let errorList = {};
    if (!data.farmId) errorList.farmId = "Field cannot be empty";
    if (!data.ownerId) errorList.ownerId = "Field cannot be empty";
    if (!data.cropId) errorList.cropId = "Field cannot be empty";
    if (!data.tilledSize) errorList.tilledSize = "Field cannot be empty";
    if (!data.plantingDate) errorList.plantingDate = "Field cannot be empty";
    if (!data.expectedYield) errorList.expectedYield = "Field cannot be empty";
    //if (!data.actualYield) errorList.actualYield = "field cannot be empty";
    //if (!data.harvestDate) errorList.harvestDate = "field cannot be empty";
    if (isEmptyObject(errorList)) {
      onCreate(data);
    } else {
      console.log("Errors");
      console.log(errorList);
      setErrors(errorList);
    }
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };

  return (
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <ComboBox
          error={errors["farmId"]}
          value={data["farmId"]}
          label={"Farm Name"}
          onSelect={handleFarmSelection}
          kvOptions={farmsKv}
        />
        <ComboBox
          error={errors["ownerId"]}
          value={data["ownerId"]}
          label={"Owner"}
          onSelect={handleOwnerSelection}
          kvOptions={usersKv}
        />
        <ComboBox
          error={errors["cropId"]}
          value={data["cropId"]}
          label={"Crop"}
          onSelect={handleCropSelection}
          kvOptions={cropsKv}
        />
        <ComboBox
          error={errors["seasonId"]}
          value={data["seasonId"]}
          label={"Season"}
          onSelect={handleSeasonSelection}
          kvOptions={seasonsKv}
        />
      </div>
      <div>
        <TextField
          error={checkError("tilledSize")}
          label="Tilled Land Size(acres)"
          id="tilledSize"
          value={data.tilledSize}
          onChange={(e) => handleChange(e, "tilledSize")}
          variant="outlined"
          helperText={errors.tilledSize}
          size="small"
        />
       <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Pick Planting date"
            value={data.plantingDate}
            defaultValue={null}
            onChange={ (e) => handlePlantingDateChange(e)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            helperText={errors.plantingDate}
            error={errors["plantingDate"]}
          /> 
        <TextField
          error={checkError("expectedYield")}
          label="Expected Yield"
          id="expectedYield"
          value={data.expectedYield}
          onChange={(e) => handleChange(e, "expectedYield")}
          variant="outlined"
          helperText={errors.expectedYield}
          size="small"
        />
        <TextField
          error={checkError("actualYield")}
          label="Actual Yield"
          id="actualYield"
          value={data.actualYield}
          onChange={(e) => handleChange(e, "actualYield")}
          variant="outlined"
          helperText={errors.actualYield}
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {userError || "Yield creation failed!"}
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Yield creation successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Generate Yield Forecast
        </Button>
      </div>
    </form>
   </MuiPickersUtilsProvider>
  );
}
YieldCreator.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
  farmsKv: PropTypes.array.isRequired,
  usersKv: PropTypes.array.isRequired,
  cropsKv: PropTypes.array.isRequired,
  seasonsKv: PropTypes.array.isRequired,
  //onplantingDateChange: PropTypes.func.isRequired,
  //plantingDate: PropTypes.instanceOf(Date),
};
