import React from "react";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import Store from "@material-ui/icons/Store";
import SuccessIcon from "@material-ui/icons/DoneAll";
import DateRange from "@material-ui/icons/DateRange";
import People from "@material-ui/icons/People";
// import CardBody from "components/Card/CardBody.js";
// import Table from "components/Table/Table.js";
import GroupIcon from "@material-ui/icons/Group";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import SupplierProducts from "glite-components/supplier-home/SupplierProducts";
import SupplierOrders from "glite-components/supplier-home/SupplierOrders";
import { useAuth } from "helpers/use-auth";
const useStyles = makeStyles(styles);
export default function Supplier({ supplierId }) {
  const auth = useAuth();
  const activeUserId = supplierId ? supplierId : auth.user.id;
  console.log("xxx supplierId", activeUserId);
  const classes = useStyles();
  const numProducts = 30;
  const totalSales = 356000;
  const rankingBySalesVolume = 3;
  function makeMoney(amt) {
    // Create our number formatter.
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "UGX",
    });

    return formatter.format(amt);
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <People />
              </CardIcon>
              <p className={classes.cardCategory}>#Products</p>
              <h3 className={classes.cardTitle}>{numProducts}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {/* Just Updated */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Total Sales</p>
              <h3 className={classes.cardTitle}>{makeMoney(totalSales)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                {/* Last 24 Hours */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <SuccessIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Ranking by volume</p>
              <h3 className={classes.cardTitle}>{rankingBySalesVolume}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                {/* Tracked from Github */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Supplier:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Products",
                tabIcon: GroupIcon,
                tabContent: <SupplierProducts supplierId={activeUserId} />,
              },
              {
                tabName: "Orders",
                tabIcon: LockOpenIcon,
                tabContent: <SupplierOrders />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
Supplier.propTypes = { supplierId: PropTypes.number };
