import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmptyObject } from "helpers/utils";
import ComboBox from "components/ComboBox";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function DistrictCreator({
  loading,
  onCreate,
  status,
  userError,
  regionsKv,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const handleChange = (event, name) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const handleRegionSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["regionId"] = e.target.value;
    setData(oldData);
  };
  const handleClick = () => {
    let errorList = {};
    if (!data.regionId) errorList.regionId = "field cannot be empty";
    if (!data.name) errorList.name = "field cannot be empty";
    if (isEmptyObject(errorList)) {
      onCreate(data);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <ComboBox
          error={errors["regionId"]}
          value={data["regionId"]}
          label={"Region"}
          onSelect={handleRegionSelection}
          kvOptions={regionsKv}
        />
        <TextField
          error={checkError("name")}
          label="Name"
          id="name"
          value={data.name}
          onChange={(e) => handleChange(e, "name")}
          variant="outlined"
          helperText={errors.name}
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {userError || "District creation failed!"}
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          District creation successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Create New District
        </Button>
      </div>
    </form>
  );
}
DistrictCreator.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
  regionsKv: PropTypes.array.isRequired,
};
