import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const yieldTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "OwnerId",
    numeric: false,
    disablePadding: true,
    label: "Owner",
  },
  { id: "farmId", numeric: false, disablePadding: true, label: "Farm" },
  { id: "cropId", numeric: false, disablePadding: true, label: "Crop" },
  { id: "seasonId", numeric: false, disablePadding: true, label: "Season" },
  { id: "tilledSize", numeric: false, disablePadding: true, label: "Tilled Land Size(acres)" },
  { id: "plantingDate", numeric: false, disablePadding: true, label: "Planting Date" },
  { id: "expectedYield", numeric: false, disablePadding: true, label: "Expected Yield" },
  { id: "actualYield", numeric: false, disablePadding: true, label: "Actual Yield" },
  { id: "harvestDate", numeric: false, disablePadding: true, label: "Harvest Date" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const yieldTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  OwnerId: { value: "", type: "number", label: "Owner" },
  farmId: { value: "", type: "number", label: "Farm" },
  cropId: { value: "", type: "number", label: "Crop" },
  seasonId: { value: "", type: "number", label: "Season" },
  tilledSize: { value: "", type: "number", label: "Tilled Land Size(acres)" },
  plantingDate: { value: "", type: "date", label: "Planting Date" },
  expectedYield: { value: "", type: "number", label: "Expected Yield" },
  actualYield: { value: "", type: "number", label: "Actual Yield" },
  harvestDate: { value: "", type: "date", label: "Harvest Date" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createYieldRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    OwnerId: {
      value: data.owner.id,
      type: "number",
      label: "Owner",
    },
    farmId: {
      value: data.farm.name,
      type: "text",
      label: "Farm",
    },
     cropId: {
      value: data.crop.name,
      type: "text",
      label: "Crop",
    },
    seasonId: {
      value: data.season.name,
      type: "text",
      label: "Season",
    },
    tilledSize: {
      value: data.tilledSize,
      type: "double",
      label: "Tilled Land Size(acres)",
    },
    plantingDate: {
      value: makeDate(data.plantingDate),
      type: "date",
      label: "Planting Date",
    },
    expectedYield: {
      value: data.expectedYield,
      type: "number",
      label: "Expected Yield",
    },
    actualYield: {
      value: data.actualYield,
      type: "number",
      label: "Actual Yield",
    },
    harvestingDate: {
      value: makeDate(data.harvestDate),
      type: "date",
      label: "Harvest Date",
    },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const yieldTableIdField = "id";
export const yieldTableTitle = "Yields";
export const yieldBaseUrl = baseUrl + "yields";
