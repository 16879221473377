import { smsTypeList } from "../../../helpers/schemas/SmsTemplate"
export default function getSmsType(key) {
    for (let i=0; smsTypeList.length; i++) {
        if (smsTypeList[i].key == key) {
          return smsTypeList[i];
        }
    }
    return smsTypeList[0];
}

export function getSmsTypeByValue(value) {
    for (let i=0; smsTypeList.length; i++) {
        if (smsTypeList[i].value == value) {
          return smsTypeList[i];
        }
    }
    return smsTypeList[0];
}