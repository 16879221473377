import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import RouterIcon from "@material-ui/icons/Router";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BioDataCard from "glite-components/user-profile/BioDataCard";
import ProfileDevices from "glite-components/user-profile/ProfileDevices";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { CardContent } from "@material-ui/core";
import { baseUrl } from "helpers/Constants";
import { useAuth } from "helpers/use-auth";
import axios from "axios";
import Code from "@material-ui/icons/Code";
import BugReport from "@material-ui/icons/BugReport";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import UserPasswordChanger from "glite-components/user-profile/UserPasswordChanger";
import AdminPasswordChanger from "glite-components/user-profile/AdminPasswordChanger";
import TransactionsTableWrapper from "glite-components/payment-manager/TransactionsTableWrapper";
import DeviceCrud from "glite-components/user-profile/DeviceCrud";

const useStyles = makeStyles(styles);

function UserProfile({ userId }) {
  const auth = useAuth();
  const activeUserId = userId ? userId : auth.user.id;
  const classes = useStyles();
  const [userProfile, setUserProfile] = useState({});
  const [devices, setDevices] = useState([]);
  const [user, setUser] = useState({});
  function makeMoney(amt) {
    // Create our number formatter.
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "UGX",
    });

    return formatter.format(amt);
  }

  useEffect(() => {
    axios
      .get(`${baseUrl}userProfile/${activeUserId}`)
      .then((response) => {
        let profile = response.data;
        setUserProfile(profile);
        setDevices(profile.devices);
        setUser(profile.user);
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
      });
  }, []);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <RouterIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Devices</p>
              <h3 className={classes.cardTitle}>{devices.length}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Get more
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AttachMoneyIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Sales</p>
              <h3 className={classes.cardTitle}>
                {makeMoney(userProfile.totalSales)}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                From client orders
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AccountBalanceIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Expenses</p>
              <h3 className={classes.cardTitle}>
                {makeMoney(userProfile.totalExpenses)}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked from purchases
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <CustomTabs
        title="My:"
        headerColor="primary"
        tabs={[
          {
            tabName: "Data",
            tabIcon: BugReport,
            tabContent: (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <BioDataCard user={user} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {userId ? (
                    <AdminPasswordChanger
                      actorId={auth.user.id}
                      userId={userId}
                      classes={classes}
                    />
                  ) : (
                    <UserPasswordChanger
                      userId={activeUserId}
                      classes={classes}
                    />
                  )}
                  <Card>
                    <CardHeader color="warning">
                      <h3 className={classes.cardTitle}>Devices</h3>
                    </CardHeader>
                    <CardContent>
                      {userId ? (
                        <DeviceCrud userId={userId} />
                      ) : (
                        <ProfileDevices devices={devices} />
                      )}
                    </CardContent>
                  </Card>
                </GridItem>
              </GridContainer>
            ),
          },
          {
            tabName: "Transactions",
            tabIcon: Code,
            tabContent: <TransactionsTableWrapper userId={activeUserId} />,
          },
        ]}
      />
    </div>
  );
}
UserProfile.propTypes = { userId: PropTypes.number };
export default UserProfile;
