import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const SoilTestingResultTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "farm", numeric: false, disablePadding: true, label: "Farm" },
  { id: "results", numeric: false, disablePadding: true, label: "Results" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const SoilTestingResultTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  farm: { value: "", type: "text", label: "Farm" },
  results: { value: "", type: "text", label: "Results" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};

export const createSoilTestingResultRecord = (data) => {
  let record = {
    id: { 
        value: data.id, 
        type: "number", 
        label: "ID", 
        readOnly: true 
    },
    farmId: { 
        value: data.farmId, 
        type: "text", 
        label: "Farm ID" 
    },
    
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const SoilTestingResultTableIdField = "id";
export const SoilTestingResultTableTitle = "soil-testing-results";
export const SoilTestingResultBaseUrl = baseUrl + "soil-testing-results";
