import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GroupIcon from "@material-ui/icons/Group";
import YieldCrud from "glite-components/yield-forecast/YieldCrud";
export default function YieldForecast() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="YieldForecast:"
            headerColor="primary"
            tabs={[
              {
                tabName: "yield",
                tabIcon: GroupIcon,
                tabContent: <YieldCrud />,
              },

            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
