import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NewDeviceForm from "./NewDeviceForm";
import EditDeviceForm from "./EditDeviceForm";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "components/Table/CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  deviceTableHeaders,
  createDeviceRecord,
} from "../../helpers/schemas/Device";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function DeviceCrud({ userId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceTypesByName, setDeviceTypesByName] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}user-devices/${userId}`)
      .then((response) => {
        let result = response.data.map((rec) => createDeviceRecord(rec.device));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
    axios
      .get(`${baseUrl}device-types`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        let byName = {};
        result.forEach((type) => (byName[type.value] = type.key));
        setDeviceTypesByName(byName);
        setDeviceTypes(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
  }, []);
  function handleBackToCreate() {
    setFormData({});
  }
  function loadEditData(tableData) {
    let selected = {};
    Object.keys(tableData).forEach((k) => {
      if (k === "deviceTypeId")
        selected[k] = "" + deviceTypesByName[tableData[k].value];
      else selected[k] = tableData[k].value;
    });
    return selected;
  }
  function saveEdit(editedDevice) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "devices/" + editedDevice.id, editedDevice)
      .then((newRecord) => {
        let transformed = createDeviceRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === editedDevice.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function create(newDevice) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(`${baseUrl}devices/${userId}`, newDevice)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        setLoading(false);
        setStatus("FAILED");
      });
  }
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <EditDeviceForm
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              deviceTypes={deviceTypes}
            />
          </div>
        ) : (
          <NewDeviceForm
            onCreate={create}
            loading={loading}
            status={status}
            userError={userError}
            deviceTypes={deviceTypes}
          />
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="User Devices"
        headers={deviceTableHeaders}
        data={data}
        setSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
DeviceCrud.propTypes = { userId: PropTypes.number };
