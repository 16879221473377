import React, { useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ComboBox from "components/ComboBox";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
// import { blue } from "@material-ui/core/colors";

const nutrients = [
  { key: "NITROGEN", value: "NITROGEN" },
  { key: "PHOSPHORUS", value: "PHOSPHORUS" },
  { key: "POTASSIUM", value: "POTASSIUM" },
  { key: "CONDUCTIVITY", value: "CONDUCTIVITY" },
  { key: "HUMIDITY", value: "HUMIDITY" },
  { key: "TEMP", value: "TEMP" },
  { key: "PH", value: "PH" },
];
const bounds = [
  { key: "UPPER", value: "UPPER" },
  { key: "LOWER", value: "LOWER" },
];

export default function NutrientPropDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [nutrient, setNutrient] = useState("NITROGEN");
  const [bound, setBound] = useState("UPPER");
  const [value, setValue] = useState(0.0);

  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleAdd = () => {
    onClose({ param: nutrient, bound, value });
  };

  const handleNutrientSelect = (e) => {
    setNutrient(e.target.value);
  };
  const handleBoundSelect = (e) => {
    setBound(e.target.value);
  };
  const handleValueUpdate = (e) => {
    setValue(e.target.value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Add parameter</DialogTitle>
      <ComboBox
        kvOptions={nutrients}
        onSelect={handleNutrientSelect}
        label={"Nutrient"}
        value={nutrient}
      />
      <ComboBox
        kvOptions={bounds}
        onSelect={handleBoundSelect}
        label={"Bound Type"}
        value={bound}
      />
      <TextField
        label="Value"
        id="value"
        value={value}
        onChange={handleValueUpdate}
        variant="outlined"
        size="small"
      />
      <Button color="success" round onClick={handleAdd}>
        add
      </Button>
    </Dialog>
  );
}

NutrientPropDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
