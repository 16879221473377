import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const villageTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "districtId",
    numeric: false,
    disablePadding: true,
    label: "District",
  },
  { id: "subcountyId", numeric: false, disablePadding: true, label: "Subcounty" },
  { id: "parishId", numeric: false, disablePadding: true, label: "Parish" },
  { id: "name", numeric: false, disablePadding: true, label: "Village" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const villageTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  districtId: { value: "", type: "number", label: "District" },
  subCountyId: { value: "", type: "number", label: "Subcounty" },
  parishId: { value: "", type: "number", label: "Parish" },
  name: { value: "", type: "text", label: "Village Name" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createVillageRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    districtId: {
      value: data.district.name,
      type: "number",
      label: "District",
    },
    name: { value: data.name, type: "text", label: "Village Name" },
    subcountyId: {
      value: data.subcounty.name,
      type: "text",
      label: "Subcounty",
    },
    parishId: {
      value: data.parish.name,
      type: "number",
      label: "Parish",
    },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const villageTableIdField = "id";
export const villageTableTitle = "Villages";
export const villageBaseUrl = baseUrl + "villages";
