import React from "react";
import PropTypes from "prop-types";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ChartistGraph from "react-chartist";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

export default function LineGraph(props) {
  const classes = useStyles();
  const { title, periodSlug, options, max, data, color, listener } = props;
  return (
    <Card chart>
      <CardHeader color={color}>
        <ChartistGraph
          className="ct-chart"
          data={data}
          type="Line"
          options={{
            ...options,
            high: parseInt(max * 1.5),
          }}
          listener={listener}
        />
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>{title}</h4>
        <p className={classes.cardCategory}>{periodSlug}</p>
      </CardBody>
    </Card>
  );
}
LineGraph.propTypes = {
  title: PropTypes.string.isRequired,
  periodSlug: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  max: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  listener: PropTypes.object.isRequired,
};
