import { RadialGauge } from "canvas-gauges";
import PropTypes from "prop-types";
import React from "react";

class ReactRadialGauge extends React.Component {
  componentDidMount() {
    this.gauge = new RadialGauge({
      renderTo: this.canvasRef,
      width: 300,
      height: 300,
      units: this.props.units,
      title: this.props.title,
      minValue: this.props.majorTicks[0],
      maxValue: this.props.majorTicks[this.props.majorTicks.length - 1],
      majorTicks: this.props.majorTicks,
      minorTicks: 10,
      value: this.props.value,
      strokeTicks: false,
      // highlights: [
      //   {
      //     from: 160,
      //     to: 220,
      //     color: "rgba(200, 50, 50, .75)",
      //   },
      // ],
      colorPlate: "#fff",
      borderShadowWidth: 0,
      borders: true,
      needleType: "arrow",
      needleWidth: 2,
      needleCircleSize: 7,
      needleCircleOuter: true,
      needleCircleInner: false,
      animationDuration: 1500,
      animationRule: "linear",
    }).draw();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.gauge) {
      this.gauge.update({
        value: props.value,
        units: props.units,
        title: props.title,
      });
    }
  }
  render() {
    return <canvas ref={(node) => (this.canvasRef = node)} />;
  }
}
ReactRadialGauge.propTypes = {
  value: PropTypes.string,
  units: PropTypes.string,
  title: PropTypes.string,
  majorTicks: PropTypes.array,
};
export default ReactRadialGauge;
