import React from "react";
import PropTypes from "prop-types";
// import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

// import Paper from "@material-ui/core/Paper";
// import Typography from "@material-ui/core/Typography";
class GoogleMapsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
    // binding this to event-handler functions
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }
  onMarkerClick = (props, marker, e) => {
    console.log(e);
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };
  onMapClick = (props) => {
    console.log(props);
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };
  render() {
    const style = {
      width: "80vw",
      height: "85vh",
      marginLeft: "auto",
      marginRight: "auto",
    };
    console.log("xxxx markers", this.props.markers);
    const markers = this.props.markers.map(({ title, position }, index) => {
      return (
        <Marker
          key={index}
          onClick={this.onMarkerClick}
          title={title}
          position={{ ...position }}
          name={title}
        />
      );
    });
    return (
      <Map
        item
        xs={12}
        style={style}
        google={this.props.google}
        onClick={this.onMapClick}
        zoom={14}
        initialCenter={{ lat: 0.2812882749526412, lng: 32.48007374531437 }}
      >
        {markers}
      </Map>
    );
  }
}
GoogleMapsContainer.propTypes = {
  google: PropTypes.object,
  markers: PropTypes.array,
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyDbpHCwwQEFuH-fiahKti1th1SOPwlSnIU",
})(GoogleMapsContainer);
