import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "helpers/Constants";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MonitorsTable from "./MonitorsTable";
import MonitorTargetsTable from "./MonitorTargetsTable";
import CreateMonitorForm from "./CreateMonitorForm";
import CreateMonitorTargetForm from "./CreateMonitorTargetForm";

// const useStyles = makeStyles(tableStyles);

export default function AlertsManager() {
  const [monitors, setMonitors] = useState([]);
  const [users, setUsers] = useState([]);
  const [farms, setFarms] = useState([]);
  const [devices, setDevices] = useState([]);
  const [monitorTargets, setMonitorTargets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTarget, setLoadingTarget] = useState(false);
  const [status, setStatus] = useState();
  const [targetStatus, setTargetStatus] = useState();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}monitors`)
      .then((response) => {
        setMonitors(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    setLoadingTarget(true);
    axios
      .get(`${baseUrl}monitor-targets`)
      .then((response) => {
        setLoadingTarget(false);
        setMonitorTargets(response.data);
      })
      .catch(() => {
        setLoadingTarget(false);
      });
    axios
      .get(`${baseUrl}users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch(() => {});
    axios
      .get(`${baseUrl}farms`)
      .then((response) => {
        setFarms(response.data);
      })
      .catch(() => {});
    axios
      .get(`${baseUrl}devices`)
      .then((response) => {
        setDevices(response.data);
      })
      .catch(() => {});
  }, []);
  function handleSave(data) {
    setLoading(true);
    axios
      .post(`${baseUrl}monitors`, data)
      .then((response) => {
        setMonitors([response.data, ...monitors]);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch(() => {
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function handleSaveTarget(data) {
    setLoadingTarget(true);
    axios
      .post(`${baseUrl}monitor-targets`, data)
      .then(() => {
        setLoadingTarget(false);
        setTargetStatus("SUCCESS");
      })
      .catch(() => {
        setLoadingTarget(false);
        setTargetStatus("FAILED");
      });
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <CreateMonitorForm
          loading={loading}
          onSave={handleSave}
          status={status}
        />
        <MonitorsTable monitors={monitors} />
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <CreateMonitorTargetForm
          monitors={monitors}
          farms={farms}
          farmers={users}
          devices={devices}
          onSave={handleSaveTarget}
          loading={loadingTarget}
          status={targetStatus}
        />
        <MonitorTargetsTable monitorTargets={monitorTargets} />
      </GridItem>
    </GridContainer>
  );
}
