import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from "@material-ui/pickers";
 import "date-fns";
import DateFnsUtils from "@date-io/date-fns"; 
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import ComboBox from "components/ComboBox";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmptyObject } from "helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function YieldEditor({
  loading,
  onEdit,
  formData,
  status,
  farmsKv,
  cropsKv,
  usersKv,
  seasonsKv,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  console.log("xxxx data", JSON.stringify(data));
  useEffect(() => {
    setData(formData);
    setErrors({});
    return () => setData({});
  }, [formData]);

  const handleFarmSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["farmId"] = e.target.value;
    setData(oldData);
  };
  const handleOwnerSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["ownerId"] = e.target.value;
    setData(oldData);
  };
  const handleCropSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["cropId"] = e.target.value;
    setData(oldData);
  };

  const handleSeasonSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["seasonId"] = e.target.value;
    setData(oldData);
  };

  const handleClick = () => {
    let errorList = {};
    if (isEmptyObject(errorList)) {
      let edited = { ...data };
      let changes = Object.fromEntries(
        Object.entries(edited).filter(
          ([k, v]) => k === "id" || v !== formData[k]
        )
      );
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  function handleChange(event, name) {
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  }
  return (
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <ComboBox
          error={errors["farmId"]}
          value={data["farmId"]}
          label={"Farm"}
          onSelect={handleFarmSelection}
          kvOptions={farmsKv}
        />
        <ComboBox
          error={errors["ownerId"]}
          value={data["ownerId"]}
          label={"Owner"}
          onSelect={handleOwnerSelection}
          kvOptions={usersKv}
        />
        <ComboBox
          error={errors["cropId"]}
          value={data["cropId"]}
          label={"Crop"}
          onSelect={handleCropSelection}
          kvOptions={cropsKv}
        />
        <ComboBox
          error={errors["seasonId"]}
          value={data["seasonId"]}
          label={"Season"}
          onSelect={handleSeasonSelection}
          kvOptions={seasonsKv}
        />
      </div>
      <div>
        <TextField
          error={checkError("tilledSize")}
          label="Tilled Land Size(acres)"
          id="tilledSize"
          value={data.tilledSize}
          onChange={(e) => handleChange(e, "tilledSize")}
          variant="outlined"
          helperText={errors.tilledSize}
          size="small"
        />
        <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Pick Planting date"
            value={data.plantingDate}
            onChange={data.onplantingDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        <TextField
          error={checkError("expectedYield")}
          label="Expected Yield"
          id="expectedYield"
          value={data.expectedYield}
          onChange={(e) => handleChange(e, "expectedYield")}
          variant="outlined"
          helperText={errors.expectedYield}
          size="small"
        />
        <TextField
          error={checkError("actualYield")}
          label="Actual Yield"
          id="actualYield"
          value={data.actualYield}
          onChange={(e) => handleChange(e, "actualYield")}
          variant="outlined"
          helperText={errors.actualYield}
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Update successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => handleClick(data)}
        >
          Update
        </Button>
      </div>
    </form>
    </MuiPickersUtilsProvider>
  );
}
YieldEditor.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  farmsKv: PropTypes.array.isRequired,
  cropsKv: PropTypes.array.isRequired,
  usersKv: PropTypes.array.isRequired,
  seasonsKv: PropTypes.array.isRequired,
};
