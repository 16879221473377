// @material-ui/icons
import Sensors from "@material-ui/icons/NetworkCheckOutlined";
import Person from "@material-ui/icons/Person";
import Message from "@material-ui/icons/Message";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Money from "@material-ui/icons/Money";
import StoreIcon from "@material-ui/icons/Store";
import Map from "@material-ui/icons/Map";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
// import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "glite-views/UserProfile.js";
// import TableList from "views/TableList/TableList.js";
// import Typography from "views/Typography/Typography.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
import Users from "glite-views/Users.js";
import SmsLog from "glite-views/SmsLog.js";
import Crops from "glite-views/Crops.js";
import Devices from "glite-views/Devices.js";
import Locations from "glite-views/Locations.js";
import YieldForecast from "glite-views/YieldForecast.js";
import Insurance from "glite-views/Insurance.js";
// import NotificationsManager from "glite-views/NotificationsManager";
import PaymentManager from "glite-views/PaymentManager";
import SoilData from "glite-views/SoilData";
import ShopManager from "glite-views/ShopManager";
import Supplier from "glite-views/Supplier";
import GoShopping from "glite-views/GoShopping";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import AlertsManager from "glite-components/nutrient-reporter/AlertsManager";
import WarningIcon from "@material-ui/icons/WarningRounded";
import SoilDataMapView from "glite-components/nutrient-reporter/SoilDataMapView";

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/admin",
  //   hide: false,
  //   // accessLevel: "ADMIN",
  // },
  {
    path: "/shop",
    name: "Shop Manager",
    icon: StoreIcon,
    component: ShopManager,
    layout: "/admin",
    hide: true,
    accessLevel: "ADMIN",
  },
  {
    path: "/shopping",
    name: "Digital Transactions",
    icon: ShoppingCart,
    component: GoShopping,
    layout: "/admin",
    hide: true,
    accessLevel: "CLIENT",
  },
  {
    path: "/user",
    name: "Account summary",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    hide: true,
    // accessLevel: "ADMIN",
  },
  {
    path: "/supplier",
    name: "Supplier Home",
    icon: Person,
    component: Supplier,
    layout: "/admin",
    hide: true,
    accessLevel: "SUPPLIER",
  },
  {
    path: "/users",
    name: "User Manager",
    icon: Person,
    component: Users,
    layout: "/admin",
    hide: true,
    accessLevel: "ADMIN",
  },
  {
    path: "/mapdataview",
    name: "Map Data View",
    icon: Message,
    component: SoilDataMapView,
    layout: "/admin",
    hide: true,
    accessLevel: "ADMIN",
  },
  {
    path: "/smslog",
    name: "SMS Center",
    icon: Message,
    component: SmsLog,
    layout: "/admin",
    hide: true,
    accessLevel: "ADMIN",
  },
  {
    path: "/soil-data",
    name: "Soil Nutrient Reporter",
    icon: AssessmentIcon,
    component: SoilData,
    layout: "/admin",
    hide: false,
    accessLevel: "CLIENT",
  },
  {
    path: "/crops",
    name: "Fertilizer Management",
    icon: FilterVintageIcon,
    component: Crops,
    layout: "/admin",
    hide: true,
    accessLevel: "CLIENT",
  },
  {
    path: "/devices",
    name: "Device Center",
    icon: Sensors,
    component: Devices,
    layout: "/admin",
    hide: true,
    accessLevel: "ADMIN",
  },
  // {
  //   path: "/notifications",
  //   name: "Notifications Manager",
  //   icon: Notifications,
  //   component: NotificationsManager,
  //   layout: "/admin",
  //   hide: true,
  //   accessLevel: "ADMIN",
  // },
  {
    path: "/payments",
    name: "Payments Manager",
    icon: Money,
    component: PaymentManager,
    layout: "/admin",
    hide: true,
    accessLevel: "ADMIN",
  },
  {
    path: "/locations",
    name: "Location Manager",
    icon: Map,
    component: Locations,
    layout: "/admin",
    hide: false,
    accessLevel: "CLIENT",
  },
  {
    path: "/monitoring",
    name: "Monitors Manager",
    icon: WarningIcon,
    component: AlertsManager,
    layout: "/admin",
    hide: true,
    accessLevel: "ADMIN",
  },
  {
  path: "/YieldForecast",
  name: "Yield Forecasts",
  icon: WarningIcon,
  component: YieldForecast,
  layout: "/admin",
  hide: false,
  accessLevel: "CLIENT",
  },
  {
  path: "/Insurance",
  name: "Insurance",
  icon: WarningIcon,
  component: Insurance,
  layout: "/admin",
  hide: false,
  accessLevel: "CLIENT",
  },
  // {
  //   path: "/notifications-widgets",
  //   name: "Notifications",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  //   hide: true,
  //   accessLevel: "ADMIN",
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin",
  //   hide: true,
  //   accessLevel: "ADMIN",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin",
  //   hide: true,
  //   // accessLevel: "ADMIN",
  // },
];

export const userAuthorized = (route, roles) => {
  if (route.accessLevel) {
    return roles.includes(route.accessLevel) || roles.includes("ADMIN");
  } else return true;
};

export default dashboardRoutes;
