import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "helpers/Constants";
import ShoppingProducts from "glite-components/go-shopping/InsuranceShoppingProducts";

export default function InsuranceInsuranceregs() {
  const [loading, setLoading] = useState(false);
  const [insuranceregs, setInsuranceregs] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}insuranceregs`)
      .then((response) => {
        setInsuranceregs(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return <ShoppingProducts loading={loading} products={insuranceregs} />;
}
