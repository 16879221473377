import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GroupIcon from "@material-ui/icons/Group";
import LanguageIcon from "@material-ui/icons/Language";
import UserBioData from "../glite-components/user-manager/UserBioData";
import LanguageData from "../glite-components/language-manager/LanguageData";
export default function Users() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="User:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Data",
                tabIcon: GroupIcon,
                tabContent: <UserBioData />,
              },
              {
                tabName: "Languages",
                tabIcon: LanguageIcon,
                tabContent: <LanguageData />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
