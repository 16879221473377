import React, { useState, useEffect } from "react";
// import EnhancedTable from "components/EnhancedTable";
import NewSmsTransactionForm from "./NewSmsTransactionForm";
import axios from "axios";
import { baseUrl } from "../../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "./CheckedTable";
import Spinner from "components/Spinner";
import { smsTransactionTableHeaders, createSmsTransactionRecord } from "../../../helpers/schemas/SmsTransaction";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function SmsTransactionData() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [userKv, setUserKv] = useState([]);

  useEffect(() => {
    setTableLoading(true);
    loadData();
      axios
      .get(`${baseUrl}users`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.firstName+" "+rec.lastName };
        });
        setUserKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
    });
  }, []);

  function loadData() {
    axios
      .get(`${baseUrl}smstransaction`)
      .then((response) => {
        let result = response.data.map((rec) => createSmsTransactionRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
    });
  }

  function create(newSmsTransaction) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(baseUrl + "smstransaction", newSmsTransaction)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");

        loadData();
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        // if (error.response.status === 400) {
        //   setUserError(error.response.data.message);
        // }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  const classes = useStyles();
  
  return (
    <div>
      <div className={classes.root}>
          <NewSmsTransactionForm
            onCreate={create}
            loading={loading}
            status={status}
            userError={userError}
            userKv={userKv}
          />
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="SMS Transaction"
        headers={smsTransactionTableHeaders}
        data={data}
        setSmsTransactionSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
