import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DeviceTableWrapper from "glite-components/device-center/DeviceTableWrapper";
import DeviceCrud from"glite-components/device-center/DeviceCrud";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import DeviceIcon from "@material-ui/icons/Devices";

export default function Devices() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        
        <CustomTabs
            title="Location:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Devices",
                tabIcon: DeviceIcon,
                tabContent: <DeviceTableWrapper />,
              },
              {
                tabName: "Manage Devices",
                tabIcon: DeviceIcon,
                tabContent: <DeviceCrud />,
              }
            ]}
          />
      </GridItem>
    </GridContainer>
  );
}
