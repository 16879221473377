import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "components/Table/CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  districtTableHeaders,
  createDistrictRecord,
} from "../../helpers/schemas/District";
import DistrictEditor from "./DistrictEditor";
import DistrictCreator from "./DistrictCreator";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function DistrictCrud() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [regionsKv, setRegionsKv] = useState([]);
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}districts`)
      .then((response) => {
        let result = response.data.map((rec) => createDistrictRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
    axios
      .get(`${baseUrl}regions`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setRegionsKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
  }, []);

  function loadEditData(tableData) {
    let selected = {};
    Object.keys(tableData).forEach((k) => (selected[k] = tableData[k].value));
    return selected;
  }
  function saveEdit(edited) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "districts/" + edited.id, edited)
      .then((newRecord) => {
        let transformed = createDistrictRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === edited.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function create(record) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(baseUrl + "districts", record)
      .then(() => {
        // console.log("xxxxx response", response.data);
        // let newRecord = createDistrictRecord(response.data);
        // let newData = { newRecord, ...data };
        // setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        // if (error.response.status === 400) {
        //   setUserError(error.response.data.message);
        // }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function handleBackToCreate() {
    setFormData({});
  }
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <DistrictEditor
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              regionsKv={regionsKv}
            />
          </div>
        ) : (
          <DistrictCreator
            onCreate={create}
            loading={loading}
            status={status}
            userError={userError}
            regionsKv={regionsKv}
          />
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="Districts"
        headers={districtTableHeaders}
        data={data}
        setSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
