import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";

export const smsTransactionTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "userId", numeric: false, disablePadding: true, label: "User" },
  { id: "narrative",numeric: false,disablePadding: true,label: "Narrative"},
  { id: "amount", numeric: true, disablePadding: true, label: "Amount" },
  { id: "balanceBefore", numeric: true, disablePadding: true, label: "Balance Before" },
  { id: "balanceAfter", numeric: true, disablePadding: true, label: "Balance After" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const smsTransactionTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  userId: { value: "", type: "text", label: "User" },
  narrative: { value: "", type: "text", label: "Narrative" },
  amount: { value: "", type: "text", label: "Amount" },
  balanceBefore: { value: "", type: "text", label: "Balance Before" },
  balanceAfter: { value: "", type: "text", label: "Balance After" },
  
  createdAt: {value: "",type: "text",label: "Date Created",readOnly: true},
  updatedAt: {value: "",type: "text",label: "Date Updated",readOnly: true},
};

export const createSmsTransactionRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    userId: { value: data.user.id, type: "text", label: 'User',
      userName: (data.user == undefined ? "" : data.user.firstName+" "+data.user.lastName),
    },
    narrative: { value: data.narrative, type: "text", label: "Narrative" },
    amount: { value: data.amount, type: "text", label: "Amount" },
    balanceBefore: { value: data.balanceBefore, type: "text", label: "Balance Before" },
    balanceAfter: { value: data.balanceAfter, type: "text", label: "Balance After" },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const smsTransactionTableIdField = "id";
export const smsTransactionTableTitle = "SMS Transaction";
export const smsTransactionBaseUrl = baseUrl + "smstransaction";
