import React from "react";
import { baseUrl } from "../Constants";
import Button from "components/CustomButtons/Button.js";
import moment from "moment";
function formatMoney(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
function statusButton(status) {
  let color;
  if (status === "SUCCESS") color = "success";
  else if (status === "FAILED") color = "danger";
  else color = "primary";
  return <Button color={color}>{status}</Button>;
}
export const paymentTableHeaders = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  { id: "payerId", numeric: true, disablePadding: false, label: "Paid By" },
  { id: "amount", numeric: true, disablePadding: false, label: "Amount" },
  {
    id: "providerId",
    numeric: true,
    disablePadding: false,
    label: "Service Provider",
  },
  {
    id: "externalId",
    numeric: false,
    disablePadding: true,
    label: "External ID",
  },
  { id: "status", numeric: false, disablePadding: true, label: "Status" },
  {
    id: "msisdn",
    numeric: false,
    disablePadding: true,
    label: "Phone No",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Update",
  },
];

export const paymentTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  payerId: { value: "", type: "number", label: "Payer" },
  amount: { value: "", type: "number", label: "Paid By" },
  providerId: { value: "", type: "number", label: "Service Provider" },
  externalId: { value: "", type: "text", label: "External ID" },
  status: { value: "", type: "text", label: "Status" },
  msisdn: { value: "", type: "text", label: "Msisdn" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createPaymentRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    payerId: {
      value: data.user.firstName + " " + data.user.lastName,
      type: "number",
      label: "Paid By",
    },
    amount: {
      value: formatMoney(data.amount),
      type: "number",
      label: "Amount",
    },
    providerId: {
      value: data.provider.name,
      type: "number",
      label: "Service Provider",
    },
    externalId: {
      value: data.externalId,
      type: "text",
      label: "External ID",
    },
    status: {
      value: statusButton(data.status),
      type: "text",
      label: "Status",
    },
    msisdn: {
      value: data.msisdn,
      type: "text",
      label: "Phone No",
    },
    createdAt: {
      value: moment(Date.parse(data.createdAt)).format("YYYY-MM-DD h:mm"),
      type: "text",
      label: "Date",
      readOnly: true,
    },
    updatedAt: {
      value: moment(Date.parse(data.createdAt)).format("YYYY-MM-DD h:mm"),
      type: "text",
      label: "Update",
      readOnly: true,
    },
  };
  return record;
};

export const paymentTableIdField = "id";
export const paymentTableTitle = "Payment Transactions";
export const paymentBaseUrl = baseUrl + "payments";
