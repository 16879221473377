import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ProgressBar from "components/ProgressBar";
import Product from "glite-components/shop/Product";
import Button from "@material-ui/core/Button";
import ItemsInCartIcon from "glite-components/shop/ItemsInCartIcon";
import Checkout from "./Checkout";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ShoppingCart from "./ShoppingCart";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export default function ShoppingProducts({ products, loading }) {
  const [productsKeyedById, setProductsKeyedById] = useState({});
  const [cartItems, setCartItems] = useState({});
  const [chunkedProducts, setChunkedProducts] = useState([]);
  const [itemsInCart, setItemsInCart] = useState(0);
  const [showCart, setShowCart] = useState(false);
  useEffect(() => {
    setChunkedProducts(chunk(products));
    setProductsKeyedById(
      Object.fromEntries(products.map((prod) => [prod.id, prod]))
    );
  }, [products]);
  function addToCart(prod) {
    let id = prod.id;
    let inCart = (cartItems[id] || 0) + 1;
    updateCart({ ...cartItems, [id]: inCart }, itemsInCart + 1);
  }
  function toggleShowCart() {
    setShowCart(!showCart);
  }
  function reset() {
    setItemsInCart(0);
    setCartItems({});
  }
  function removeFromCart(prod) {
    let id = prod.id;
    let inCart = cartItems[id];
    inCart &&
      inCart > 0 &&
      updateCart({ ...cartItems, [id]: inCart - 1 }, itemsInCart - 1);
  }
  function updateCart(items, count) {
    setCartItems(items);
    setItemsInCart(count);
  }
  function chunk(arr) {
    var i,
      j,
      temporary,
      chunk = 4;
    let res = [];
    for (i = 0, j = arr.length; i < j; i += chunk) {
      temporary = arr.slice(i, i + chunk);
      res.push(temporary);
    }
    return res;
  }

  return (
    <div>
      <div>
        <ItemsInCartIcon count={itemsInCart} handleClick={toggleShowCart} />
        <Button
          variant="outlined"
          color="primary"
          onClick={reset}
          disabled={itemsInCart === 0}
        >
          Reset Selection
        </Button>
      </div>
      {loading && <ProgressBar />}
      {showCart ? (
        <div>
          <Tooltip title="Back to shopping">
            <IconButton onClick={toggleShowCart}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <ShoppingCart
            cartItems={cartItems}
            keyedProducts={productsKeyedById}
          />
          <Checkout cartItems={cartItems} keyedProducts={productsKeyedById} />
        </div>
      ) : (
        chunkedProducts.map((product, row) => {
          return (
            <GridContainer key={row}>
              {product.map((p) => {
                return (
                  <GridItem key={p.id} xs={12} sm={12} md={3}>
                    <Product
                      details={p}
                      inCart={cartItems[p.id] || 0}
                      addToCart={addToCart}
                      removeFromCart={removeFromCart}
                    />
                  </GridItem>
                );
              })}
            </GridContainer>
          );
        })
      )}
    </div>
  );
}
ShoppingProducts.propTypes = {
  loading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
};
