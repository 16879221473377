import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  updateButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function EditProductUnitForm({
  loading,
  onEdit,
  formData,
  status,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setData(formData);
    setErrors({});
    return () => setData({});
  }, [formData]);
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handleClick = () => {
    let errorList = {};
    if (!data.label) errorList.label = "field cannot be empty";
    if (!data.numKg) errorList.numKg = "field cannot be empty";
    if (data.numKg && isNaN(data.numKg))
      errorList.numKg = "This value must be a number";
    if (emptyObject(errorList)) {
      let changes = Object.fromEntries(
        Object.entries(data).filter(([k, v]) => k === "id" || v !== formData[k])
      );
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  function handleChange(event, name) {
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          error={checkError("label")}
          helperText={errors.label}
          label="Product Unit Name"
          id="name"
          defaultValue={data.label}
          value={data.label}
          onChange={(e) => handleChange(e, "label")}
          variant="outlined"
          size="small"
        />
      </div>
      <div>
        <TextField
          error={checkError("numKg")}
          helperText={errors.numKg}
          label="PER UNIT KG"
          id="numKg"
          defaultValue={data.numKg}
          value={data.numKg}
          onChange={(e) => handleChange(e, "numKg")}
          variant="outlined"
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Update successful!
        </Alert>
      )}
      <div className={classes.updateButton}>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => handleClick(data)}
        >
          Update
        </Button>
      </div>
    </form>
  );
}
EditProductUnitForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};
