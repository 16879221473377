import axios from "axios";
import { baseUrl } from "./Constants";

export const login = (username, password) => {
  return axios
    .post(
      `${baseUrl}auth/signin`,
      JSON.stringify({ userName: username, password: password }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      return handleResponse(error.response);
    });
};

export const register = (email, password) => {
  console.log(email, password);
  return null;
};

export const logout = () => {
  return null;
};

export const sendPasswordResetEmail = (email) => {
  console.log(email);
  return null;
};

export const confirmPasswordReset = (email, password) => {
  console.log(email, password);
  return null;
};

function handleResponse(response) {
  const data = response.data;
  if (response.status >= 400) {
    if ([401, 403].indexOf(response.status) !== -1) {
      // logout();
      // window.location.reload(true);
      return Promise.reject(data.error);
    }
    const error = (data && data.error) || response.status;
    return Promise.reject(error);
  }

  return data;
}
