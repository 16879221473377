import React, { Component } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import axios from 'axios';
import Spinner from "components/Spinner";
import { baseUrl } from "../../helpers/Constants";

class UploadFiles extends Component {
    state = {
        file: '',
        results: '',
        loading: false
    };

    componentDidMount() {
        const { file } = this.props;
        this.setState({ file });
    }

    handleFileChange = (event) => {
        this.setState({ file: event.target.files[0] });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            results: "",
            loading: true
        });
        const { file } = this.state;
        let data = new FormData();
        data.append('file', file);
        axios.post(`${baseUrl}`+"excel/upload", data)
         //axios.post(baseUrl +"csv/upload", data)
            .then((res) => { 
                console.log(res);
                let successColor = "#027517";
                let errorColor = "#ad0320";
                let color;
                let r = res.data.map((resObj)=>{
                    if (resObj.results == "FAILED") {
                        color = errorColor;
                    } else {
                        color = successColor;
                    }
                    return <div key={resObj.rowNumber}><span style={{color:color}}>{resObj.rowNumber} {resObj.results} {resObj.message}</span></div>;
                });
                console.log(r);
                this.setState({
                    results : r,
                    loading: false
                }); 
                
            })
            .catch(err => { 
                console.error(err) 
                this.setState({
                    results : (<div><span>{err}</span></div>),
                    loading: false
                }); 
            });
    };

    render() {
        const { file, results, loading } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <div>
                    <h2>Upload Your File</h2>
                    <input
                        type="file"
                        onChange={this.handleFileChange}
                        
                    />

                    <button type="submit" disabled={!file}>Submit</button>
                </div>
                <h3>Upload Results</h3>
                {loading && <Spinner />}
                <div>{results}</div>
            </form>
        );
    }
}

// Define PropTypes for the component
UploadFiles.propTypes = {
    file: PropTypes.string // Specify the type of 'file' prop
};

export default UploadFiles;
