import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import ComboBox from "components/ComboBox";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmptyObject } from "helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function ParishEditor({
  loading,
  onEdit,
  formData,
  status,
  subcountysKv,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  console.log("xxxx data", JSON.stringify(data));
  useEffect(() => {
    setData(formData);
    setErrors({});
    return () => setData({});
  }, [formData]);

  const handleSubcountyChange = (e) => {
    let oldData = { ...data };
    oldData.subcountyId = e.target.value;
    setData(oldData);
  };

  const handleClick = () => {
    let errorList = {};
    if (!data.name) errorList.name = "field cannot be empty";
    if (!data.subcountyId) errorList.subcountyId = "field cannot be empty";
    if (isEmptyObject(errorList)) {
      let edited = { ...data };
      let changes = Object.fromEntries(
        Object.entries(edited).filter(
          ([k, v]) => k === "id" || v !== formData[k]
        )
      );
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  function handleChange(event, name) {
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <ComboBox
          value={data["subcountyId"]}
          label={"Subcounty"}
          onSelect={handleSubcountyChange}
          kvOptions={subcountysKv}
        />
        <TextField
          error={checkError("name")}
          label="Name"
          id="name"
          value={data.name}
          onChange={(e) => handleChange(e, "name")}
          variant="outlined"
          helperText={errors.name}
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Update successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => handleClick(data)}
        >
          Update
        </Button>
      </div>
    </form>
  );
}
ParishEditor.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  subcountysKv: PropTypes.array.isRequired,
};
