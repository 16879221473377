// ##############################
// // // javascript library for creating charts
// #############################
var Chartist = require("chartist");
require("chartist-plugin-legend");
// const ChartistTooltip = require("chartist-plugin-tooltips-updated");
require("chartist-plugin-tooltips");

// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;

// ##############################
// // // Daily feeds
// #############################
//what do i want - label chart lines by color
const dailyFeedsChart = {
  data: {
    labels: ["M", "T", "W", "T", "F", "S", "S"],
    series: [
      {
        // className: "N",
        name: "Nitrogen",
        data: [12, 17, 7, 17, 23, 18, 38],
      },
      {
        // className: "P",
        name: "Phosphorus",
        data: [38, 18, 17, 23, 17, 7, 12],
      },
      {
        // className: "K",
        name: "Potassium",
        data: [18, 38, 23, 17, 7, 17, 12],
      },
      {
        // className: "Ph",
        name: "Ph",
        data: [18, 38, 23, 17, 7, 17, 12],
      },
      {
        // className: "Temp",
        name: "Temp",
        data: [18, 38, 23, 17, 7, 17, 12],
      },
      {
        // className: "Moisture",
        name: "Humidity",
        data: [18, 38, 23, 17, 7, 17, 12],
      },
      {
        // className: "Conductivity",
        name: "EC",
        data: [18, 38, 23, 17, 7, 17, 12],
      },
      {
        // className: "Signal Level",
        name: "Signal",
        data: [18, 38, 23, 17, 7, 17, 12],
      },
    ],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 120, // recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    plugins: [
      // Chartist.plugins.legend({
      //   legendNames: ["N", "P", "K", "Ph", "°C", "Moisture", "EC", "Signal"],
      //   position: "top",
      // }),
      Chartist.plugins.tooltip({ appendToBody: true }),
      // Chartist.plugins.tooltip(),
    ],
  },
  // for animation
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

// ##############################
// // // Weekly
// #############################

const weeklyFeedsChart = {
  data: {
    labels: [
      "W-12",
      "W-11",
      "W-10",
      "W-9",
      "W-8",
      "W-7",
      "W-6",
      "W-5",
      "W-4",
      "W-3",
      "W-2",
      "W-1",
    ],
    series: [
      [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
      [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
      [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
    ],
  },
  options: {
    axisX: {
      showGrid: false,
    },
    low: 0,
    high: 1000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

// ##############################
// // // Completed Tasks
// #############################

const hourlyFeedsChart = {
  data: {
    labels: ["9am", "10pm", "11pm", "12pm", "1pm", "2pm", "3pm", "4pm"],
    series: [
      {
        // className: "N",
        name: "Nitrogen",
        data: [12, 17, 7, 17, 23, 18, 38, 14],
      },
      {
        // className: "P",
        name: "Phosphorus",
        data: [38, 18, 17, 23, 17, 7, 12, 16],
      },
      {
        // className: "K",
        name: "Potassium",
        data: [18, 38, 23, 17, 7, 17, 12, 13],
      },
    ],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

module.exports = {
  dailyFeedsChart,
  weeklyFeedsChart,
  hourlyFeedsChart,
};
