import { baseUrl } from "../Constants";
import moment from "moment";
export const productUnitTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "label", numeric: false, disablePadding: true, label: "LABEL" },
  { id: "numKg", numeric: false, disablePadding: true, label: "NUM KG" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Added",
  },
];

export const productUnitTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  label: { value: "", type: "text", label: "LABEL" },
  numKg: { value: "", type: "number", label: "PER UNIT KG" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Added",
    readOnly: true,
  },
};
export const createProductUnitRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    label: { value: data.label, type: "text", label: "LABEL" },
    numKg: { value: data.numKg, type: "number", label: "PER UNIT KG" },
    createdAt: {
      value: moment(data.createdAt.replace("T", " ").replace("Z", "")).format(
        "YYYY-MM-DD"
      ),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
  };
  return record;
};

export const productUnitIdField = "id";
export const productUnitTableTitle = "Product Units";
export const productUnitBaseUrl = baseUrl + "product-units";
