import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Warning from "@material-ui/icons/Warning";
//import DateRange from "@material-ui/icons/DateRange";
//import LocalOffer from "@material-ui/icons/LocalOffer";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import RouterIcon from "@material-ui/icons/Router";
//import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
//import BioDataCard from "glite-components/user-profile/BioDataCard";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
//import { CardContent } from "@material-ui/core";
import { baseUrl } from "helpers/Constants";
//import { useAuth } from "helpers/use-auth";
import axios from "axios";
//import Code from "@material-ui/icons/Code";

const useStyles = makeStyles(styles);

function LanguageProfile({ languageId }) {
  //const auth = useAuth();
  const classes = useStyles();
  const [/*userProfile,*/ setUserProfile] = useState({});

  useEffect(() => {
    axios
      .get(`${baseUrl}languageProfile/${languageId}`)
      .then((response) => {
        let profile = response.data;
        setUserProfile(profile);
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
      });
  }, []);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <RouterIcon />
              </CardIcon>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Get more
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
LanguageProfile.propTypes = { languageId: PropTypes.number };
export default LanguageProfile;
