import { baseUrl } from "../Constants";
import moment from "moment";
export const deviceTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Device Number",
  },
  {
    id: "deviceTypeId",
    numeric: false,
    disablePadding: false,
    label: "Device Type",
  },
  {
    id: "msisdn",
    numeric: false,
    disablePadding: true,
    label: "Msisdn",
  },
  {
    id: "farmId",
    numeric: false,
    disablePadding: true,
    label: "Farm",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created",
  },
];

export const deviceTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  number: { value: "", type: "text", label: "Device Number" },
  deviceTypeId: { value: "", type: "number", label: "Device Type" },
  msisdn: { value: "", type: "text", label: "Msisdn" },
  createdAt: {
    value: "",
    type: "text",
    label: "Created",
    readOnly: true,
  },
};
export const createDeviceRecord = (data) => {
  
  let record = {
    id: { value: data[7], type: "number", label: "ID", readOnly: true },
    number: { value: data[0], type: "text", label: "Device Number" },
    deviceTypeId: {
      value: data[2],//data.deviceType.name,
      type: "number",
      label: "Device Type",
    },
    msisdn: { value: data[5], type: "text", label: "Msisdn" },
    farmId: { value: data[2], type: "text", label: "Farm" },
    createdAt: {
      value: moment(data[8].replace("T", " ").replace("Z", "")).format(
        "YYYY-MM-DD"
      ),
      type: "text",
      label: "Created",
      readOnly: true,
    },
  };
  /*
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    number: { value: data.number, type: "text", label: "Device Number" },
    deviceTypeId: {
      value: data.deviceType.name,
      type: "number",
      label: "Device Type",
    },
    msisdn: { value: data.msisdn, type: "text", label: "Msisdn" },
    farmId: { value: data.farm.name, type: "text", label: "Farm" },
    createdAt: {
      value: moment(data.createdAt.replace("T", " ").replace("Z", "")).format(
        "YYYY-MM-DD"
      ),
      type: "text",
      label: "Created",
      readOnly: true,
    },
  };*/
  return record;
};

export const createDeviceRecordFromObject = (data) => {
  
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    number: { value: data.number, type: "text", label: "Device Number" },
    deviceTypeId: {
      value: data.deviceType.name,
      type: "number",
      label: "Device Type",
    },
    msisdn: { value: data.msisdn, type: "text", label: "Msisdn" },
    farmId: { value: data.farm.name, type: "text", label: "Farm" },
    createdAt: {
      value: moment(data.createdAt.replace("T", " ").replace("Z", "")).format(
        "YYYY-MM-DD"
      ),
      type: "text",
      label: "Created",
      readOnly: true,
    },
  };
  return record;
};

export const deviceTableIdField = "id";
export const deviceTableTitle = "Sensors";
export const deviceBaseUrl = baseUrl + "devices";
