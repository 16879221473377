import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { red } from "@material-ui/core/colors";
import BioDataTable from "./BioDataTable";
import MultipleSelector from "./MultipleSelector";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { useAuth } from "helpers/use-auth";
function createData(key, value) {
  return { key, value };
}
function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}
const allRoles = ["BASIC", "CLIENT", "SUPPLIER", "ADMIN"];
const roleIdMap = { BASIC: 4, CLIENT: 1, SUPPLIER: 5, ADMIN: 2 };

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "10px",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function BioDataCard({ user }) {
  const classes = useStyles();
  const auth = useAuth();
  const [userRoles, setUserRoles] = useState([]);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isEmptyObject(user)) {
      setUserRoles(user.userAuth.roles.map((r) => r.name));
    }
  }, [user]);
  useEffect(() => {
    if (!isEmptyObject(user)) {
      setRows([
        createData("Platform ID", user.id),
        createData("National ID Number(NIN)", user.nin),
        createData("First Name", user.firstName),
        createData("Last Name", user.lastName),
        createData("Middle Name", user.middleName),
        createData("Email", user.userAuth.email),
        createData("Phone Number", user.phoneNo),
        createData("Join Date", user.createdAt),
        createData("Data Last Modified", user.updatedAt),
        createData("Platform Roles", userRoles.join(", ")),
      ]);
    }
  }, [user, userRoles]);
  function handleRoleAdd(role) {
    axios
      .post(`${baseUrl}user-roles`, {
        userId: user.id,
        roleId: roleIdMap[role],
      })
      .then((response) => {
        let newRole = response.data;
        console.log("xxx new role", newRole);
        setUserRoles([...userRoles, role]);
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
      });
  }
  function handleRoleRemove(role) {
    //send API request to remove role
    if (role !== "BASIC") {
      axios
        .delete(`${baseUrl}user-roles/${user.id}/${roleIdMap[role]}`)
        .then((response) => {
          let newRole = response.data;
          console.log("xxx deleted role", newRole);
          setUserRoles(userRoles.filter((el) => el !== role));
        })
        .catch((error) => {
          console.log("xxx error", JSON.stringify(error));
        });
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="User Data" color="info" />
      {auth.user.roles.includes("ADMIN") && (
        <MultipleSelector
          options={allRoles}
          selectedOptions={userRoles}
          onSelect={handleRoleAdd}
          onUnselect={handleRoleRemove}
          busy={false}
        />
      )}
      <CardContent>
        <BioDataTable rows={rows} />
      </CardContent>
    </Card>
  );
}
BioDataCard.propTypes = { user: PropTypes.object.isRequired };
