import React, { useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { isEmptyObject } from "helpers/utils";
import ComboBox from "components/ComboBox";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function NewUserForm({ loading, onCreate, status, userError, languageKv}) {
  const classes = useStyles();
  const [data, setData] = useState({languageId:1});
  const [errors, setErrors] = useState({});
  
  const handleChange = (event, name) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const phoneNoValidates = (phone) => {
    const re = /^([2567]{4})([\d]{8})$/g;
    return re.test(phone);
  };
  const emailValidates = (email) => {
    const re = /^\S+@\S+\.\S+$/;
    return re.test(email);
  };
  
  const handleLanguageSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["languageId"] = e.target.value;
    setData(oldData);
  };

  const handleGenderSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["gender"] = e.target.value;
    setData(oldData);
  };

  const genderOptions = [
    {label:'Male', value:'male', key:'male'}, {
    label:'Female', value:'female', key:'female'},
    {label:'Undefined', value:'undefined', key:'undefined'}
  ];

  const handleClick = () => {
    let errorList = {};
    if (!data.firstName) errorList.firstName = "field cannot be empty";
    if (!data.lastName) errorList.lastName = "field cannot be empty";
    if (!data.nin) errorList.nin = "field cannot be empty";
    if (!data.email) errorList.email = "field cannot be empty";
    if (!data.phoneNo) errorList.phoneNo = "field cannot be empty";
    if (data.phoneNo && !phoneNoValidates(data.phoneNo))
      errorList.phoneNo = "Invalid phone number format";
    if (data.email && !emailValidates(data.email))
      errorList.email = "Invalid email format";
    if (emptyObject(errorList)) {
      onCreate(data);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          error={checkError("firstName")}
          label="First Name"
          id="firstName"
          value={data.firstName}
          onChange={(e) => handleChange(e, "firstName")}
          variant="outlined"
          helperText={errors.firstName}
          size="small"
        />
        <TextField
          error={checkError("lastName")}
          label="Last Name"
          id="lastName"
          value={data.lastName}
          onChange={(e) => handleChange(e, "lastName")}
          variant="outlined"
          helperText={errors.lastName}
          size="small"
        />
        <TextField
          error={checkError("middleName")}
          label="Middle Name"
          id="middleName"
          value={data.middleName}
          onChange={(e) => handleChange(e, "middleName")}
          variant="outlined"
          helperText={errors.middleName}
          size="small"
        />
        <ComboBox
          defaultValue={"undefined"}
          error={errors["gender"]}
          value={data.gender === undefined ? "undefined" : data.gender}
          label={"Gender"}
          onSelect={handleGenderSelection}
          kvOptions={genderOptions}
        />
      </div>
      <div>
        <TextField
          error={checkError("nin")}
          label="NIN"
          id="nin"
          value={data.nin}
          onChange={(e) => handleChange(e, "nin")}
          variant="outlined"
          helperText={errors.nin}
          size="small"
        />
        <TextField
          error={checkError("email")}
          label="Email"
          id="email"
          value={data.email}
          onChange={(e) => handleChange(e, "email")}
          variant="outlined"
          helperText={errors.email}
          size="small"
        />
        <TextField
          error={checkError("phoneNo")}
          label="Phone No"
          id="phoneNo"
          value={data.phoneNo}
          onChange={(e) => handleChange(e, "phoneNo")}
          variant="outlined"
          helperText={errors.phoneNo}
          size="small"
        />
        <ComboBox
          defaultValue={1}
          error={errors["languageId"]}
          value={data.languageId === undefined ? 1 : data.languageId}
          label={"Language Name"}
          onSelect={handleLanguageSelection}
          kvOptions={languageKv}
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {userError || "User creation failed!"}
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          User creation successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Create New User
        </Button>
      </div>
    </form>
  );
}

NewUserForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
  languageKv: PropTypes.array.isRequired
};
