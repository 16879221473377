import React, { useState, useEffect } from "react";
// import EnhancedTable from "components/EnhancedTable";
import UptakeCreator from "./UptakeCreator";
import UptakeEditor from "./UptakeEditor";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "../user-manager/CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  cropNeedTableHeaders,
  createCropNeedRecord,
} from "../../helpers/schemas/CropNeed";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function UptakeManager() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [cropsKv, setCropsKv] = useState([]);
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}crop-needs`)
      .then((response) => {
        let result = response.data.map((rec) => createCropNeedRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
    axios
      .get(`${baseUrl}crops`)
      .then((response) => {
        let kv = response.data.map((crop) => {
          return { key: crop.id, value: crop.name };
        });
        setCropsKv(kv);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
  }, []);
  
  function handleBackToCreate() {
    setFormData({});
  }
  function loadEditData(tableData) {
    let userSelected = {};
    Object.keys(tableData).forEach(
      (k) => (userSelected[k] = tableData[k].value)
    );
    return userSelected;
  }
  function saveEdit(editedData) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "crop-needs/" + editedData.id, editedData)
      .then((newRecord) => {
        let transformed = createCropNeedRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === editedData.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function create(newData) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(baseUrl + "crop-needs", newData)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        // if (error.response.status === 400) {
        //   setUserError(error.response.data.message);
        // }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <UptakeEditor
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              crops={cropsKv}
            />
          </div>
        ) : (
          <UptakeCreator
            onCreate={create}
            loading={loading}
            status={status}
            userError={userError}
            crops={cropsKv}
          />
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="Crop Requirements"
        headers={cropNeedTableHeaders}
        data={data}
        setUserSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
