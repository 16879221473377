import React, { useState, useEffect } from "react";
// import EnhancedTable from "components/EnhancedTable";
//import NewLanguageForm from "./NewLanguageForm";
//import EditLanguageForm from "./EditLanguageForm";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "./CheckedTable";
import Spinner from "components/Spinner";

import { smsLogTableHeaders, createSmsLogRecord } from "../../helpers/schemas/SmsLog";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function SmsLogData() {
  //const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  //const [status, setStatus] = useState("");
  //const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}smslog`)
      .then((response) => {
        let result = response.data.map((rec) => createSmsLogRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
  }, []);
  
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        {}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="Sms Log"
        headers={smsLogTableHeaders}
        data={data}
        setSmsLogSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
