import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import ComboBox from "components/ComboBox";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isEmptyObject } from "helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newUserButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function FarmEditor({
  loading,
  onEdit,
  formData,
  status,
  villagesKv,
  cropsKv,
  usersKv,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  console.log("xxxx data", JSON.stringify(data));
  useEffect(() => {
    setData(formData);
    setErrors({});
    return () => setData({});
  }, [formData]);

  const handleVillageSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["villageId"] = e.target.value;
    setData(oldData);
  };
  const handleOwnerSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["ownerId"] = e.target.value;
    setData(oldData);
  };
  const phoneNoValidates = (phone) => {
    const re = /^([2567]{4})([\d]{8})$/g;
    return re.test(phone);
  };
  const handleCropSelection = (e) => {
    if (!isEmptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData["cropId"] = e.target.value;
    setData(oldData);
  };

  const handleCrop2Selection = (e) => {
      if (!isEmptyObject(errors)) {
        setErrors({});
      }
      let oldData = { ...data };
      oldData["crop2Id"] = e.target.value;
      setData(oldData);
    };

  const handleClick = () => {
    let errorList = {};
    // if (!data.villageId) errorList.villageId = "field cannot be empty";
    // if (!data.ownerId) errorList.ownerId = "field cannot be empty";
    // if (!data.cropId) errorList.cropId = "field cannot be empty";
    // if (!data.name) errorList.name = "field cannot be empty";
    // if (!data.coordinates) errorList.coordinates = "field cannot be empty";
       if (!data.phoneNo) errorList.phoneNo = "field cannot be empty";
    if (data.phoneNo && !phoneNoValidates(data.phoneNo))
      errorList.phoneNo = "Invalid phone number format";
    if (isEmptyObject(errorList)) {
      let edited = { ...data };
      let changes = Object.fromEntries(
        Object.entries(edited).filter(
          ([k, v]) => k === "id" || v !== formData[k]
        )
      );
      onEdit(changes);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  function handleChange(event, name) {
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <ComboBox
          error={errors["villageId"]}
          value={data["villageId"]}
          label={"Village"}
          onSelect={handleVillageSelection}
          kvOptions={villagesKv}
        />
        <ComboBox
          error={errors["ownerId"]}
          value={data["ownerId"]}
          label={"Owner"}
          onSelect={handleOwnerSelection}
          kvOptions={usersKv}
        />
        <ComboBox
          error={errors["cropId"]}
          value={data["cropId"]}
          label={"Crop"}
          onSelect={handleCropSelection}
          kvOptions={cropsKv}
        />
        <ComboBox
          error={errors["crop2Id"]}
          value={data["crop2Id"]}
          label={"Crop 2"}
          onSelect={handleCrop2Selection}
          kvOptions={cropsKv}
        />
      </div>
      <div>
        <TextField
          error={checkError("name")}
          label="Name"
          id="name"
          value={data.name}
          onChange={(e) => handleChange(e, "name")}
          variant="outlined"
          helperText={errors.name}
          size="small"
        />
        <TextField
          error={checkError("phoneNo")}
          helperText={errors.phoneNo}
          label="Phone No"
          id="phoneNo"
          defaultValue={data.phoneNo}
          value={data.phoneNo}
          onChange={(e) => handleChange(e, "phoneNo")}
          variant="outlined"
          size="small"
        />
        <TextField
          error={checkError("coordinates")}
          label="Coordinates"
          id="coordinates"
          value={data.coordinates}
          onChange={(e) => handleChange(e, "coordinates")}
          variant="outlined"
          helperText={errors.coordinates}
          size="small"
        />
        <TextField
          error={checkError("farmSize")}
          label="Tilled Land Size"
          id="farmSize"
          value={data.farmSize}
          onChange={(e) => handleChange(e, "farmSize")}
          variant="outlined"
          helperText={errors.farmSize}
          size="small"
        />     
        <TextField
          error={checkError("farmerNum")}
          label="Farmer Number"
          id="farmerNum"
          value={data.farmerNum}
          onChange={(e) => handleChange(e, "farmerNum")}
          variant="outlined"
          helperText={errors.farmerNum}
          size="small"
        />  
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Update failed!
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Update successful!
        </Alert>
      )}
      <div className={classes.newUserButton}>
        <Button
          color="primary"
          round
          disabled={loading}
          onClick={() => handleClick(data)}
        >
          Update
        </Button>
      </div>
    </form>
  );
}
FarmEditor.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  villagesKv: PropTypes.array.isRequired,
  cropsKv: PropTypes.array.isRequired,
  usersKv: PropTypes.array.isRequired,
};
