import React, { useState, useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import LineGraph from "./LineGraph";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "helpers/use-auth";
import axios from "axios";
import ProgressBar from "components/ProgressBar";
import { baseUrl } from "helpers/Constants";
import moment from "moment";
import {
  extractChartData,
  dataWithToolTip,
  getMaxValues,
  createLast8WeeksData,
} from "helpers/utils";
import { dailyFeedsChart } from "variables/charts.js";
function maxValuesInit() {
  return {
    Nitrogen: 100,
    Phosphorus: 100,
    Potassium: 100,
    Ph: 100,
    Temp: 100,
    Moisture: 100,
    Conductivity: 100,
    SignalLevel: 100,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function WeeklyAnalytics() {
  const classes = useStyles();
  const auth = useAuth();
  const [weeklyN, setWeeklyN] = useState({});
  const [weeklyP, setWeeklyP] = useState({});
  const [weeklyK, setWeeklyK] = useState({});
  const [weeklyPh, setWeeklyPh] = useState({});
  const [weeklyTemp, setWeeklyTemp] = useState({});
  const [weeklyMoisture, setWeeklyMoisture] = useState({});
  const [weeklyEc, setWeeklyEc] = useState({});
  const [maxWeekly, setMaxWeekly] = useState(maxValuesInit());
  const [inProgress, setInProgress] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const handleDeviceSelection = (e, values) => {
    e.preventDefault();
    setSelectedDevices(values.map((d) => d.id));
  };
  useEffect(() => {
    setInProgress(true);

    if (devices.length === 0) {
      axios.get(`${baseUrl}user-devices/${auth.user.id}`).then((response) => {
        setDevices(response.data.map((e) => e.device));
      });
    }
    let devicesToUse =
      selectedDevices.length === 0 ? devices.map((d) => d.id) : selectedDevices;

    axios
      .get(`${baseUrl}analytics/weekly?devices=${devicesToUse.join()}`)
      .then((response) => {
        let dataArr = response.data.map((value) => [
          moment(value.date).format("YYYY-MM-DD"),
          value,
        ]);

        let dataObj = Object.fromEntries(dataArr);
        let weeklyChartData = createLast8WeeksData(dataObj);

        setMaxWeekly(getMaxValues(weeklyChartData));
        setWeeklyN(
          dataWithToolTip(
            "N(mg/Kg)",
            extractChartData("Nitrogen", weeklyChartData)
          )
        );
        setWeeklyP(
          dataWithToolTip(
            "P(mg/Kg)",
            extractChartData("Phosphorus", weeklyChartData)
          )
        );
        setWeeklyK(
          dataWithToolTip(
            "K(mg/Kg)",
            extractChartData("Potassium", weeklyChartData)
          )
        );
        setWeeklyPh(
          dataWithToolTip("Ph", extractChartData("Ph", weeklyChartData))
        );
        setWeeklyTemp(
          dataWithToolTip("Temp(°C)", extractChartData("Temp", weeklyChartData))
        );
        setWeeklyMoisture(
          dataWithToolTip(
            "Relative Humidity(%RH)",
            extractChartData("Moisture", weeklyChartData)
          )
        );
        setWeeklyEc(
          dataWithToolTip(
            "EC(us/cm)",
            extractChartData("Conductivity", weeklyChartData)
          )
        );
        setInProgress(false);
      })
      .catch((error) => {
        setInProgress(false);
        console.log("xxxx error", error);
      });
  }, [selectedDevices]);
  return (
    <div>
      <div className={classes.root}>
        <Autocomplete
          multiple
          // id="checkboxes-tags-demo"
          id="tags-standard"
          options={devices}
          disableCloseOnSelect
          getOptionLabel={(device) => device.number}
          onChange={handleDeviceSelection}
          renderOption={(device, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {`${device.farm.name}-${device.farm.crop.name}(${device.number})`}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Select Devices"
              placeholder="Leave empty to use all"
            />
          )}
        />
      </div>
      {inProgress && <ProgressBar></ProgressBar>}
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <LineGraph
            title="Nitrogen"
            periodSlug="Last 8 weeks"
            options={dailyFeedsChart.options}
            max={maxWeekly.Nitrogen}
            data={weeklyN}
            color="warning"
            listener={dailyFeedsChart.animation}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <LineGraph
            title="Phosphorus"
            periodSlug="Last 8 weeks"
            options={dailyFeedsChart.options}
            max={maxWeekly.Phosphorus}
            data={weeklyP}
            color="warning"
            listener={dailyFeedsChart.animation}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <LineGraph
            title="Potassium"
            periodSlug="Last 8 weeks"
            options={dailyFeedsChart.options}
            max={maxWeekly.Potassium}
            data={weeklyK}
            color="warning"
            listener={dailyFeedsChart.animation}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <LineGraph
            title="Ph"
            periodSlug="Last 8 weeks"
            options={dailyFeedsChart.options}
            max={maxWeekly.Ph}
            data={weeklyPh}
            color="warning"
            listener={dailyFeedsChart.animation}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <LineGraph
            title="Temperature"
            periodSlug="Last 8 weeks"
            options={dailyFeedsChart.options}
            max={maxWeekly.Temp}
            data={weeklyTemp}
            color="warning"
            listener={dailyFeedsChart.animation}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <LineGraph
            title="Relative Humidity"
            periodSlug="Last 8 weeks"
            options={dailyFeedsChart.options}
            max={maxWeekly.Moisture}
            data={weeklyMoisture}
            color="warning"
            listener={dailyFeedsChart.animation}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <LineGraph
            title="Conductivity"
            periodSlug="Last 8 weeks"
            options={dailyFeedsChart.options}
            max={maxWeekly.Conductivity}
            data={weeklyEc}
            color="warning"
            listener={dailyFeedsChart.animation}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
