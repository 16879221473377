import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const languageTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "name", numeric: false, disablePadding: true, label: "NAME" },
  
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const languageTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  name: { value: "", type: "text", label: "NAME" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};

export const createLanguageRecord = (data) => {
  let record = {
    id: { 
        value: data.id, 
        type: "number", 
        label: "ID", 
        readOnly: true 
    },
    name: { 
        value: data.name, 
        type: "text", 
        label: "NAME" 
    },
    
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const languageTableIdField = "id";
export const languageTableTitle = "Languages";
export const languageBaseUrl = baseUrl + "languages";
