import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  centered: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function PasswordResetter({
  loading,
  onPasswordReset,
  status,
  userError,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (event, name) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handleClick = () => {
    let errorList = {};
    if (!data.newPassword) errorList.newPassword = "field cannot be empty";
    if (!data.repeatNewPassword)
      errorList.repeatNewPassword = "field cannot be empty";
    if (data.newPassword && data.newPassword.length < 6)
      errorList.newPassword = "Password must be atleast 6 characters long!";
    if (data.newPassword !== data.repeatNewPassword) {
      errorList.newPassword = "Mismatch!";
      errorList.repeatNewPassword = "Mismatch!";
    }
    if (emptyObject(errorList)) {
      onPasswordReset(data.newPassword);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <div className={classes.centered}>
      <form className={classes.root} noValidate autoComplete="new-password">
        <div>
          <TextField
            autoComplete="new-password"
            error={checkError("newPassword")}
            label="New Password"
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            type="password"
            id="new-password"
            value={data.newPassword}
            onChange={(e) => handleChange(e, "newPassword")}
            variant="outlined"
            helperText={errors.newPassword}
            size="small"
          />
        </div>
        <div>
          <TextField
            autoComplete="new-password"
            error={checkError("repeatNewPassword")}
            label="Repeat new Password"
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            type="password"
            id="repeat-new-password"
            value={data.repeatNewPassword}
            onChange={(e) => handleChange(e, "repeatNewPassword")}
            variant="outlined"
            helperText={errors.repeatNewPassword}
            size="small"
          />
        </div>
        {loading && <Spinner />}
        {!loading && status === "FAILED" && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {userError || "Password change failed!"}
          </Alert>
        )}
        {!loading && status === "SUCCESS" && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Password reset successful!
          </Alert>
        )}
        <div className={classes.centered}>
          <Button
            color="primary"
            round
            disabled={loading}
            onClick={handleClick}
          >
            Reset Password
          </Button>
        </div>
      </form>
    </div>
  );
}
PasswordResetter.propTypes = {
  loading: PropTypes.bool.isRequired,
  onPasswordReset: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
};
