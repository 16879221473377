import React from "react";
import {
  Switch,
  Redirect,
  useHistory,
  withRouter,
  Route,
} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useAuth } from "../helpers/use-auth";
import routes, { userAuthorized } from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";

import axios from "axios";
import { baseUrl } from "helpers/Constants";
import { makeDate } from "helpers/utils";

let ps;

const useStyles = makeStyles(styles);

function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const auth = useAuth();
  const history = useHistory();
  const handleLogout = () => {
    auth.signout();
    history.push("/");
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  function isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }
  const [notifications, setNotifications] = React.useState([]);
  React.useEffect(() => {
    if (auth.user) {
      axios
        .get(`${baseUrl}notifications/${auth.user.id}`)
        .then((response) => {
          let content = response.data.map((notification) => {
            let notif = JSON.parse(notification.content);
            return `Around ${makeDate(notification.createdAt)}:${
              notif.param
            }, value:${notif.value} was too ${
              notif.bound === "UPPER" ? "high" : "low"
            }!`;
          });
          setNotifications(content);
        })
        .catch(() => handleLogout());
    }
  }, []);
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
     if (navigator.userAgentData.platform.indexOf("Win") > -1) {
		ps = new PerfectScrollbar(mainPanel.current, { 
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.userAgentData.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  let authorizedRoutes;
  if (auth.user && !isEmptyObject(auth.user)) {
    authorizedRoutes = routes.filter((route) => {
      return userAuthorized(route, auth.user.roles);
    });
  }
  return auth.user && !isEmptyObject(auth.user) ? (
    <div className={classes.wrapper}>
      <Sidebar
        routes={authorizedRoutes}
        logoText={"GLite"}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"blue"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={authorizedRoutes}
          handleDrawerToggle={handleDrawerToggle}
          handleLogout={handleLogout}
          notifications={notifications}
          {...rest}
        />
        <div className={classes.map}>
          {" "}
          <Switch>
            {authorizedRoutes.map((route, key) => {
              if (route.layout === "/admin") {
                return (
                  <Route
                    path={route.layout + route.path}
                    component={route.component}
                    key={key}
                  />
                );
              }
              return null;
            })}
            <Redirect from="/admin" to="/admin/user" />
          </Switch>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}
export default withRouter(Admin);
