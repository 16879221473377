import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const userTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "nin", numeric: false, disablePadding: true, label: "NIN" },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  { id: "lastName", numeric: false, disablePadding: true, label: "Last Name" },
  {
    id: "middleName",
    numeric: false,
    disablePadding: true,
    label: "Middle Name",
  },
  { id: "gender", numeric: false, disablePadding: false, label: "Gender" },
  // { id: "dob", numeric: false, disablePadding: true, label: "Birth Date" },
  { id: "email", numeric: false, disablePadding: true, label: "Email" },
  { id: "phoneNo", numeric: false, disablePadding: true, label: "Phone" },
  
  
  { id: "smsCount", numeric: true, disablePadding: false, label: "smsCount" },
  {id: "smsBalance", numeric: true, disablePadding: false, label: "smsBalance" },
  { id: "languageId", numeric: false, disablePadding: false, label: "Lang" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const userTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  nin: { value: "", type: "text", label: "NIN" },
  firstName: { value: "", type: "text", label: "First Name" },
  lastName: { value: "", type: "text", label: "Last Name" },
  middleName: { value: "", type: "text", label: "Middle Name" },
  gender: { value: "undefined", type: "text", label: "Gender" },
  
  // dob: { value: "", type: "text", label: "Birth Date" },
  email: { value: "", type: "text", label: "Email" },
  phoneNo: { value: "", type: "text", label: "Phone" },
  languageId: { value: 1, type: "language", label: "Language" },
  smsCount: { value: 0, type: "text", label: "SMS Count" },
  smsBalance: { value: 0, type: "text", label: "SMS Balance" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createUserRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    nin: { value: data.nin, type: "text", label: "NIN" },
    firstName: { value: data.firstName, type: "text", label: "First Name" },
    lastName: { value: data.lastName, type: "text", label: "Last Name" },
    middleName: {
      value: data.middleName,
      type: "text",
      label: "Middle Name",
    },
    gender: { 
      value: (data.gender == undefined ? "undefined" : data.gender),
      type: "text", 
      label: 'Gender' ,
      gender: (data.gender == undefined ? "undefined" : data.gender),
    },
    // dob: { value: data.dob, type: "text", label: "Birth Date" },
    email: {
      value: data.userAuth ? data.userAuth.email : "",
      type: "text",
      label: "Email",
    },
    phoneNo: { value: data.phoneNo, type: "text", label: "Phone" },
    smsCount: { value: data.smsCount, type: "text", label: "SMS Count" },
    smsBalance: { value: data.smsBalance, type: "text", label: "SMS Balance" },
    languageId: { 
      value: (data.language == undefined ? 1 : data.language.id),
      type: "text", 
      label: 'Language' ,
      languageName: (data.language == undefined ? "" : data.language.name),
    },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const userTableIdField = "id";
export const userTableTitle = "Users";
export const userBaseUrl = baseUrl + "users";
