import React, { useEffect, useState } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Typography from "@material-ui/core/Typography";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import axios from "axios";
import { baseUrl } from "helpers/Constants";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
// import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useAuth } from "../../helpers/use-auth";
import { useHistory, withRouter } from "react-router-dom";

// const localStyles = {
//   // successText: dashboardStyles.successText,
//   // dangerText: dashboardStyles.dangerText,
//   // arrow: dashboardStyles.upArrowCardCategory,
//   ...styles,
// };
const useStyles = makeStyles(styles);

function AdminNavbarLinks({ onLogout, notifications }) {
  const classes = useStyles();
  const history = useHistory();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [displayedNotifications, setDisplayedNotification] = useState([]);
  useEffect(() => {
    setDisplayedNotification(notifications.slice(0, 5));
  }, [notifications]);
  const auth = useAuth();
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
    axios
      .post(`${baseUrl}notifications/ack/${auth.user.id}`)
      .then(() => history.push("/admin/soil-data"));
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleLogout = () => {
    handleCloseProfile();
    onLogout();
  };
  return (
    <div>
      <div className={classes.searchWrapper}>
        <Typography variant="h6" className={classes.title}>
          {auth.user.name}
        </Typography>
      </div>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          {notifications.length > 0 && (
            <span className={classes.notifications}>
              {notifications.length}
            </span>
          )}
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {displayedNotifications.map((notif, index) => (
                      <MenuItem
                        onClick={handleCloseNotification}
                        className={classes.dropdownItem}
                        key={index}
                      >
                        {notif.includes("low") ? (
                          <span className={classes.dangerText}>
                            <ArrowDownward
                              className={classes.upArrowCardCategory}
                            />
                          </span>
                        ) : (
                          <span className={classes.successText}>
                            <ArrowUpward
                              className={classes.upArrowCardCategory}
                            />
                          </span>
                        )}
                        {notif}
                      </MenuItem>
                    ))}
                    {displayedNotifications.length < notifications.length && (
                      <>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={classes.dropdownItem}
                        >
                          <MoreHorizIcon />
                          {notifications.length -
                            displayedNotifications.length}{" "}
                          more
                        </MenuItem>
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
AdminNavbarLinks.propTypes = {
  onLogout: PropTypes.func,
  notifications: PropTypes.array,
};
export default withRouter(AdminNavbarLinks);
