import React, { useState, useEffect } from "react";
import axios from "axios";
import SoilTestingResultTable from "./SoilTestingResultTable";

import { SoilTestingResultBaseUrl } from "helpers/schemas/SoilTestingResult";


export default function SoilTestingResultTableWrapper() {
  const [soilTestingResults, setSoilTestingResults] = useState([]);
  useEffect(() => { 
    axios
      .get(`${SoilTestingResultBaseUrl}/all`)
      .then((response) => {
        setSoilTestingResults(response.data);
      })
      .catch(() => {});
  }, []);
  return <SoilTestingResultTable soilTestingResults={soilTestingResults} />;
}
