import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function NewProductUnitForm({
  loading,
  onCreate,
  status,
  userError,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const handleChange = (event, name) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handleClick = () => {
    let errorList = {};
    if (!data.label) errorList.label = "field cannot be empty";
    if (!data.numKg) errorList.numKg = "field cannot be empty";
    if (data.numKg && isNaN(data.numKg))
      errorList.numKg = "This value must be a number";
    if (emptyObject(errorList)) {
      onCreate(data);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          error={checkError("name")}
          label="Product Unit Name"
          id="name"
          value={data.label}
          onChange={(e) => handleChange(e, "label")}
          variant="outlined"
          helperText={errors.label}
          size="small"
        />
      </div>
      <div>
        <TextField
          error={checkError("numKg")}
          label="PER UNIT KG"
          id="numKg"
          value={data.numKg}
          onChange={(e) => handleChange(e, "numKg")}
          variant="outlined"
          helperText={errors.numKg}
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {userError || "Product Unit creation failed!"}
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Product Unit creation successful!
        </Alert>
      )}
      <div className={classes.newButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Create
        </Button>
      </div>
    </form>
  );
}
NewProductUnitForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
};
