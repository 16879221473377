import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
export const farmTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "ownerId", numeric: false, disablePadding: true, label: "Owner" },
  { id: "villageId", numeric: false, disablePadding: true, label: "Village" },
  { id: "district", numeric: false, disablePadding: true, label: "District" },
  /*{ id: "region", numeric: false, disablePadding: true, label: "Region" },
  { id: "subcounty", numeric: false, disablePadding: true, label: "Subcounty" },
  { id: "parish", numeric: false, disablePadding: true, label: "Parish" },*/
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
   { id: "phoneNo", numeric: false, disablePadding: true, label: "Phone" }, 
  { id: "crop", numeric: false, disablePadding: true, label: "Crop" },
  { id: "crop2", numeric: false, disablePadding: true, label: "Crop 2" },
  {
    id: "coordinates",
    numeric: false,
    disablePadding: true,
    label: "Coordinates",
  },
  {
    id: "farmSize",
    numeric: false,
    disablePadding: true,
    label: "Tilled Land Size",
  },
  {
    id: "farmerNum",
    numeric: false,
    disablePadding: true,
    label: "Farmer Number",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created",
  }
];

export const farmTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  ownerId: { value: "", type: "number", label: "Owner" },
  villageId: { value: "", type: "number", label: "Village" },
  parishId: { value: "", type: "number", label: "Parish" },
  subcountyId: { value: "", type: "number", label: "Subcounty" },
  district: { value: "", type: "number", label: "Village" },
  name: { value: "", type: "text", label: "Farm Name" },
  coordinates: { value: "", type: "text", label: "Coordinates" },
  farmSize: { value: "", type: "text", label: "Tilled Land Size" },
  created_at: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  farmerNum: { value: "", type: "text", label: "Farmer number" },
  updated_at: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};

export const createFarmRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    ownerId: {
      value: data.owner.firstName + " " + data.owner.lastName,
      type: "text",
      label: "Owner",
    },
    villageId: { value: data.village.name, type: "text", label: "Village" },
    district: {
      value: data.village.district.name,
      type: "text",
      label: "District",
    },
    /*region: {
      value: data.village.district.region.name,
      type: "text",
      label: "Region",
    },
     subcounty: {
      value: data.village.subcounty.name,
      type: "text",
      label: "Subcounty",
    },
     Parish: {
      value: data.village.parish.name,
      type: "text",
      label: "Parish",
    },*/
    name: { value: data.name, type: "text", label: "Name" },
    crop: { value: data.crop.name, type: "text", label: "Crop" },
    crop2: { value: data.crop2 == null ? "" : data.crop2.name, type: "text", label: "Crop 2" },
    coordinates: {
      value: data.coordinates,
      type: "text",
      label: "Coordinates",
    },
    farmSize: {
      value: data.farmSize,
      type: "text",
      label: "Tilled Land Size",
    },
    farmNum: {
      value: data.farmerNum,
      type: "text",
      label: "Farmer Number",
    },
    created_at: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Created",
      readOnly: true,
    },
  };
  return record;
};

export const farmTableIdField = "id";
export const farmTableTitle = "Farms";
export const farmBaseUrl = baseUrl + "farms";
