import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Spinner from "components/Spinner";
import { Alert, AlertTitle } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: 200,
    },
  },
  newLanguageButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function NewLanguageForm({ loading, onCreate, status, userError }) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const handleChange = (event, name) => {
    if (!emptyObject(errors)) {
      setErrors({});
    }
    let oldData = { ...data };
    oldData[name] = event.target.value;
    setData(oldData);
  };
  const emptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleClick = () => {
    let errorList = {};
    if (!data.name) errorList.name = "field cannot be empty";
    if (emptyObject(errorList)) {
      onCreate(data);
    } else setErrors(errorList);
  };
  const checkError = (field) => {
    return errors[field] !== undefined;
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          error={checkError("Name")}
          label="Name"
          id="name"
          value={data.name}
          onChange={(e) => handleChange(e, "name")}
          variant="outlined"
          helperText={errors.name}
          size="small"
        />
      </div>
      {loading && <Spinner />}
      {!loading && status === "FAILED" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {userError || "Language creation failed!"}
        </Alert>
      )}
      {!loading && status === "SUCCESS" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          User creation successful!
        </Alert>
      )}
      <div className={classes.newLanguageButton}>
        <Button color="primary" round disabled={loading} onClick={handleClick}>
          Create New Language
        </Button>
      </div>
    </form>
  );
}
NewLanguageForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userError: PropTypes.string,
};
