import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "components/Table/CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { insuranceregTableHeaders, createInsuranceRecord } from "../../helpers/schemas/InsuranceReg";
import InsuranceCreator from "./InsuranceCreator";
import InsuranceEditor from "./InsuranceEditor";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function InsuranceCrud() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [farmsKv, setInsurancesKv] = useState([]);
  const [usersKv, setUsersKv] = useState([]);
  const [cropsKv, setCropsKv] = useState([]);
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}insuranceregs`)
      .then((response) => {
        let result = response.data.map((rec) => createInsuranceRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
    axios
      .get(`${baseUrl}farms`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setInsurancesKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
    axios
      .get(`${baseUrl}crops`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.name };
        });
        setCropsKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
    axios
      .get(`${baseUrl}users`)
      .then((response) => {
        let result = response.data.map((rec) => {
          return { key: rec.id, value: rec.firstName + " " + rec.lastName };
        });
        setUsersKv(result);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
      });
  }, []);

  function loadEditData(tableData) {
    let selected = {};
    Object.keys(tableData).forEach((k) => (selected[k] = tableData[k].value));
    return selected;
  }
  function saveEdit(edited) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "insuranceregs/" + edited.id, edited)
      .then((newRecord) => {
        let transformed = createInsuranceRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === edited.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function create(record) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(baseUrl + "insuranceregs", record)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function handleBackToCreate() {
    setFormData({});
  }
  const classes = useStyles();
  return (

    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to Registration">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <InsuranceEditor
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
              farmsKv={farmsKv}
              cropsKv={cropsKv}
              usersKv={usersKv}
            />
          </div>
        ) : (
          <InsuranceCreator
            onCreate={create}
            loading={loading}
            status={status}
            userError={userError}
            farmsKv={farmsKv}
            cropsKv={cropsKv}
            usersKv={usersKv}
          />
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="Insurance"
        headers={insuranceregTableHeaders}
        data={data}
        setSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
