import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GroupIcon from "@material-ui/icons/Group";
import DistrictCrud from "glite-components/location-manager/DistrictCrud";
import VillageCrud from "glite-components/location-manager/VillageCrud";
import FarmCrud from "glite-components/location-manager/FarmCrud";
import FarmUpload from "glite-components/location-manager/FarmUpload";
import ParishCrud from "glite-components/location-manager/ParishCrud";
import SubCountyCrud from "glite-components/location-manager/SubCountyCrud";
export default function Locations() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Location:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Farms",
                tabIcon: GroupIcon,
                tabContent: <FarmCrud />,
              },
              {
                tabName: "Villages",
                tabIcon: GroupIcon,
                tabContent: <VillageCrud />,
              },
              {
                tabName: "Districts",
                tabIcon: GroupIcon,
                tabContent: <DistrictCrud />,
              },
              {
                tabName: "Subcounties",
                tabIcon: GroupIcon,
                tabContent: <SubCountyCrud />,
              },
              {
                tabName: "Parishes",
                tabIcon: GroupIcon,
                tabContent: <ParishCrud />,
              },
              {
                tabName: "FarmUpload",
                tabIcon: GroupIcon,
                tabContent: <FarmUpload />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
