//import React from "react";
//import React, { useState } from "react";
import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";
//import Button from "components/CustomButtons/Button.js";
//import MomoWidget from "glite-components/payment-manager/MomoWidget";
//import Button from "@material-ui/core/Button";
//import { ErrorAlert, SuccessAlert } from "components/Alerts";
//import { useAuth } from "helpers/use-auth";
//import Spinner from "components/Spinner";
//import { isEmptyObject } from "helpers/utils";
//import axios from "axios";

export const insuranceregTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "Owner",
    numeric: false,
    disablePadding: true,
    label: "Owner",
  },
  //{ id: "phoneno", numeric: false, disablePadding: true, label: "Telephone" },
  { id: "farmId", numeric: false, disablePadding: true, label: "Farm Name" },
  { id: "village", numeric: false, disablePadding: true, label: "Village" },
  { id: "crop", numeric: false, disablePadding: true, label: "Crop" },
  { id: "farmSize", numeric: false, disablePadding: true, label: "Farm Size" },
  { id: "groupName", numeric: false, disablePadding: true, label: "Group Name" },
  { id: "price", numeric: false, disablePadding: true, label: "Price per KG" },
  { id: "schemerate", numeric: false, disablePadding: true, label: "Scheme Rate" },
  { id: "expectedvalue", numeric: false, disablePadding: true, label: "Expected Value" },
  { id: "value2insure", numeric: false, disablePadding: true, label: "Value To Insure" },
  { id: "basicpremium", numeric: false, disablePadding: true, label: "Basic Premium" },
  { id: "subsidyperc", numeric: false, disablePadding: true, label: "%Subsidy" },
  { id: "subsidy", numeric: false, disablePadding: true, label: "Subsidy(% of basic premium)" },
  { id: "traininglevy", numeric: false, disablePadding: true, label: "Training Levy(0.5% of basic premium)" },
  { id: "stampduty", numeric: false, disablePadding: true, label: "Stamp Duty" },
  { id: "netpremium", numeric: false, disablePadding: true, label: "Net Premium" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const insuranceregTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  Owner: { value: "", type: "number", label: "Owner" },
  //phoneNo: { value: "", type: "text", label: "Telephone" },
  farmId: { value: "", type: "number", label: "Farm Name" },
  village: { value: "", type: "number", label: "Village" },
  crop: { value: "", type: "number", label: "Crop" },
  farmSize: { value: "", type: "number", label: "Farm Size" },
  groupName: { value: "", type: "text", label: "Group Name" },
  price: { value: "", type: "number", label: "Price Per KG(Ugx)" },
  schemerate: { value: "", type: "number", label: "Scheme Rate" },
  expectedvalue: { value: "", type: "number", label: "Expected Value" },
  value2insure: { value: "", type: "number", label: "Value To Insure" }, 
  basicpremium: { value: "", type: "number", label: "Basic Premium" },
  subsidyperc: { value: "", type: "number", label: "%subsidy" },
  subsidy: { value: "", type: "number", label: "Subsidy(% of basic premium)" },
  traininglevy: { value: "", type: "number", label: "Training Levy(0.5% of basic premium)" },
  stampduty: { value: "", type: "number", label: "Stamp Duty" },
  netpremium: { value: "", type: "number", label: "Net Premium" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createInsuranceRecord = (data) => {

  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    Owner: {
      value: data.farm.owner.firstName + " " + data.farm.owner.lastName,
      type: "text",
      label: "Owner",
      },
    farmId: {
      value: data.farm.name,
      type: "text",
      label: "Farm",
    },
    village: {
      value: data.farm.village.name,
      type: "text",
      label: "Village",
    },
    crop: {
      value: data.farm.crop.name,
      type: "text",
      label: "Crop",
    },
     farmSize: {
      value: data.farmSize,
      type: "number",
      label: "Farm Size(Acres)",
    },
    groupName: {
      value: data.groupName,
      type: "text",
      label: "Group Name",
    },
    price: {
      value: data.price,
      type: "number",
      label: "Price Per KG(Ugx)",
    },
    schemerate: {
      value: data.schemerate,
      type: "number",
      label: "Scheme Rate",
    }, 
    expectedvalue: {
      value: data.expectedvalue,
      type: "number",
      label: "Expected Value",
    },
    value2insure: {
      value: data.value2insure,
      type: "number",
      label: "Value To Insure",
    },
    basicpremium: {
      value: data.basicpremium,
      type: "number",
      label: "Basic Premium",
    },
   subsidyperc: {
      value: data.subsidyperc,
      type: "number",
      label: "%SubsidyValue",
    },   
   subsidy: {
      value: data.subsidy,
      type: "number",
      label: "Subsidy(% of basic premium)",
    },
   traininglevy: {
      value: data.traininglevy,
      type: "number",
      label: "Training Levy(0.5% of basic premium)",
    },
  stampduty: {
      value: data.stampduty,
      type: "number",
      label: "Stamp Duty",
    },
  netpremium: {
      value: data.netpremium,
      type: "number",
      label: "Net Premium",
    },  
  createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
   updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;

  
};

export const insuranceregTableIdField = "id";
export const insuranceregTableTitle = "InsuranceRegs";
export const insuranceregBaseUrl = baseUrl + "insuranceregs";
