import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";

export function ErrorAlert({ msg }) {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {msg}
    </Alert>
  );
}

export function SuccessAlert({ msg }) {
  return (
    <Alert severity="success">
      <AlertTitle>Success</AlertTitle>
      {msg}
    </Alert>
  );
}
ErrorAlert.propTypes = { msg: PropTypes.string.isRequired };
SuccessAlert.propTypes = { msg: PropTypes.string.isRequired };
