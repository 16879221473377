import React, { useState, useEffect } from "react";
// import EnhancedTable from "components/EnhancedTable";
import NewProductUnitForm from "./NewProductUnitForm";
import EditProductUnitForm from "./EditProductUnitForm";
import axios from "axios";
import { baseUrl } from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import CheckedTable from "./CheckedTable";
import Spinner from "components/Spinner";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  productUnitTableHeaders,
  createProductUnitRecord,
} from "../../helpers/schemas/ProductUnit";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

export default function ProductUnitCrud() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [userError, setUserError] = useState(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}product-units`)
      .then((response) => {
        let result = response.data.map((rec) => createProductUnitRecord(rec));
        setData(result);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("xxxx err", JSON.stringify(error.response));
        setTableLoading(false);
      });
  }, []);
  function handleBackToCreate() {
    setFormData({});
  }
  function loadEditData(tableData) {
    let selected = {};
    Object.keys(tableData).forEach((k) => (selected[k] = tableData[k].value));
    return selected;
  }
  function saveEdit(editedUser) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .put(baseUrl + "product-units/" + editedUser.id, editedUser)
      .then((newRecord) => {
        let transformed = createProductUnitRecord(newRecord.data);
        let newData = data.map((oldRecord) => {
          let updated =
            oldRecord.id.value === editedUser.id ? transformed : oldRecord;
          return updated;
        });
        setFormData(transformed);
        setData(newData);
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        if (error.response.status === 400) {
          setUserError(error.response.data.message);
        }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  function create(newProductUnit) {
    setUserError(undefined);
    setStatus("");
    setLoading(true);
    axios
      .post(baseUrl + "product-units", newProductUnit)
      .then(() => {
        setLoading(false);
        setStatus("SUCCESS");
      })
      .catch((error) => {
        console.log("xxx error", JSON.stringify(error));
        // if (error.response.status === 400) {
        //   setUserError(error.response.data.message);
        // }
        setLoading(false);
        setStatus("FAILED");
      });
  }
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        {formData.id ? (
          <div>
            <Tooltip title="Back to create">
              <IconButton onClick={handleBackToCreate}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <EditProductUnitForm
              onEdit={saveEdit}
              loading={loading}
              formData={loadEditData(formData)}
              status={status}
            />
          </div>
        ) : (
          <NewProductUnitForm
            onCreate={create}
            loading={loading}
            status={status}
            userError={userError}
          />
        )}
      </div>
      {tableLoading && <Spinner />}
      <CheckedTable
        title="Product Units"
        headers={productUnitTableHeaders}
        data={data}
        setSelected={setFormData}
        selected={formData}
      />
    </div>
  );
}
