import React, { useState, useEffect } from "react";
import axios from "axios";
import CropsTable from "./CropsTable";

import { cropBaseUrl } from "helpers/schemas/Crop";

export default function CropsTableWrapper() {
  const [crops, setCrops] = useState([]);
  useEffect(() => {
    axios
      .get(`${cropBaseUrl}`)
      .then((response) => {
        setCrops(response.data);
      })
      .catch(() => {});
  }, []);
  return <CropsTable crops={crops} />;
}
