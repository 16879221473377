import React from "react";
// core components
import LockOpenIcon from "@material-ui/icons/LockOpen";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GroupIcon from "@material-ui/icons/Group";
import InsuranceCrud from "glite-components/insurance/InsuranceCrud";
import InsuranceProducts from "glite-components/insurance/InsuranceProducts";
export default function Insurance() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Insurance:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Register for Insurance",
                tabIcon: GroupIcon,
                tabContent: <InsuranceCrud />,
              },
              {
                tabName: "Pay for Insurance",
                tabIcon: LockOpenIcon,
                tabContent: <InsuranceProducts />,
              },

            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
